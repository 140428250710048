import React from "react";

import { Link } from "react-router-dom";
import FormatDate from "../../utils/formatDate";

export default function OrdersToME({ ele, order, currencySymbol }) {
  return (
    <li
      className={` mb-4 rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] p-2 w-full`}
    >
      <Link className={`flex flex-col`} to={ele._id}>
        <h3 className={`font-bold mb-2`}>
          <span>Order : </span>
          <span>{order}</span>
        </h3>
        <p className={`text-sm mb-2`}>
          <span>Date</span>
          <span>{FormatDate(ele.createdAt)}</span>
        </p>
        <p className={`text-sm mb-2`}>
          <span>Order Holder</span>
          <span className="mx-2">{ele.userId.username}</span>
        </p>
        <ul className={`mb-2`}>
          {ele.products.map((ele, i) => (
            <li key={i} className={`mb-2 flex flex-col`}>
              <div className={`flex items-center `}>
                <img
                  crossorigin="anonymous"
                  src={ele.product.imageUrl}
                  alt={ele.product.name}
                  className={`w-14 md:w-36 rounded-lg`}
                />
                <p
                  className={`text-lg font-bold ml-4 w-full whitespace-nowrap overflow-hidden text-ellipsis`}
                >
                  {ele.product.name}
                </p>
              </div>
            </li>
          ))}
          <div>
            <span className={`text-[#5776a5] text-lg font-semibold mr-2`}>
              Total Amount :
            </span>
            <span className={`text-lg text-[#5776a5]`}>
              {ele.grandTotal} {currencySymbol}
            </span>
          </div>
        </ul>
      </Link>
    </li>
  );
}
