import React from "react";

const Master = () => {
  return (
    <svg
      width="41"
      height="26"
      viewBox="0 0 41 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7895_31540)">
        <path
          d="M24.3939 6.58838H16.2556V19.8806H24.3939V6.58838Z"
          fill="#FF5F00"
        />
        <path
          d="M17.0946 13.2372C17.0936 11.9572 17.3837 10.6939 17.943 9.5426C18.5024 8.39138 19.3163 7.38246 20.3231 6.59221C19.0761 5.61219 17.5785 5.00279 16.0016 4.83363C14.4246 4.66446 12.8319 4.94238 11.4053 5.63561C9.97886 6.32884 8.77615 7.40942 7.93472 8.75383C7.0933 10.0983 6.64709 11.6523 6.64709 13.2383C6.64709 14.8243 7.0933 16.3783 7.93472 17.7228C8.77615 19.0672 9.97886 20.1478 11.4053 20.841C12.8319 21.5343 14.4246 21.8121 16.0016 21.643C17.5785 21.4738 19.0761 20.8644 20.3231 19.8844C19.316 19.0939 18.5019 18.0846 17.9425 16.933C17.3832 15.7813 17.0932 14.5175 17.0946 13.2372Z"
          fill="#EB001B"
        />
        <path
          d="M33.1922 18.4746V18.2025H33.3094V18.1462H33.0303V18.2025H33.1405V18.4746H33.1922ZM33.734 18.4746V18.1462H33.6495L33.551 18.3808L33.4525 18.1462H33.368V18.4746H33.429V18.2259L33.5205 18.4394H33.5839L33.6753 18.2259V18.4746H33.734Z"
          fill="#F79E1B"
        />
        <path
          d="M33.9997 13.2363C33.9996 14.8224 33.5532 16.3766 32.7117 17.721C31.8702 19.0655 30.6673 20.1461 29.2406 20.8392C27.814 21.5324 26.221 21.8101 24.6439 21.6407C23.0669 21.4713 21.5693 20.8615 20.3224 19.8812C21.3288 19.0903 22.1424 18.0811 22.702 16.9298C23.2614 15.7785 23.5521 14.5152 23.5521 13.2351C23.5521 11.9551 23.2614 10.6918 22.702 9.54047C22.1424 8.38917 21.3288 7.37999 20.3224 6.58903C21.5693 5.60872 23.0669 4.999 24.6439 4.8296C26.221 4.6602 27.814 4.93793 29.2406 5.63106C30.6673 6.3242 31.8702 7.40476 32.7117 8.74923C33.5532 10.0937 33.9996 11.6478 33.9997 13.234V13.2363Z"
          fill="#F79E1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_7895_31540">
          <rect width="41" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Master;
