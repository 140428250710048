import React from "react";
import PlaneDetailsComp from "../../components/PlaneDetails/PlaneDetailsComp";

const PlaneDetailsPage = () => {
  return <PlaneDetailsComp />;
};

export default PlaneDetailsPage;
export const planeDetailsPageLoader = async ({ params }) => {
 
  const planeId = params.planeId;

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const response = await fetch(baseUrl + `/categories/vehicle/${planeId}`);
  const data = await response.json();
 
  return data;
};
