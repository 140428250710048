import React from "react"
import { Link } from "react-router-dom"

const ServiceCategoryCard = ({ path, title, className }) => {
  return (
    <Link to={path} className={`block w-full md:w-80 ${className}`}>
      <div
        className={`flex items-center justify-center p-4 h-12 rounded-md overflow-hidden bg-white text-white text-center border-2 border-[#5776a5]`}
      >
        <p
          className={`text-sm md:text-lg font-normal leading-snug text-[#5776a5] `}
        >
          {title}
        </p>
      </div>
    </Link>
  )
}

export default ServiceCategoryCard
