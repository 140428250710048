import React, { useState } from "react";
import { Input, InputLabel, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import VideoCallIcon from "@mui/icons-material/VideoCall";
const VideoInput = ({ setValue, name }) => {
  const { t } = useTranslation();
  const handleUploadFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      const imgFile = event.target.files[0];
      setValue(name, imgFile);
    }
  };
  return (
    <Box>
      <InputLabel>
        <Button
          variant="contained"
          startIcon={<VideoCallIcon />}
          component={"span"}
        >
          {t("upload_video")}
        </Button>
        <Input
          type="file"
          accept={"video/*"}
          sx={{ display: "none" }}
          onChange={handleUploadFile}
        />
      </InputLabel>
    </Box>
  );
};

export default VideoInput;
