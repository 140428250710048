import React from "react";
import { useSelector } from "react-redux";
import MainSection from "../../../components/UI/MainSection";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Cities } from "../../../constants/cities";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useForm } from "react-hook-form";
import styles from "./DeliveryDetails.module.scss";
import { MdOutlineWorkOutline } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import logo from "../../../assets/netzoon-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { shipping } from "../../../services/shipping";
import { selectCart } from "../../../store/cartSlice";
import { useGetCitiesQuery } from "../../../api/world/queries";
import { selectUser } from "../../../store/userSlice";

export const DeliveryDetails = props => {
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const user = useSelector(selectUser);
  const {
    data: cities,
    isLoading: isCitiesLoading,
    isError: isCitiesError,
  } = useGetCitiesQuery(user.user.country);
  const {
    state: { serviceFees },
  } = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      username: "",
      mobile: "",
      phone: "",
      floorNumber: "",
      city: "",
      addressDetail: "",
      LocationType: "Home",
    },
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setValue("username", user.result.username);
    setValue("email", user.result.email);
    setValue("mobile", user.result?.secondeMobile ?? "");
    setValue("phone", user.result.firstMobile);
    setValue("floorNumber", user.result.floorNum ?? "");
    setValue("city", user.result.city);
    setValue("addressDetail", user.result.addressDetails);
    setValue("LocationType", user.result.locationType ?? "Home");
  }, [setValue]);

  const { mutate: CalucalateRate } = useMutation({
    mutationKey: ["CalculateRate"],
    mutationFn: async data => {
      return await shipping.CalculateRate(data);
    },
    onSuccess(data) {
      if (!data.HasErrors) {
        toast(t("success-operation"), { type: "success" });
      }

      const productsPrice = cart.reduce(
        (acc, pre) => acc + pre.price * pre.productCountInCart
      );

      navigate("/cart/paymentgateway", {
        replace: true,
        state: {
          productsPrice: productsPrice,
          serviceFees: serviceFees,
          TotalDelivery: data.data.TotalAmount.Value,
          TotalPrice: data.data.TotalAmount.Value + serviceFees + productsPrice,

          Address: watch("addressDetail"),
          mobile: watch("mobile"),
          city: watch("city"),
          username: watch("username"),
          phone: watch("phone"),
          email: watch("email"),
        },
      });
      reset();
    },
    onError(error) {
      toast(t("failed_operation"), { type: "error" });
    },
  });
  console.log(cart[0].owner);

  const onSubmit = async data => {
    const ProductDetails = JSON.parse(localStorage.getItem("cart"));

    const totalweight = ProductDetails.reduce((a, b) => {
      return a + b.weight * b.productCountInCart;
    }, 0);
    const pices = ProductDetails.reduce((a, b) => {
      return a + b.productCountInCart;
    }, 0);
    CalucalateRate({
      OriginAddress: {
        Line1: cart[0].owner.addressDetails,
        Line2: cart[0].owner.address,
        Line3: null,
        City: cart[0].owner.city,
        StateOrProvinceCode: cart[0].owner.city || "Dubai",
        PostCode: "",
        CountryCode: cart[0].owner.country,
        Longitude: 0,
        Latitude: 0,
        BuildingNumber: null,
        BuildingName: null,
        Floor: null,
        Apartment: null,
        POBox: null,
        Description: null,
      },
      DestinationAddress: {
        Line1: data.addressDetail,
        Line2: data.addressDetail,
        Line3: null,
        City: data.city,
        StateOrProvinceCode: null,
        PostCode: "",
        CountryCode: user.user.country,
        Longitude: 0,
        Latitude: 0,
        BuildingNumber: null,
        BuildingName: null,
        Floor: data.floorNumber,
        Apartment: null,
        POBox: null,
        Description: null,
      },
      ShipmentDetails: {
        Dimensions: null,
        ActualWeight: {
          Unit: "KG",
          Value: totalweight,
        },
        ChargeableWeight: {
          Unit: "KG",
          Value: 0,
        },
        DescriptionOfGoods: null,
        GoodsOriginCountry: null,
        NumberOfPieces: pices,
        ProductGroup: "DOM",
        ProductType: "ONP",
        PaymentType: "P",
        PaymentOptions: null,
        CustomsValueAmount: null,
        CashOnDeliveryAmount: null,
        InsuranceAmount: null,
        CashAdditionalAmount: null,
        CashAdditionalAmountDescription: null,
        CollectAmount: null,
        Services: "",
        Items: null,
        DeliveryInstructions: null,
        AdditionalProperties: null,
        ContainsDangerousGoods: false,
      },
      PreferredCurrencyCode: "AED",
      ClientInfo: {
        Source: 24,
        AccountCountryCode: "AE",
        AccountEntity: "DXB",
        AccountPin: "116216",
        AccountNumber: "45796",
        UserName: "testingapi@aramex.com",
        Password: "R123456789$r",
        Version: "v1",
      },
      Transaction: null,
    });
  };

  const { t } = useTranslation();

  return (
    <MainSection className={`!mt-52 md:!mt-36`}>
      <h2 className={`text-3xl font-medium text-[#5776a5] text-center mb-8`}>
        {t("DeliveryDetails")}
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`flex justify-between flex-col-reverse lg:flex-row w-[90%] mx-auto`}
        >
          <div
            className={`py-2 px-2 bg-[#5776A5] bg-opacity-10 rounded-2xl flex-1`}
          >
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="username" className={`text-xl mb-2 md:mb-0`}>
                {t("username")}
              </label>
              <input
                {...register("username", {
                  required: {
                    value: true,
                    message: "required",
                  },
                  minLength: {
                    value: 6,
                    message: "min length 6 char",
                  },
                })}
                id="username"
                type="text"
                name="username"
                required
                className={` ml-2 outline-none px-4  flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.username && (
              <p className={styles.validation}>{errors.username.message}</p>
            )}

            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="email" className={`text-xl mb-2 md:mb-0`}>
                {t("email")}
              </label>
              <input
                {...register("email", {
                  required: {
                    value: true,
                    message: "required",
                  },
                  minLength: {
                    value: 6,
                    message: "min length 6 char",
                  },
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message:
                      "That Is Not An Email ,It will be Like Example@gmail.com",
                  },
                })}
                id="email"
                type="email"
                name="email"
                required
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.email && (
              <p className={styles.validation}>{errors.email.message}</p>
            )}
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="phone" className={`text-xl mb-2 md:mb-0`}>
                {t("phone")}
              </label>
              <input
                {...register("phone", {
                  required: {
                    value: true,
                    message: "required",
                  },
                  minLength: {
                    value: 6,
                    message: "min length 6 char",
                  },
                })}
                id="phone"
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.phone && (
              <p className={styles.validation}>{errors.phone.message}</p>
            )}

            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="mobile" className={`text-xl mb-2 md:mb-0`}>
                {t("mobile")}
              </label>
              <input
                {...register("mobile", {
                  required: {
                    value: true,
                    message: "required",
                  },
                  minLength: {
                    value: 6,
                    message: "min length 6 char",
                  },
                })}
                id="mobile"
                type="number"
                name="mobile"
                required
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.mobile && (
              <p className={styles.validation}>{errors.mobile.message}</p>
            )}

            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="SelectCity" className={`text-xl mb-2 md:mb-0`}>
                {t("SelectCity")}
              </label>
              <select
                name="SelectCity"
                id="SelectCity"
                className={`ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                {...register("city", {
                  required: {
                    value: true,
                    message: "required",
                  },
                })}
              >
                <option value={""}>{t("SelectCity")}</option>
                {cities?.Cities.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            {errors.city && (
              <p className={styles.validation}>{errors.city.message}</p>
            )}

            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="addressDetail" className={`text-xl mb-2 md:mb-0`}>
                {t("addressDetail")}
              </label>
              <input
                {...register("addressDetail", {
                  required: {
                    value: true,
                    message: "required",
                  },
                  minLength: {
                    value: 6,
                    message: "min length 6 char",
                  },
                })}
                id="addressDetail"
                type="text"
                name="addressDetail"
                required
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.addressDetail && (
              <p className={styles.validation}>
                {errors.addressDetail.message}
              </p>
            )}
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="floorNumber" className={`text-xl mb-2 md:mb-0`}>
                {t("floorNumber")}
              </label>
              <input
                {...register("floorNumber", {
                  required: {
                    value: true,
                    message: "required",
                  },
                })}
                id="floorNumber"
                type="number"
                name="floorNumber"
                required
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>

            {errors.floorNumber && (
              <p className={styles.validation}>{errors.floorNumber.message}</p>
            )}

            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label className={`text-xl mb-2 md:mb-0`}>
                {t("LocationType")}
              </label>
              <div className="flex flex-row">
                <div className="flex flex-row">
                  <input
                    {...register("LocationType", { required: true })}
                    type="radio"
                    value="Home"
                    className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                  />
                  <FaHome className={`text-[#5776a5] md:text-base w-8 h-8`} />
                </div>

                <div className="flex flex-row">
                  <input
                    {...register("LocationType", { required: true })}
                    type="radio"
                    value="Work"
                    className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                  />
                  <MdOutlineWorkOutline className={`text-[#5776a5] w-8 h-8`} />
                </div>
              </div>
            </div>

            {errors.LocationType && (
              <p className={styles.validation}>{errors.LocationType.message}</p>
            )}
          </div>

          <div
            className={`flex flex-1 flex-col items-center justify-center align-middle`}
          >
            <img
              crossorigin="anonymous"
              src={logo}
              alt={logo.toString().slice(0, 3)}
              className={`w-[200px] md:w-[400px] ${styles.logo} opacity-20`}
            />
            <p
              className={`text-2xl text-[#5776a5] font-semibold text-center mt-8`}
            >
              Stay Connected, Stay Satisfied with Netzoon
            </p>
          </div>
        </div>
        <div className={`mt-4 mb-8 flex w-[90%] mx-auto`}>
          <input
            type="submit"
            value="Ckeckout"
            // disabled={!isDirty || !isValid}
            className={`mt-4 mx-auto px-2 py-1 bg-[#5776a5] text-white text-xl font-semibold rounded-lg mb-4 w-full md:w-60 cursor-pointer`}
          />
        </div>
      </form>
    </MainSection>
  );
};
