import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedCurrency } from "../../store/currencySlice";
import ReactFlagsSelect from "react-flags-select";
import "./Flags.css";
const CurrencyFlagSelect = ({ className }) => {
  // Fetch the selectedCurrency from the Redux store
  const selectedCurrency = useSelector(
    (state) => state.currency.selectedCurrency
  );

  // Dispatch the setSelectedCurrency action to update the Redux state
  const dispatch = useDispatch();

  const updateLocalStorage = (currencyCode) => {
    localStorage.setItem("cur", currencyCode);
  };
  // const countries = [
  //   "US",
  //   "CA",
  //   "UK",
  //   "AU",
  //   "DE",
  //   "FR",
  //   "JP",
  //   "BR",
  //   "RU",
  //   "ZA",
  //   "IN",
  //   "CN",
  //   "KR",
  //   "MX",
  //   "AR",
  //   "NG",
  //   "IT",
  //   "ES",
  //   "GR",
  //   "EG",
  //   "TH",
  //   "VN",
  //   "ID",
  //   "TR",
  //   "IR",
  //   "PK",
  //   "BD",
  //   "AF",
  //   "ZA",
  //   "ZW",
  //   "KE",
  //   "UG",
  //   "GH",
  //   "CA",
  //   "US",
  //   "MX",
  //   "BR",
  //   "AR",
  //   "CL",
  //   "PE",
  //   "CO",
  //   "VE",
  //   "EC",
  //   "BO",
  //   "PY",
  //   "UY",
  //   "NZ",
  //   "AU",
  //   "PG",
  //   "FJ",
  //   "SB",
  //   "VU",
  //   "TO",
  //   "WS",
  //   "KI",
  //   "MH",
  //   "FM",
  //   "PW",
  //   "PW",
  //   "PW",
  //   "PW",
  //   "PW",
  //   "PW",
  // ];

  const countries = [
    "AE",
    "SA",
    "EG",
    "JO",
    "IQ",
    "US",
    "AD",
    "AE",
    "AF",
    "AG",
    "AI",
    "AL",
    "AM",
    "AN",
    "AO",
    "AQ",
    "AR",
    "AS",
    "AT",
    "AU",
    "AW",
    "AX",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BF",
    "BG",
    "BH",
    "BI",
    "BJ",
    "BL",
    "BM",
    "BN",
    "BO",
    "BQ",
    "BR",
    "BS",
    "BT",
    "BV",
    "BW",
    "BY",
    "BZ",
    "CA",
    "CC",
    "CD",
    "CF",
    "CG",
    "CH",
    "CI",
    "CK",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CS",
    "CU",
    "CV",
    "CW",
    "CX",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DM",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "EH",
    "ER",
    "ES",
    "ET",
    "FI",
    "FJ",
    "FK",
    "FM",
    "FO",
    "FR",
    "GA",
    "GB",
    "GD",
    "GE",
    "GF",
    "GG",
    "GH",
    "GI",
    "GL",
    "GM",
    "GN",
    "GP",
    "GQ",
    "GR",
    "GS",
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HM",
    "HN",
    "HR",
    "HT",
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IO",
    "IQ",
    "IR",
    "IS",
    "IT",
    "JE",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KI",
    "KM",
    "KN",
    "KP",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LB",
    "LC",
    "LI",
    "LK",
    "LR",
    "LS",
    "LT",
    "LU",
    "LV",
    "LY",
    "MA",
    "MC",
    "MD",
    "ME",
    "MF",
    "MG",
    "MH",
    "MK",
    "ML",
    "MM",
    "MN",
    "MO",
    "MP",
    "MQ",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NF",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NR",
    "NU",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PF",
    "PG",
    "PH",
    "PK",
    "PL",
    "PM",
    "PN",
    "PR",
    "PS",
    "PT",
    "PW",
    "PY",
    "QA",
    "RE",
    "RO",
    "RS",
    "RU",
    "RW",
    "SA",
    "SB",
    "SC",
    "SD",
    "SE",
    "SG",
    "SH",
    "SI",
    "SJ",
    "SK",
    "SL",
    "SM",
    "SN",
    "SO",
    "SR",
    "SS",
    "ST",
    "SV",
    "SX",
    "SY",
    "SZ",
    "TC",
    "TD",
    "TF",
    "TG",
    "TH",
    "TJ",
    "TK",
    "TL",
    "TM",
    "TN",
    "TO",
    "TR",
    "TT",
    "TV",
    "TW",
    "TZ",
    "UA",
    "UG",
    "UM",
    "US",
    "UY",
    "UZ",
    "VA",
    "VC",
    "VE",
    "VG",
    "VI",
    "VN",
    "VU",
    "WF",
    "WS",
    "XK",
    "YE",
    "YT",
    "ZA",
    "ZM",
    "ZW",
  ];
  const uniqueCountries = [...new Set(countries)];
  const countriesCurrencies = [
    { code: "AF", currency: "AFN" },
    { code: "AX", currency: "EUR" },
    { code: "AL", currency: "ALL" },
    { code: "DZ", currency: "DZD" },
    { code: "AS", currency: "USD" },
    { code: "AD", currency: "EUR" },
    { code: "AO", currency: "AOA" },
    { code: "AI", currency: "XCD" },
    { code: "AQ", currency: "AAD" },
    { code: "AG", currency: "XCD" },
    { code: "AR", currency: "ARS" },
    { code: "AM", currency: "AMD" },
    { code: "AW", currency: "AWG" },
    { code: "AU", currency: "AUD" },
    { code: "AT", currency: "EUR" },
    { code: "AZ", currency: "AZN" },
    { code: "BS", currency: "BSD" },
    { code: "BH", currency: "BHD" },
    { code: "BD", currency: "BDT" },
    { code: "BB", currency: "BBD" },
    { code: "BY", currency: "BYN" },
    { code: "BE", currency: "EUR" },
    { code: "BZ", currency: "BZD" },
    { code: "BJ", currency: "XOF" },
    { code: "BM", currency: "BMD" },
    { code: "BT", currency: "BTN" },
    { code: "BO", currency: "BOB" },
    { code: "BQ", currency: "USD" },
    { code: "BA", currency: "BAM" },
    { code: "BW", currency: "BWP" },
    { code: "BV", currency: "NOK" },
    { code: "BR", currency: "BRL" },
    { code: "IO", currency: "USD" },
    { code: "BN", currency: "BND" },
    { code: "BG", currency: "BGN" },
    { code: "BF", currency: "XOF" },
    { code: "BI", currency: "BIF" },
    { code: "KH", currency: "KHR" },
    { code: "CM", currency: "XAF" },
    { code: "CA", currency: "CAD" },
    { code: "CV", currency: "CVE" },
    { code: "KY", currency: "KYD" },
    { code: "CF", currency: "XAF" },
    { code: "TD", currency: "XAF" },
    { code: "CL", currency: "CLP" },
    { code: "CN", currency: "CNY" },
    { code: "CX", currency: "AUD" },
    { code: "CC", currency: "AUD" },
    { code: "CO", currency: "COP" },
    { code: "KM", currency: "KMF" },
    { code: "CG", currency: "XAF" },
    { code: "CD", currency: "CDF" },
    { code: "CK", currency: "NZD" },
    { code: "CR", currency: "CRC" },
    { code: "CI", currency: "XOF" },
    { code: "HR", currency: "HRK" },
    { code: "CU", currency: "CUP" },
    { code: "CW", currency: "ANG" },
    { code: "CY", currency: "EUR" },
    { code: "CZ", currency: "CZK" },
    { code: "DK", currency: "DKK" },
    { code: "DJ", currency: "DJF" },
    { code: "DM", currency: "XCD" },
    { code: "DO", currency: "DOP" },
    { code: "EC", currency: "USD" },
    { code: "EG", currency: "EGP" },
    { code: "SV", currency: "USD" },
    { code: "GQ", currency: "XAF" },
    { code: "ER", currency: "ERN" },
    { code: "EE", currency: "EUR" },
    { code: "ET", currency: "ETB" },
    { code: "FK", currency: "FKP" },
    { code: "FO", currency: "DKK" },
    { code: "FJ", currency: "FJD" },
    { code: "FI", currency: "EUR" },
    { code: "FR", currency: "EUR" },
    { code: "GF", currency: "EUR" },
    { code: "PF", currency: "XPF" },
    { code: "TF", currency: "EUR" },
    { code: "GA", currency: "XAF" },
    { code: "GM", currency: "GMD" },
    { code: "GE", currency: "GEL" },
    { code: "DE", currency: "EUR" },
    { code: "GH", currency: "GHS" },
    { code: "GI", currency: "GIP" },
    { code: "GR", currency: "EUR" },
    { code: "GL", currency: "DKK" },
    { code: "GD", currency: "XCD" },
    { code: "GP", currency: "EUR" },
    { code: "GU", currency: "USD" },
    { code: "GT", currency: "GTQ" },
    { code: "GG", currency: "GBP" },
    { code: "GN", currency: "GNF" },
    { code: "GW", currency: "XOF" },
    { code: "GY", currency: "GYD" },
    { code: "HT", currency: "HTG" },
    { code: "HM", currency: "AUD" },
    { code: "VA", currency: "EUR" },
    { code: "HN", currency: "HNL" },
    { code: "HK", currency: "HKD" },
    { code: "HU", currency: "HUF" },
    { code: "IS", currency: "ISK" },
    { code: "IN", currency: "INR" },
    { code: "ID", currency: "IDR" },
    { code: "IR", currency: "IRR" },
    { code: "IQ", currency: "IQD" },
    { code: "IE", currency: "EUR" },
    { code: "IM", currency: "GBP" },
    { code: "IL", currency: "ILS" },
    { code: "IT", currency: "EUR" },
    { code: "JM", currency: "JMD" },
    { code: "JP", currency: "JPY" },
    { code: "JE", currency: "GBP" },
    { code: "JO", currency: "JOD" },
    { code: "KZ", currency: "KZT" },
    { code: "KE", currency: "KES" },
    { code: "KI", currency: "AUD" },
    { code: "KP", currency: "KPW" },
    { code: "KR", currency: "KRW" },
    { code: "XK", currency: "EUR" },
    { code: "KW", currency: "KWD" },
    { code: "KG", currency: "KGS" },
    { code: "LA", currency: "LAK" },
    { code: "LV", currency: "EUR" },
    { code: "LB", currency: "LBP" },
    { code: "LS", currency: "LSL" },
    { code: "LR", currency: "LRD" },
    { code: "LY", currency: "LYD" },
    { code: "LI", currency: "CHF" },
    { code: "LT", currency: "EUR" },
    { code: "LU", currency: "EUR" },
    { code: "MO", currency: "MOP" },
    { code: "MK", currency: "MKD" },
    { code: "MG", currency: "MGA" },
    { code: "MW", currency: "MWK" },
    { code: "MY", currency: "MYR" },
    { code: "MV", currency: "MVR" },
    { code: "ML", currency: "XOF" },
    { code: "MT", currency: "EUR" },
    { code: "MH", currency: "USD" },
    { code: "MQ", currency: "EUR" },
    { code: "MR", currency: "MRO" },
    { code: "MU", currency: "MUR" },
    { code: "YT", currency: "EUR" },
    { code: "MX", currency: "MXN" },
    { code: "FM", currency: "USD" },
    { code: "MD", currency: "MDL" },
    { code: "MC", currency: "EUR" },
    { code: "MN", currency: "MNT" },
    { code: "ME", currency: "EUR" },
    { code: "MS", currency: "XCD" },
    { code: "MA", currency: "MAD" },
    { code: "MZ", currency: "MZN" },
    { code: "MM", currency: "MMK" },
    { code: "NA", currency: "NAD" },
    { code: "NR", currency: "AUD" },
    { code: "NP", currency: "NPR" },
    { code: "NL", currency: "EUR" },
    { code: "AN", currency: "ANG" },
    { code: "NC", currency: "XPF" },
    { code: "NZ", currency: "NZD" },
    { code: "NI", currency: "NIO" },
    { code: "NE", currency: "XOF" },
    { code: "NG", currency: "NGN" },
    { code: "NU", currency: "NZD" },
    { code: "NF", currency: "AUD" },
    { code: "MP", currency: "USD" },
    { code: "NO", currency: "NOK" },
    { code: "OM", currency: "OMR" },
    { code: "PK", currency: "PKR" },
    { code: "PW", currency: "USD" },
    { code: "PS", currency: "ILS" },
    { code: "PA", currency: "PAB" },
    { code: "PG", currency: "PGK" },
    { code: "PY", currency: "PYG" },
    { code: "PE", currency: "PEN" },
    { code: "PH", currency: "PHP" },
    { code: "PN", currency: "NZD" },
    { code: "PL", currency: "PLN" },
    { code: "PT", currency: "EUR" },
    { code: "PR", currency: "USD" },
    { code: "QA", currency: "QAR" },
    { code: "RE", currency: "EUR" },
    { code: "RO", currency: "RON" },
    { code: "RU", currency: "RUB" },
    { code: "RW", currency: "RWF" },
    { code: "BL", currency: "EUR" },
    { code: "SH", currency: "SHP" },
    { code: "KN", currency: "XCD" },
    { code: "LC", currency: "XCD" },
    { code: "MF", currency: "EUR" },
    { code: "PM", currency: "EUR" },
    { code: "VC", currency: "XCD" },
    { code: "WS", currency: "WST" },
    { code: "SM", currency: "EUR" },
    { code: "ST", currency: "STD" },
    { code: "SA", currency: "SAR" },
    { code: "SN", currency: "XOF" },
    { code: "RS", currency: "RSD" },
    { code: "CS", currency: "RSD" },
    { code: "SC", currency: "SCR" },
    { code: "SL", currency: "SLL" },
    { code: "SG", currency: "SGD" },
    { code: "SX", currency: "ANG" },
    { code: "SK", currency: "EUR" },
    { code: "SI", currency: "EUR" },
    { code: "SB", currency: "SBD" },
    { code: "SO", currency: "SOS" },
    { code: "ZA", currency: "ZAR" },
    { code: "GS", currency: "GBP" },
    { code: "SS", currency: "SSP" },
    { code: "ES", currency: "EUR" },
    { code: "LK", currency: "LKR" },
    { code: "SD", currency: "SDG" },
    { code: "SR", currency: "SRD" },
    { code: "SJ", currency: "NOK" },
    { code: "SZ", currency: "SZL" },
    { code: "SE", currency: "SEK" },
    { code: "CH", currency: "CHF" },
    { code: "SY", currency: "SYP" },
    { code: "TW", currency: "TWD" },
    { code: "TJ", currency: "TJS" },
    { code: "TZ", currency: "TZS" },
    { code: "TH", currency: "THB" },
    { code: "TL", currency: "USD" },
    { code: "TG", currency: "XOF" },
    { code: "TK", currency: "NZD" },
    { code: "TO", currency: "TOP" },
    { code: "TT", currency: "TTD" },
    { code: "TN", currency: "TND" },
    { code: "TR", currency: "TRY" },
    { code: "TM", currency: "TMT" },
    { code: "TC", currency: "USD" },
    { code: "TV", currency: "AUD" },
    { code: "UG", currency: "UGX" },
    { code: "UA", currency: "UAH" },
    { code: "AE", currency: "AED" },
    { code: "GB", currency: "GBP" },
    { code: "US", currency: "USD" },
    { code: "UM", currency: "USD" },
    { code: "UY", currency: "UYU" },
    { code: "UZ", currency: "UZS" },
    { code: "VU", currency: "VUV" },
    { code: "VE", currency: "VEF" },
    { code: "VN", currency: "VND" },
    { code: "VG", currency: "USD" },
    { code: "VI", currency: "USD" },
    { code: "WF", currency: "XPF" },
    { code: "EH", currency: "MAD" },
    { code: "YE", currency: "YER" },
    { code: "ZM", currency: "ZMW" },
    { code: "ZW", currency: "ZWL" },
  ];
  const countriesObject = {};
  for (const countryData of countriesCurrencies) {
    countriesObject[countryData.code] = countryData.currency;
  }

  return (
    <div className={`mr-2 ${className}`}>
      <ReactFlagsSelect
        selectedSize={14}
        fullWidth={false}
        countries={uniqueCountries}
        customLabels={countriesObject}
        placeholder="Select Currency"
        selected={selectedCurrency} // Set the selected currency based on the Redux state
        onSelect={(countryCode) => {
          // Dispatch the action to update the selected currency in Redux
          dispatch(setSelectedCurrency(countryCode));

          // Update local storage with the selected currency
          updateLocalStorage(countryCode);
          window.location.assign("/");
        }}
      />
    </div>
  );
};

export default CurrencyFlagSelect;
