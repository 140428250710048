import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const DeleteDialog = ({ handleDelete, name, open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} fullWidth maxWidth={"sm"} onClose={onClose}>
      <DialogTitle textAlign={"center"}>
        {t("sour_to_delete", { name })}
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>{t("cancel")}</Button>
        <Button variant="contained" onClick={handleDelete}>
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
