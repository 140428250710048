import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { RiSecurePaymentFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { order } from "../../services/order";

import { toast } from "react-toastify";
import { shipping } from "../../services/shipping";
import Loading from "../Loading/Loading";
import CheckoutOrderDetails from "../CheckoutOrderDetails/CheckoutOrderDetails";

const CheckOutFormComp = ({ toPayAmount, url }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const cart = JSON.parse(localStorage.getItem("cartItems"));

  const {
    state: {
      TotalPrice,
      Address,
      username,
      mobile,
      phone,
      productsPrice,
      serviceFees,
      email,
      city,
    },
  } = useLocation();

  const stripe = useStripe();
  const elements = useElements();
  const returnURL = window.location.origin + url;

  const { mutate: SaveOrder, isPending: isPendingSaveOrder } = useMutation({
    onSuccess(data) {
      const totalweight = cart.reduce((a, b) => {
        return a + b.product.weight * b.quantity;
      }, 0);

      const pices = cart.reduce((a, b) => {
        return a + b.quantity;
      }, 0);

      var currentDate = new Date();
      var currentMilliseconds = currentDate.getTime();
      var threeDaysMilliseconds = 3 * 24 * 60 * 60 * 1000;
      var newDateMilliseconds = currentMilliseconds + threeDaysMilliseconds;

      PickUp({
        ClientInfo: {
          Source: 24,
          AccountCountryCode: "AE",
          AccountEntity: "DXB",
          AccountPin: "116216",
          AccountNumber: "45796",
          UserName: "testingapi@aramex.com",
          Password: "R123456789$r",
          Version: "v1",
        },
        LabelInfo: {
          ReportID: 9201,
          ReportType: "URL",
        },
        Pickup: {
          PickupAddress: {
            Line1: cart[0].product.owner.address,
            Line2: cart[0].product.owner.addressDetails,
            Line3: "",
            City: cart[0].product.owner.city,
            StateOrProvinceCode: cart[0].product.owner.city,
            PostCode: "",
            CountryCode: "AE",
            Longitude: 0,
            Latitude: 0,
            BuildingNumber: null,
            BuildingName: null,
            Floor: null,
            "Apartme nt": null,
            POBox: null,
            Description: null,
          },
          PickupContact: {
            Department: "Test Department",
            PersonName: cart[0].product.owner.contactName,
            Title: cart[0].product.owner.username,
            CompanyName: cart[0].product.owner.username,
            PhoneNumber1: cart[0].product.owner.firstMobile,
            PhoneNumber1Ext: null,
            PhoneNumber2: null,
            PhoneNumber2Ext: null,
            FaxNumber: null,
            CellPhone:
              cart[0].product.owner.secondeMobile ??
              cart[0].product.owner.firstMobile,
            EmailAddress: cart[0].product.owner.email,
            Type: null,
          },
          PickupLocation: cart[0].product.owner.address,
          PickupDate: `/Date(${currentMilliseconds})/`,
          ReadyTime: `/Date(${currentMilliseconds})/`,
          LastPickupTime: `/Date(${newDateMilliseconds})/`,
          ClosingTime: "/Date(1561993200000)/", //pickupdate
          Comments: "",
          Reference1: "001",
          Reference2: "",
          Vehicle: "Car",
          Shipments: null,
          PickupItems: [
            {
              ProductGroup: "DOM",
              ProductType: "CDS",
              NumberOfShipments: 1,
              PackageType: "Box",
              Payment: "P",
              ShipmentWeight: {
                Unit: "KG",
                Value: totalweight,
              },
              ShipmentVolume: null,
              NumberOfPieces: pices,
              CashAmount: null,
              ExtraCharges: null,
              ShipmentDimensions: {
                Length: 0,
                Width: 0,
                Height: 0,
                Unit: "",
              },
              Comments: "Test",
            },
          ],
          Status: "Ready",
          ExistingShipments: null,
          Branch: "",
          RouteCode: "",
        },
        Transaction: {
          Reference1: "",
          Reference2: "",
          Reference3: "",
          Reference4: "",
          Reference5: "",
        },
      });
    },
    onError(error) {},
    mutationKey: ["SaveOrder"],
    mutationFn: async data => await order.SaveOrder(data),
  });

  const { mutate: PickUp, isPending: isPendingPickUp } = useMutation({
    onSuccess(data) {
      if (!data.data.HasErrors) {
        const cart = JSON.parse(localStorage.getItem("cartItems"));

        const totalweight = cart.reduce((a, b) => {
          return a + b.product.weight * b.quantity;
        }, 0);
        const pices = cart.reduce((a, b) => {
          return a + b.quantity;
        }, 0);

        var currentDate = new Date();
        var currentMilliseconds = currentDate.getTime();
        var threeDaysMilliseconds = 3 * 24 * 60 * 60 * 1000;
        var newDateMilliseconds = currentMilliseconds + threeDaysMilliseconds;

        CreateShipments({
          Shipments: [
            {
              Reference1: null,
              Reference2: null,
              Reference3: null,
              Shipper: {
                Reference1: null,
                Reference2: null,
                AccountNumber: "71923340",
                PartyAddress: {
                  Line1: cart[0].product.owner.address,
                  Line2: cart[0].product.owner.addressDetails,
                  Line3: "",
                  City: cart[0].product.owner.city,
                  StateOrProvinceCode: cart[0].product.owner.city,
                  PostCode: "",
                  CountryCode: "AE",
                  Longitude: 0,
                  Latitude: 0,
                  BuildingNumber: null,
                  BuildingName: null,
                  Floor: null,
                  "Apartme nt": null,
                  POBox: null,
                  Description: null,
                },
                Contact: {
                  Department: cart[0].product.owner.address,
                  PersonName: cart[0].product.owner.contactName,
                  Title: null,
                  CompanyName: cart[0].product.owner.username,
                  PhoneNumber1: cart[0].product.owner.firstMobile,
                  PhoneNumber1Ext: null,
                  PhoneNumber2: null,
                  PhoneNumber2Ext: null,
                  FaxNumber: null,
                  CellPhone:
                    cart[0].product.owner.secondeMobile ??
                    cart[0].product.owner.firstMobile,
                  EmailAddress: cart[0].product.owner.email,
                  Type: null,
                },
              },
              Consignee: {
                Reference1: null,
                Reference2: null,
                AccountNumber: null,
                PartyAddress: {
                  Line1: Address,
                  Line2: "",
                  Line3: "",
                  City: city,
                  StateOrProvinceCode: city,
                  PostCode: "",
                  CountryCode: "AE",
                  Longitude: 0,
                  Latitude: 0,
                  BuildingNumber: null,
                  BuildingName: null,
                  Floor: null,
                  Apartment: null,
                  POBox: null,
                  Description: null,
                },
                Contact: {
                  Department: Address,
                  PersonName: username,
                  Title: null,
                  CompanyName: username,
                  PhoneNumber1: mobile,
                  PhoneNumber1Ext: null,
                  PhoneNumber2: null,
                  PhoneNumber2Ext: null,
                  FaxNumber: null,
                  CellPhone: phone ?? mobile,
                  EmailAddress: email,
                  Type: null,
                },
              },
              ThirdParty: null,
              ShippingDateTime: `/Date(${currentMilliseconds})/`,
              DueDate: `/Date(${newDateMilliseconds})/`,
              Comments: null,
              PickupLocation: null,
              OperationsInstructions: null,
              AccountingInstrcutions: null,
              Details: {
                Dimensions: null,
                ActualWeight: {
                  Unit: "KG",
                  Value: totalweight,
                },
                ChargeableWeight: null,
                DescriptionOfGoods: "items",
                GoodsOriginCountry: "AE",
                NumberOfPieces: pices,
                ProductGroup: "DOM",
                ProductType: "CDS",
                PaymentType: "P",
                PaymentOptions: null,
                CustomsValueAmount: null,
                CashOnDeliveryAmount: null,
                InsuranceAmount: null,
                CashAdditionalAmount: null,
                CashAdditionalAmountDescription: null,
                "CollectAmou nt": null,
                Services: null,
                Items: [
                  ...cart?.map(item => {
                    return {
                      PackageType: "item",
                      Quantity: item.quantity,
                      Weight: {
                        Unit: "KG",
                        Value: item.product.weight,
                      },
                      Comments: "item remark if any",
                      Reference: "Item ref1",
                      PiecesDimensions: null,
                      CommodityCode: 640000,
                      GoodsDescription: "desc",
                      CountryOfOrigin: "AE",
                      CustomsValue: {
                        CurrencyCode: "AED",
                        Value: 10,
                      },
                      ContainerNumber: null,
                    };
                  }),
                ],
                DeliveryInstructions: null,
              },
              Attachments: null,
              ForeignHAWB: null,
              TransportType: 0,
              PickupGUID: data.data.ProcessedPickup.GUID,
              Number: null,
              ScheduledDelivery: null,
            },
          ],
          LabelInfo: {
            ReportID: 9729,
            ReportType: "URL",
          },
          ClientInfo: {
            Source: 24,
            AccountCountryCode: "AE",
            AccountEntity: "DXB",
            AccountPin: "116216",
            AccountNumber: "45796",
            UserName: "testingapi@aramex.com",
            Password: "R123456789$r",
            Version: "v1",
          },
          Transaction: {
            Reference1: "001",
            Reference2: "",
            Reference3: "",
            Reference4: "",
            Reference5: "",
          },
        });
      }
    },
    mutationKey: ["PickUp"],
    mutationFn: async data => await shipping.CreatePickup(data),
  });

  const { mutate: CreateShipments, isPending: isPendingCreateShipments } =
    useMutation({
      onSuccess(data) {
        if (!data.HasErrors) {
          toast("Process Is Completed");
          localStorage.removeItem("cartItems");
          localStorage.removeItem("lastTotalPrice");
          window.location.replace("/");
        }
      },
      mutationKey: ["CreateShipments"],
      mutationFn: async data => await shipping.CreateShipments(data),
    });

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnURL,
      },
      redirect: "if_required",
    });

    if (error) {
      toast("We Got Error", { type: "error" });
    } else {
      toast("Success Payment", { position: "top-right", type: "success" });
      toast("We Will Re-direct you to Home Page", {
        position: "top-right",
        type: "info",
      });
      SaveOrder({
        data: {
          clientId: cart[0].product.owner._id,
          products: [
            ...cart.map(item => {
              return {
                product: item.product._id,
                amount: item.product.price,
                qty: item.quantity,
              };
            }),
          ],
          orderStatus: "pending",
          grandTotal: TotalPrice,
          orderEvent: "orderEvent",
          shippingAddress: Address,
          mobile: mobile,
          subTotal: productsPrice,
          serviceFee: serviceFees,
        },
        userId: user.result._id,
      });
    }
  };

  return (
    <>
      {(isPendingSaveOrder || isPendingCreateShipments || isPendingPickUp) && (
        <Loading />
      )}
      {!(isPendingSaveOrder || isPendingCreateShipments || isPendingPickUp) && (
        <>
          <h2 className={`text-[#5776a5] text-center text-2xl`}>
            Your order is one step away from you
          </h2>
          <CheckoutOrderDetails />
          <form
            onSubmit={handleSubmit}
            className={`flex flex-col items-center mt-4`}
          >
            <PaymentElement />
            <button
              disabled={!stripe}
              className={` px-2 py-1 bg-green-500 text-white border-2 hover:border-green-500 hover:bg-transparent hover:!text-green-500 duration-300 flex  items-center justify-between mt-4 rounded-md disabled:bg-white`}
            >
              <span className={` flex items-center`}>
                Pay{" "}
                <span className={`mx-4 font-bold text-xl`}>
                  {TotalPrice.toFixed(2)}
                </span>{" "}
                Securely
              </span>

              <span>
                <RiSecurePaymentFill className={`text-3xl`} />
              </span>
            </button>
          </form>
        </>
      )}
    </>
  );
};

export default CheckOutFormComp;
