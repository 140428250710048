import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { editUser } from ".";
import { useCreateSendBirdUserMutation } from "../sendBird/queries";
const useEditUserMutation = () => {
  const navigate = useNavigate();
  const { mutate: createSendBirdUser } = useCreateSendBirdUserMutation();
  return useMutation({
    mutationKey: ["edit-user"],
    mutationFn: (payload) => editUser(payload),
    onSuccess: (data) => {
      localStorage.setItem("user", JSON.stringify(data));
      createSendBirdUser({
        user_id: data.result.username,
        nickname: data.result.username,
        profile_url: "",
      });
      navigate("/profile");
    },
  });
};
export { useEditUserMutation };
