import { CiLocationOn } from "react-icons/ci";
import MainSection from "../../UI/MainSection";
import { MdMobileFriendly } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import { order } from "../../../services/order";
import FormatDate from "../../../utils/formatDate";

export const OrderDetailsToMe = ({ OrderDetail }) => {
  return (
    <>
      <h2 className={`text-center text-2xl md:text-3xl font-bold mb-4 p-8`}>
        Order ID - {OrderDetail._id}
      </h2>
      <p className={`mb-2 p-4`}>
        Placed : <span>{FormatDate(OrderDetail.createdAt)}</span>
      </p>
      <p className={`mb-2 p-4`}>
        Order Holder : <span>{OrderDetail.userId.username}</span>
      </p>
      <div className={`bg-gray-100  rounded-sm mb-2 p-8`}>
        <div>
          <h4>Shipping Address :</h4>
          <span className={`flex items-center mb-2`}>
            <span className={`mr-2`}>
              <CiLocationOn className={`text-gray-400`} />
            </span>
            <span>{OrderDetail.shippingAddress}</span>
          </span>
        </div>
        <div>
          <h4>Mobile Number :</h4>
          <div>
            <span className={`flex items-center`}>
              <span className={`mr-2`}>
                <MdMobileFriendly className={`text-green-500`} />
              </span>
              <span>{OrderDetail.mobile}</span>
            </span>
          </div>
        </div>
      </div>

      <div className={`bg-gray-100  rounded-sm mb-2 p-8`}>
        <h3 className={`text-lg md:text-xl font-semibold`}>Products</h3>
        <ul>
          {OrderDetail?.products?.map((ele, i) => (
            <li
              className={`flex items-center justify-between md:grid md:grid-cols-3 md:gap-2 place-items-center`}
            >
              <div>
                <img
                  crossorigin="anonymous"
                  src={ele.product.imageUrl}
                  alt={ele.product.name}
                  className={`w-40 h-auto object-cover`}
                />
              </div>
              <div>
                <h4 className={`text-[#5776a5] text-lg font-semibold`}>
                  {ele.product.name}
                </h4>
                <p>{ele.product.description}</p>
              </div>
              <div className={`text-red-500`}>
                <h4>{ele.product.owner.username}</h4>
                <p>
                  {ele.product.price} * {ele.qty}
                </p>
              </div>
            </li>
          ))}
        </ul>
        <div className={`mt-4 border-t border-black `}>
          <h2 className={`text-lg font-semibold mb-2`}>Order Summery:</h2>
          <ul>
            <li className={`flex items-center justify-between`}>
              <span>SubTotal : </span>
              <span>{OrderDetail.subTotal}</span>
            </li>
            <li className={`font-bold flex items-center justify-between`}>
              <span className={`font-semibold`}>Total : </span>
              <span>{OrderDetail.grandTotal}</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
