import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import MainSection from "../../../components/UI/MainSection";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const data = useLoaderData();
 

  const [content, setContent] = useState();
  useEffect(() => {
    const language = window.localStorage.getItem("i18nextLng");

    setContent(
      language === "en"
        ? data.results[0].textEn.replace(/•/g, "\n•")
        : data.results[0].text.replace(/•/g, "\n•")
    );
  }, [data.results]);
  const { t } = useTranslation();
  function replaceWithBr() {
    return content?.replace(/\n/g, "<br />");
  }
  return (
    <MainSection className={`!mt-52 md:!mt-24 `}>
      <h2 className={`text-2xl text-center text-[#5776a5] font-bold mb-4`}>
        {t("PrivacyPolicy")}
      </h2>

      <p dangerouslySetInnerHTML={{ __html: replaceWithBr() }} />
    </MainSection>
  );
};

export default PrivacyPolicy;
export const PrivacyPolicyLoader = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const response = await fetch(baseUrl + `/legalAdvices`);
  const data = await response.json();
  return data;
};
