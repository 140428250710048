import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { editProduct, getProductDetails } from ".";
import { toast } from "react-toastify";
const useGetProductDetails = (id) =>
  useQuery({
    queryKey: ["product-details", id],
    queryFn: () => getProductDetails(id),
    enabled: !!id,
    staleTime: 0,
  });
const useEditProductMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["edit-product"],
    mutationFn: (payload) => editProduct(payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["product-details"] });
      toast.success(`edit ${data.name} successfully`);
    },
    onError: (data, variants) => {
      toast.error(`failed to edit ${variants.data.name}`);
    },
  });
};
export { useEditProductMutation, useGetProductDetails };
