import API_ROUTES from "../../constants/apiRoute";
import { createFormData } from "../../utils/createFormData";
import privetInstance from "../privetInstance";

const getPieceOfNews = async (id) => {
  const res = await privetInstance.get(API_ROUTES.PIECE_OF_NEWS.GET(id));
  return res.data;
};
const editPieceOfNews = async ({ id, ...data }) => {
  const formData = createFormData(data);
  const res = await privetInstance.put(
    API_ROUTES.PIECE_OF_NEWS.EDIT(id),
    formData
  );
  return res.data;
};
const deletePieceOfNews = async (id) => {
  const res = await privetInstance.delete(API_ROUTES.PIECE_OF_NEWS.DELETE(id));
  return res.data;
};
export { editPieceOfNews, deletePieceOfNews, getPieceOfNews };
