import React, { useState, useEffect } from "react";
import MainSection from "../../components/UI/MainSection";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { ImBin } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencySymbol } from "../../funcs/Currency";
import { useNavigate } from "react-router-dom";
import {
  decrementProductCount,
  incrementProductCount,
  removeProductFromCart,
  selectCart,
} from "../../store/cartSlice";
const Cart = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = window.localStorage.getItem("user");

  const cart = useSelector(selectCart);
  const totalPrice = cart.reduce(
    (cur, pre) => cur + pre.productCountInCart * pre.price,
    0
  );
  const fees = 10;
  const dispatch = useDispatch();

  const increaseQuantity = (id) => {
    dispatch(incrementProductCount({ id }));
  };

  const decreaseQuantity = (id) => {
    dispatch(decrementProductCount({ id }));
  };

  const removeItem = (id) => {
    dispatch(removeProductFromCart({ id }));
  };
  const currencySymbol = useSelector((state) =>
    getCurrencySymbol(state.currency.selectedCurrency)
  );

  const checkOutHandler = async () => {
    if (!user) {
      window.alert("Please LogIn to Complete the Action");
      return;
    }

    navigate("/cart/DeliveryDetails", {
      state: {
        serviceFees: 10,
      },
    });
  };

  return (
    <MainSection className={`!mt-52 md:!mt-28`}>
      <h1 className={`text-2xl font-semibold text-[#5776a5] text-center mb-8`}>
        {t("Your_Cart")}
      </h1>
      {cart.length === 0 ? (
        <p
          className={`text-4xl text-[#5776a5] text-center h-[calc(100vh-200px)] flex justify-center items-center`}
        >
          {t("empty_cart")}
        </p>
      ) : (
        <div>
          <ul className={`flex flex-col w-full md:w-[70%] mx-auto`}>
            {cart?.map((product, index) => (
              <li
                key={index}
                className={`p-2 bg-[#5776a5] bg-opacity-70 mb-2 rounded-xl flex flex-col md:flex-row items-center justify-between`}
              >
                <div
                  className={`flex justify-between md:justify-start items-center w-full md:w-auto`}
                >
                  <img
                    crossorigin="anonymous"
                    src={product.imageUrl}
                    alt={product.name}
                    className={`w-20 md:w-36 h-auto md:h-36 rounded-lg mr-2 ml-2 object-cover self-start`}
                  />
                  <div
                    className={`flex flex-col flex-1 md:flex-auto overflow-hidden`}
                  >
                    <p
                      className={`text-sm md:text-xl text-white font-medium mb-4 whitespace-nowrap text-ellipsis`}
                    >
                      {product.name}
                    </p>
                    <p
                      className={`text-sm md:text-2xl text-white font-normal mb-4`}
                    >
                      {t("price")}:{" "}
                      <span className={`text-sm md:text-2xl`}>
                        {product.priceAfterDiscount ?? product.price}{" "}
                        {t(currencySymbol)}
                      </span>
                    </p>
                    <p
                      className={`text-xs md:text-lg text-white font-normal flex items-center mb-4`}
                    >
                      {t("quantity")}:{" "}
                      <button onClick={() => increaseQuantity(product._id)}>
                        <AiOutlinePlusCircle
                          className={`cursor-pointer w-6 h-6 md:w-8 md:h-8 text-white opacity-75 hover:opacity-100 mx-2 duration-300`}
                        >
                          +
                        </AiOutlinePlusCircle>{" "}
                      </button>
                      <span className={`text-2xl`}>
                        {product.productCountInCart}
                      </span>
                      <button
                        disabled={product.productCountInCart <= 1}
                        onClick={() => decreaseQuantity(product._id)}
                      >
                        <AiOutlineMinusCircle
                          className={`cursor-pointer w-6 h-6 md:w-8 md:h-8 text-white opacity-75 hover:opacity-100 mx-2 duration-300`}
                        >
                          -
                        </AiOutlineMinusCircle>
                      </button>
                      <ImBin
                        className={`text-red-500 w-8 h-8 duration-300 hover:text-red-600 cursor-pointer block md:hidden`}
                        onClick={() => removeItem(product._id)}
                      />
                      <button
                        className={`py-1 px-4 bg-red-600 text-white rounded-2xl border-2 border-transparent duration-300 hover:text-red-600 hover:bg-transparent hover:border-red-600`}
                        onClick={() => removeItem(product._id)}
                      >
                        {t("remove")}
                      </button>
                    </p>
                  </div>
                </div>
                {/* <ImBin
                  className={`text-red-500 w-10 h-10 duration-300 hover:text-red-600 cursor-pointer hidden md:block`}
                  onClick={() => removeItem(product._id)}
                /> */}
                {/* <div className={`w-[40%] flex justify-center`}></div> */}
              </li>
            ))}
          </ul>
          <div
            className={`flex flex-col self-stretch md:items-center justify-between px-4 py-2 border border-[#5776a5] rounded-2xl mb-4 w-full md:w-[70%] mx-auto`}
          >
            <div
              className={`flex flex-col md:flex-row items-stretch md:items-center justify-between w-full mb-2`}
            >
              <p className="text-xl text-[#5776a5] pb-2 md:mb-0 font-semibold border-b md:border-none border-[#5776a5]">
                <span>{t("order_total")}:</span>
                <span className={`font-medium`}>
                  {totalPrice} {t(currencySymbol)}
                </span>
              </p>
              <p className="text-xl text-[#5776a5]  md:mb-0 font-semibold pb-2 border-b md:border-none border-[#5776a5]">
                <span>{t("service_fee")} :</span>
                <span className={`font-medium`}>
                  {fees}
                  {t(currencySymbol)}
                </span>
              </p>
              <p className="text-xl text-white my-4 md:mb-0 font-semibold bg-[#5776a5] p-1 rounded-xl border-2 border-white flex justify-between">
                <span> {t("total_amount")} : </span>
                <span className={`font-medium`}>
                  {totalPrice + fees} {t(currencySymbol)}
                </span>
              </p>
            </div>
            <button
              onClick={checkOutHandler}
              className={`px-4 py-1 bg-[#5776a5] border-2 border-transparent text-white text-lg hover:bg-transparent hover:border-[#5776a5] hover:text-[#5776a5] duration-300 rounded-2xl `}
            >
              {t("check_out")}
            </button>
          </div>
        </div>
      )}
    </MainSection>
  );
};

export default Cart;
