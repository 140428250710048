import React, { useState } from "react";
import MainSection from "../../UI/MainSection";

import MultiItemCarousel from "../../UI/MultiItemCarousel";

import aramexDelivery from "./../../../assets/advertisment/aramexDelivery.jpg";
import netzoonapp from "./../../../assets/advertisment/netzoonapp.jpg";
import netzoonpayment from "./../../../assets/advertisment/netzoonpayment.jpg";
import netzoonpayments from "./../../../assets/advertisment/netzoonpayments.jpg";
import AdvertismentsCard from "../../UI/AdvertismentsCard";


export default function Advertisments() {
  const [advertisments, setadvertisments] = useState([
    <AdvertismentsCard
      // textSize={"text-xl font-semibold"}
      w="md:w-full md:h-[140rem]"      isCarousel={true}
      imgSrc={aramexDelivery}
      imgAlt={"aramexDelivery"}
      forthClassName={`!h-9 overflow-hidden`}
    />,
    <AdvertismentsCard
      // textSize={"text-xl font-semibold"}
      w="md:w-full md:h-[140rem]"      isCarousel={true}
      imgSrc={netzoonapp}
      imgAlt={"netzoonapp"}
      forthClassName={`!h-9 overflow-hidden`}
    />,
    <AdvertismentsCard
      // textSize={"text-xl font-semibold"}
      w="md:w-full md:h-[140rem]"      isCarousel={true}
      imgSrc={netzoonpayment}
      imgAlt={"netzoonpayment"}
      forthClassName={`!h-9 overflow-hidden`}
    />,
    <AdvertismentsCard
      // textSize={"text-xl font-semibold"}
      w="md:w-full md:h-[140rem]"
      isCarousel={true}
      imgSrc={netzoonpayments}
      imgAlt={netzoonpayments}
      forthClassName={`!h-9 overflow-hidden`}
    />,
  ]);


  return (
    <MainSection className="mt-5 ">

      <MultiItemCarousel
      isCarouselAutoPlayMobile={true}
      autoPlayInterval={3000}
      count0={1}
      count1={1}
      count2={1}
      count3={1}
      count4={1}
      count5={1}
      count6={1}
      items={advertisments}
  
      />
     
    </MainSection>
  );
}
