import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import logo from "../../assets/netzoon-logo.png";
import styles from "./SignUpInfoPage.module.css";

import Toast from "../../utils/Toast";
import { DropZoneHook } from "../../utils/DropzoneHook";
import { user } from "../../services/users";
import MainSection from "../UI/MainSection";
import { useEffect } from "react";
import { countriesCurrenciesSignUp } from "../../constants/countriesSignUp";

const EditProfileForm = () => {
  const { t } = useTranslation();

  const {
    state: {
      userId,
      email,
      username,
      contactName,
      firstMobile,
      secondeMobile,
      thirdMobile,
      subcategory,
      address,
      sellType,
      bio,
      description,
      website,
      link,
      slogn,
      toCountry,
      profilePhoto,
      country,
      coverPhoto,
    },
  } = useLocation();

  console.log(email);
  console.log(address);
  console.log(description);
  console.log(link);
  console.log(toCountry);

  const [profilePhotoFile, setProfilePhotoFile] = useState(null);
  const [coverPhotoFile, setCoverPhotoFile] = useState(null);

  const {
    getRootProps: getRootPropscoverPhoto,
    getInputProps: getInputPropscoverPhoto,
    isFocused: isFocusedcoverPhoto,
    isDragAccept: isDragAcceptcoverPhoto,
    isDragReject: isDragRejectcoverPhoto,
  } = DropZoneHook({
    onDropCallback: (accepted) => {
      setCoverPhotoFile(accepted[0]);
    },
    acceptedTypes: {
      "image/png": [".png", ".jpg"],
    },
    maxFiles: 1,
  });

  const {
    getRootProps: getRootPropsprofilePhoto,
    getInputProps: getInputPropsprofilePhoto,
    isFocused: isFocusedprofilePhoto,
    isDragAccept: isDragAcceptprofilePhoto,
    isDragReject: isDragRejectprofilePhoto,
  } = DropZoneHook({
    onDropCallback: (accepted) => {
      setProfilePhotoFile(accepted[0]);
    },
    acceptedTypes: {
      "image/png": [".png", ".jpg"],
    },
    maxFiles: 1,
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
    watch,
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      username: "",
      contactName: "",
      firstMobile: "",
      secondeMobile: "",
      thirdMobile: "",
      subcategory: "",
      address: "",
      sellType: "",
      bio: "",
      description: "",
      website: "",
      link: "",
      slogn: "",
      toCountry: "",
      country: "",
    },
  });

  const { mutate: updateUser } = useMutation({
    mutationFn: async ({ data, userId }) =>
      await user.updateUser({ data, userId }),
    onSuccess(data) {
      Toast({
        Data: t("profile_updated"),
        type: "success",
      });
    },
    onError(error) {
      Toast({
        Data: t(error.message),
        type: "error",
      });
    },
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("userType", data.userType);
    formData.append("email", data.email);
    formData.append("username", data.username);
    formData.append("contactName", data.contactName);
    formData.append("description", data.description);
    formData.append("website", data.website);
    formData.append("link", data.link);
    formData.append("bio", data.bio);
    formData.append("city", data.city);
    formData.append("firstMobile", data.firstMobile);
    formData.append("secondeMobile", data.secondeMobile);
    formData.append("thirdMobile", data.thirdMobile);
    formData.append("slogn", data.slogn);
    formData.append("toCountry", data.toCountry);
    formData.append("country", data.country);
    formData.append("sellType", data.sellType);
    formData.append("subcategory", data.subcategory);

    formData.append("address", data.address);
    if (typeof profilePhotoFile?.name == "string") {
      formData.append("profilePhoto", profilePhotoFile);
    }
    if (typeof coverPhotoFile?.name == "string") {
      formData.append("coverPhoto", coverPhotoFile);
    }

    updateUser({ data: formData, userId });
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#5776a5",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#5776a5",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocusedprofilePhoto ? focusedStyle : {}),
      ...(isDragAcceptprofilePhoto ? acceptStyle : {}),
      ...(isDragRejectprofilePhoto ? rejectStyle : {}),
    }),
    [acceptStyle, baseStyle, focusedStyle, rejectStyle]
  );

  useEffect(() => {
    setValue("address", address);
    setValue("bio", bio);
    setValue("contactName", contactName);
    setValue("description", description);
    setValue("email", email);
    setValue("firstMobile", firstMobile);
    setValue("secondeMobile", secondeMobile);
    setValue("thirdMobile", thirdMobile);
    setValue("link", link);
    setValue("sellType", sellType);
    setValue("website", website);
    setValue("slogn", slogn);
    setValue("subcategory", subcategory);
    setValue("username", username);
    setValue("toCountry", toCountry);
    setValue("country", country);
    setProfilePhotoFile(profilePhoto);
    setCoverPhotoFile(coverPhoto);
  }, []);
  return (
    <MainSection
      className={`!mt-52 md:!mt-22 min-h-screen  flex items-center flex-col `}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`flex justify-between flex-col-reverse gap-x-10 lg:flex-row w-[95%] mx-auto`}
        >
          <div
            className={`py-2 px-2 bg-[#5776A5] bg-opacity-10 rounded-2xl w-full`}
          >
            <h2 className={`text-2xl font-semibold text-[#5776a5] mb-6`}>
              {t("editprofile")}
            </h2>
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="email" className={`text-xl mb-2 md:mb-0`}>
                {t("enterTheEmailId")}
              </label>
              <input
                id="email"
                type="email"
                name="email"
                {...register("email", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                })}
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.email && (
              <p className="text-red-500 ">{errors.email.message}</p>
            )}
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="username" className={`text-xl mb-2 md:mb-0`}>
                {t("enterTheUserName")}
              </label>
              <input
                id="username"
                type="text"
                name="username"
                {...register("username", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                })}
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.username && (
              <p className="text-red-500 ">{errors.username.message}</p>
            )}
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="contactName" className={`text-xl mb-2 md:mb-0`}>
                {t("enterThecontactName")}
              </label>
              <input
                id="contactName"
                type="text"
                name="contactName"
                {...register("contactName", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                })}
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.contactName && (
              <p className="text-red-500 ">{errors.contactName.message}</p>
            )}
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label className={`text-xl`}>{t("country")}</label>

              <select
                id="country"
                name="country"
                {...register("country", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                })}
                className={`w-[90%] md:w-[40%] outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl ml-2 duration-300`}
              >
                {countriesCurrenciesSignUp?.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.countryName}
                  </option>
                ))}
              </select>
            </div>
            {errors.country && (
              <p className="text-red-500 ">{errors.country.message}</p>
            )}

            <div>
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="firstMobile" className={`text-xl mb-2 md:mb-0`}>
                  {t("enterTheContactNumber1")}
                </label>
                <input
                  id="firstMobile"
                  type="number"
                  name="firstMobile"
                  {...register("firstMobile", {
                    required: {
                      value: true,
                      message: t("required"),
                    },
                  })}
                  className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                />
              </div>
              {errors.firstMobile && (
                <p className="text-red-500 ">{errors.firstMobile.message}</p>
              )}
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label
                  htmlFor="secondeMobile"
                  className={`text-xl mb-2 md:mb-0`}
                >
                  {t("enterTheContactNumber2IfAvailable")}
                </label>
                <input
                  id="secondeMobile"
                  type="number"
                  name="secondeMobile"
                  {...register("secondeMobile", {})}
                  className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                />
              </div>
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="thirdMobile" className={`text-xl mb-2 md:mb-0`}>
                  {t("enterTheContactNumber3IfAvailable")}
                </label>
                <input
                  id="thirdMobile"
                  type="number"
                  name="thirdMobile"
                  {...register("thirdMobile", {})}
                  className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                />
              </div>
            </div>
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="bio" className={`text-xl mb-2 md:mb-0`}>
                {t("enterTheBio")}
              </label>
              <textarea
                {...register("bio", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                  maxLength: {
                    value: 30,
                    message: "The bio will be less 30 char",
                  },
                })}
                id="bio"
                name="bio"
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
              {errors.bio && (
                <p className="text-red-500 ">{errors.bio.message}</p>
              )}
            </div>
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="description" className={`text-xl mb-2 md:mb-0`}>
                {t("enterTheDescription")}
              </label>
              <textarea
                id="description"
                name="description"
                {...register("description", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                })}
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
              {errors.description && (
                <p className="text-red-500 ">{errors.description.message}</p>
              )}
            </div>
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="website" className={`text-xl mb-2 md:mb-0`}>
                {t("enterTheLink")}
              </label>
              <input
                id="website"
                type="text"
                name="website"
                {...register("website", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                })}
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
              {errors.website && (
                <p className="text-red-500 ">{errors.website.message}</p>
              )}
            </div>
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="link" className={`text-xl mb-2 md:mb-0`}>
                {t("enterTheWebLink")}
              </label>
              <input
                id="link"
                type="text"
                name="link"
                {...register("link", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                })}
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.link && (
              <p className="text-red-500 ">{errors.link.message}</p>
            )}
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="slogn" className={`text-xl mb-2 md:mb-0`}>
                {t("enterTheSlogan")}
              </label>
              <input
                id="slogn"
                type="text"
                name="slogn"
                {...register("slogn", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                })}
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.slogn && (
              <p className="text-red-500 ">{errors.slogn.message}</p>
            )}

            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="subcategory" className={`text-xl mb-2 md:mb-0`} s>
                {t("enterTheSubcategory")}
              </label>
              <input
                id="subcategory"
                type="text"
                {...register("subcategory", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                })}
                name="subcategory"
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.subcategory && (
              <p className="text-red-500 ">{errors.subcategory.message}</p>
            )}

            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="address" className={`text-xl mb-2 md:mb-0`}>
                {t("enterTheAddress")}
              </label>
              <input
                id="address"
                type="text"
                name="address"
                {...register("address", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                })}
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.address && (
              <p className="text-red-500 ">{errors.address.message}</p>
            )}

            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="sellType" className={`text-xl mb-2 md:mb-0`}>
                {t("methodOfSaleRetailOrWholesale")}
              </label>
              <input
                id="sellType"
                type="text"
                name="sellType"
                {...register("sellType", {})}
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>

            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <label htmlFor="toCountry" className={`text-xl mb-2 md:mb-0`}>
                {t("whereToSellToWhichCountry")}
              </label>
              <input
                id="toCountry"
                type="text"
                name="toCountry"
                {...register("toCountry", {
                  required: {
                    value: true,
                    message: t("required"),
                  },
                })}
                className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
              />
            </div>
            {errors.toCountry && (
              <p className="text-red-500 ">{errors.toCountry.message}</p>
            )}

            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <div className="container">
                <div {...getRootPropsprofilePhoto({ style })}>
                  <input {...getInputPropsprofilePhoto()} />
                  <p>{t("profilePhoto")}</p>
                </div>
                {profilePhotoFile && (
                  <img
                    crossorigin="anonymous"
                    className="h-1/2 w-1/2 md:h-1/2 md:w-1/2  object-cover"
                    src={
                      typeof profilePhotoFile.name == "string"
                        ? URL?.createObjectURL(profilePhotoFile)
                        : profilePhotoFile
                    }
                    alt={profilePhotoFile?.name || "profile photo"}
                  />
                )}
              </div>
            </div>
            <div
              className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
            >
              <div className="container">
                <div {...getRootPropscoverPhoto({ style })}>
                  <input {...getInputPropscoverPhoto()} />
                  <p>{t("coverPhoto")}</p>
                </div>
                {coverPhotoFile && (
                  <img
                    crossorigin="anonymous"
                    className="h-1/2 w-1/2 md:h-1/2 md:w-1/2  object-cover"
                    src={
                      typeof coverPhotoFile.name == "string"
                        ? URL?.createObjectURL(coverPhotoFile)
                        : coverPhotoFile
                    }
                    alt={coverPhotoFile.name}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={`flex flex-col   `}>
            <img
              crossorigin="anonymous"
              src={logo}
              alt={logo.toString().slice(0, 3)}
              className={`w-[200px] md:w-[400px] ${styles.logo} opacity-20`}
            />
          </div>
        </div>
        <div className={`mt-4 mb-8 flex w-[90%] mx-auto`}>
          <button
            type="submit"
            disabled={!isDirty || !isValid}
            className={`py-1 px-2  bg-[#5776a5] border-2 border-[#5776a5] text-white text-xl hover:text-[#5776a5] hover:bg-transparent duration-300 rounded-md`}
          >
            {t("edit")}
          </button>
        </div>
      </form>
    </MainSection>
  );
};

export default EditProfileForm;
