import React, { useMemo, useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import axios from "axios";
import { user } from "../../../services/users";
import { MdOutlineWorkOutline } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { deliveryTypes } from "../../../constants/deliveryTypes";
import MainSection from "../../UI/MainSection";
import logo from "../../../assets/netzoon-logo.png";
import styles from "./SignUpInfoPage.module.css";

import Toast from "../../../utils/Toast";

import { DropZoneHook } from "../../../utils/DropzoneHook";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { privacyPolicy } from "../../../services/privacypolicy";
import { useDispatch, useSelector } from "react-redux";
import { useClampText } from "@yukimoto/use-clamp-text";
import { countriesCurrenciesSignUp } from "../../../constants/countriesSignUp";
import { useEditUserMutation } from "../../../api/user/queries";
import { useCreateSendBirdUserMutation } from "../../../api/sendBird/queries";
import { setUser } from "../../../store/userSlice";
const SignUp = () => {
  const [tradeLicenseFile, setTradeLicenseFile] = useState(null);
  const [profilePhotoFile, setProfilePhotoFile] = useState(null);
  const [coverPhotoFile, setCoverPhotoFile] = useState(null);
  const [frontIdPhotoFile, setFrontIdPhotoFile] = useState(null);
  const [backIdPhotoFile, setBackIdPhotoFile] = useState(null);
  const [deliveryPermitPhotoFile, setDeliveryPermitPhotoFile] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    countriesCurrenciesSignUp[0].code
  );
  const navigate = useNavigate();
  const { userId } = useParams();
  const { t } = useTranslation();
  const options = [
    { value: "", label: "Select an option" },
    { value: "local_company", label: "local_companies" },
    { value: "real_estate", label: "Real Estate" },
    { value: "trader", label: "Trader" },
    { value: "car", label: "Cars" },
    { value: "sea_companies", label: "Sea Companies" },
    { value: "user", label: "Clients" },
    { value: "freezone", label: "Free Zone" },
    { value: "news_agency", label: "New Agency" },
    { value: "delivery_company", label: "Delivery Company" },
    { value: "factory", label: "factories" },
  ];

  const dispatch = useDispatch();
  const { mutate: editUser } = useEditUserMutation();
  // Function to fetch cities
  const fetchCities = async (countryCode) => {
    try {
      const response = await axios.post(
        `https://back.netzoon.com/aramex/fetchCities?countryCode=${countryCode}`
      );
      if (response.data && response.data.Cities) {
        setCities(response.data.Cities);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
      // Handle error appropriately
    }
  };

  // useEffect to call fetchCities when selectedCountry changes
  useEffect(() => {
    fetchCities(selectedCountry);
  }, [selectedCountry]);

  const { data: Categoriesoffactories } = useQuery({
    queryKey: ["Categoriesoffactories"],
    queryFn: user.getCategoriesOfFactories,
  });

  const {
    getRootProps: getRootPropsTradeLicense,
    getInputProps: getInputPropsTradeLicense,
    isFocused: isFocusedTradeLicense,
    isDragAccept: isDragAcceptTradeLicense,
    isDragReject: isDragRejectTradeLicense,
  } = DropZoneHook({
    onDropCallback: (accepted) => {
      setTradeLicenseFile(accepted[0]);
    },
    acceptedTypes: {
      "image/png": [".png", ".jpg"],
      "application/pdf": [".pdf"],
    },

    maxFiles: 1,
  });

  const {
    getRootProps: getRootPropscoverPhoto,
    getInputProps: getInputPropscoverPhoto,
    isFocused: isFocusedcoverPhoto,
    isDragAccept: isDragAcceptcoverPhoto,
    isDragReject: isDragRejectcoverPhoto,
  } = DropZoneHook({
    onDropCallback: (accepted) => {
      setCoverPhotoFile(accepted[0]);
    },
    acceptedTypes: {
      "image/png": [".png", ".jpg"],
    },
    maxFiles: 1,
  });

  const {
    getRootProps: getRootPropsBackIdPhoto,
    getInputProps: getInputPropsBackIdPhoto,
    isFocused: isFocusedBackIdPhoto,
    isDragAccept: isDragAcceptBackIdPhoto,
    isDragReject: isDragRejectBackIdPhoto,
  } = DropZoneHook({
    onDropCallback: (accepted) => {
      setBackIdPhotoFile(accepted[0]);
    },
    acceptedTypes: {
      "image/png": [".png", ".jpg"],
      "application/pdf": [".pdf"],
    },

    maxFiles: 1,
  });
  const {
    getRootProps: getRootPropsFrontIdPhoto,
    getInputProps: getInputPropsFrontIdPhoto,
    isFocused: isFocusedFrontIdPhoto,
    isDragAccept: isDragAcceptFrontIdPhoto,
    isDragReject: isDragRejectFrontIdPhoto,
  } = DropZoneHook({
    onDropCallback: (accepted) => {
      setFrontIdPhotoFile(accepted[0]);
    },
    acceptedTypes: {
      "image/png": [".png", ".jpg"],
      "application/pdf": [".pdf"],
    },

    maxFiles: 1,
  });

  const {
    getRootProps: getRootPropsprofilePhoto,
    getInputProps: getInputPropsprofilePhoto,
    isFocused: isFocusedprofilePhoto,
    isDragAccept: isDragAcceptprofilePhoto,
    isDragReject: isDragRejectprofilePhoto,
  } = DropZoneHook({
    onDropCallback: (accepted) => {
      setProfilePhotoFile(accepted[0]);
    },
    acceptedTypes: {
      "image/png": [".png", ".jpg"],
    },
    maxFiles: 1,
  });
  const {
    getRootProps: getRootPropsdeliveryPermitPhoto,
    getInputProps: getInputPropsdeliveryPermitPhoto,
    isFocused: isFocuseddeliveryPermitPhoto,
    isDragAccept: isDragAcceptdeliveryPermitPhoto,
    isDragReject: isDragRejectdeliveryPermitPhoto,
  } = DropZoneHook({
    onDropCallback: (accepted) => {
      setDeliveryPermitPhotoFile(accepted[0]);
    },
    acceptedTypes: {
      "image/png": [".png", ".jpg"],
    },
    maxFiles: 1,
  });

  const [UserData, setFormData] = useState(null);

  const HitApi = () => {
    userId ? editUser({ id: userId, data: UserData }) : CreateUser(UserData);
  };
  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    watch,
  } = useForm({
    mode: "all",
    defaultValues: {
      privacypolicy: false,
      userType: "user",
      city: "",
      country: countriesCurrenciesSignUp[0].code,
      email: "",
      contactName: "",
      username: "",
      password: "",
      rePassword: "",
      firstMobile: "",
      secondeMobile: "",
      thirdMobile: "",
      bio: "",
      description: "",
      website: "",
      link: "",
      locationType: "",
      floorNum: "",
      slogn: "",
      deliverable: "",
      subcategory: "",
      addressDetails: "",
      isFreeZoon: false,
      isService: false,
      companyProductsNumber: "",
      title: "",
      sellType: "",
      toCountry: "",
      isThereWarehouse: false,
      deliveryType: "inside",
      isThereFoodsDelivery: false,
      deliveryCarsNum: "",
      deliveryMotorsNum: "",
    },
  });
  console.log(errors);
  useEffect(() => {
    register("country", { required: true });
  }, [register]);

  const { mutate: createSendBirdUser } = useCreateSendBirdUserMutation();
  const { mutate: CreateUser } = useMutation({
    mutationFn: (data) => user.CreateUser(data),
    onSuccess(data) {
      Toast({
        Data: t("account_created"),
        type: "success",
      });

      setOpen(false);
      localStorage.setItem("user", JSON.stringify(data.data));
      createSendBirdUser({
        user_id: data.result.username,
        nickname: data.result.username,
        profile_url: "",
      });

      window.location.replace("/");
    },
    onError(error) {
      Toast({
        Data: t(error.message),
        type: "error",
      });
    },
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("userType", data.userType);

    if (!userId) {
      formData.append("email", data.email);
      formData.append("password", data.password);
      formData.append("username", data.username);
      formData.append("profilePhoto", profilePhotoFile);
    }

    formData.append("contactName", data.contactName);
    formData.append("description", data.description);
    formData.append("website", data.website);
    formData.append("link", data.link);
    formData.append("bio", data.bio);
    formData.append("country", data.country);

    formData.append("city", data.city);

    formData.append("firstMobile", data.firstMobile);
    formData.append("secondeMobile", data.secondeMobile);
    formData.append("thirdMobile", data.thirdMobile);
    formData.append("slogn", data.slogn);
    formData.append("locationType", data.locationType);
    formData.append("address", data.address);
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      formData.append("mainAccount", user.result.email);
      formData.append("withAdd", true);
    }
    if (data.userType === "local_company") {
      if (!tradeLicenseFile) {
        Toast({
          Data: t("please_upload_copyOfTradeLicense"),
          type: "error",
        });
        return;
      }

      if (!frontIdPhotoFile) {
        Toast({
          Data: t("please_upload_frontIdPhoto"),
          type: "error",
        });
        return;
      }
      if (!backIdPhotoFile) {
        Toast({
          Data: t("please_upload_backIdPhoto"),
          type: "error",
        });
        return;
      }

      formData.append("deliverable", data.deliverable ? true : false);
      formData.append("subcategory", data.subcategory);
      formData.append("isFreeZoon", data.isFreeZoon ? true : false);
      formData.append("isService", data.isService ? true : false);
      formData.append("companyProductsNumber", data.companyProductsNumber);
      formData.append("sellType", data.sellType);
      formData.append("toCountry", data.toCountry);
      formData.append("tradeLicensePhoto", tradeLicenseFile);
      formData.append("coverPhoto", coverPhotoFile);
      formData.append("frontIdPhoto", frontIdPhotoFile);
      formData.append("backIdPhoto", backIdPhotoFile);
    } else if (
      data.userType ===
      ("real_estate" || "trader" || "car" || "sea_companies" || "freezone")
    ) {
      if (!tradeLicenseFile) {
        Toast({
          Data: t("please_upload_copyOfTradeLicense"),
          type: "error",
        });
        return;
      }

      if (!frontIdPhotoFile) {
        Toast({
          Data: t("please_upload_frontIdPhoto"),
          type: "error",
        });
        return;
      }
      if (!backIdPhotoFile) {
        Toast({
          Data: t("please_upload_backIdPhoto"),
          type: "error",
        });
        return;
      }
      formData.append("subcategory", data.subcategory);

      formData.append("isFreeZoon", data.isFreeZoon ? true : false);
      formData.append("companyProductsNumber", data.companyProductsNumber);
      formData.append("sellType", data.sellType);
      formData.append("toCountry", data.toCountry);
      formData.append("tradeLicensePhoto", tradeLicenseFile);
      formData.append("coverPhoto", coverPhotoFile);
      formData.append("frontIdPhoto", frontIdPhotoFile);
      formData.append("backIdPhoto", backIdPhotoFile);
    } else if (data.userType === "user") {
      formData.append("coverPhoto", coverPhotoFile);
      formData.append("isFreeZoon", data.isFreeZoon);
    } else if (data.userType === "factory") {
      formData.append("deliverable", data.deliverable ? true : false);
      formData.append("subcategory", data.subcategory);

      formData.append("isFreeZoon", data.isFreeZoon);
      formData.append("isService", data.isService ? true : false);
      formData.append("companyProductsNumber", data.companyProductsNumber);
      formData.append("sellType", data.sellType);
      formData.append("toCountry", data.toCountry);

      if (!tradeLicenseFile) {
        Toast({
          Data: t("please_upload_copyOfTradeLicense"),
          type: "error",
        });
        return;
      }

      if (!frontIdPhotoFile) {
        Toast({
          Data: t("please_upload_frontIdPhoto"),
          type: "error",
        });
        return;
      }
      if (!backIdPhotoFile) {
        Toast({
          Data: t("please_upload_backIdPhoto"),
          type: "error",
        });
        return;
      }

      formData.append("tradeLicensePhoto", tradeLicenseFile);
      formData.append("coverPhoto", coverPhotoFile);
      formData.append("frontIdPhoto", frontIdPhotoFile);
      formData.append("backIdPhoto", backIdPhotoFile);
      formData.append("title", data.title);
    } else if (data.userType === "delivery_company") {
      if (!tradeLicenseFile) {
        Toast({
          Data: t("please_upload_copyOfTradeLicense"),
          type: "error",
        });
        return;
      }

      if (!frontIdPhotoFile) {
        Toast({
          Data: t("please_upload_frontIdPhoto"),
          type: "error",
        });
        return;
      }
      if (!backIdPhotoFile) {
        Toast({
          Data: t("please_upload_backIdPhoto"),
          type: "error",
        });
        return;
      }

      if (!deliveryPermitPhotoFile) {
        Toast({
          Data: t("please_upload_deliveryPermitPhoto"),
          type: "error",
        });
        return;
      }
      formData.append("subcategory", data.subcategory);
      formData.append("isFreeZoon", data.isFreeZoon ? true : false);
      formData.append("companyProductsNumber", data.companyProductsNumber);
      formData.append("sellType", data.sellType);
      formData.append("toCountry", data.toCountry);
      formData.append("isThereWarehouse", data.isThereWarehouse);
      formData.append("isThereFoodsDelivery", data.isThereFoodsDelivery);
      formData.append("deliveryCarsNum", data.deliveryCarsNum);
      formData.append("deliveryMotorsNum", data.deliveryMotorsNum);
      formData.append("deliveryType", data.deliveryType);
      formData.append("tradeLicensePhoto", tradeLicenseFile);
      formData.append("coverPhoto", coverPhotoFile);
      formData.append("frontIdPhoto", frontIdPhotoFile);
      formData.append("backIdPhoto", backIdPhotoFile);
      formData.append("deliveryPermitPhoto", deliveryPermitPhotoFile);
    } else {
      if (!tradeLicenseFile) {
        Toast({
          Data: t("please_upload_copyOfTradeLicense"),
          type: "error",
        });
        return;
      }

      if (!coverPhotoFile) {
        Toast({
          Data: t("please_upload_coverPhoto"),
          type: "error",
        });
        return;
      }
      if (!profilePhotoFile && !userId) {
        Toast({
          Data: t("please_upload_profilePhoto"),
          type: "error",
        });
        return;
      }
      formData.append("isFreeZoon", data.isFreeZoon);
      formData.append("toCountry", data.toCountry);
      formData.append("tradeLicensePhoto", tradeLicenseFile);
      formData.append("coverPhoto", coverPhotoFile);
    }
    setFormData(formData);
    setOpen(true);
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#5776a5",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#5776a5",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocusedFrontIdPhoto || isFocusedBackIdPhoto ? focusedStyle : {}),
      ...(isDragAcceptFrontIdPhoto || isDragAcceptBackIdPhoto
        ? acceptStyle
        : {}),
      ...(isDragRejectFrontIdPhoto || isDragRejectBackIdPhoto
        ? rejectStyle
        : {}),
    }),
    [
      isFocusedFrontIdPhoto,
      isFocusedBackIdPhoto,
      isDragAcceptFrontIdPhoto,
      isDragAcceptBackIdPhoto,
      isDragRejectFrontIdPhoto,
      isDragRejectBackIdPhoto,
      acceptStyle,
      baseStyle,
      focusedStyle,
      rejectStyle,
    ]
  );
  const [open, setOpen] = useState(false);

  const { data: PrivacyPolicyData } = useQuery({
    queryKey: ["privacypolicy"],
    queryFn: privacyPolicy.GetPrivacyPolicy,
  });

  const lang = useSelector((state) => state.lang.lang);
  const cancelButtonRef = useRef(null);

  const {
    longEnoughToClamp,
    textContainerRef,
    addOnsContainerRef,
    wrapperContainerRef,
    clamped,
    clampedText,
    toggleClamp,
  } = useClampText({
    originalText:
      lang === "ar"
        ? PrivacyPolicyData?.data?.results[0]?.text
        : PrivacyPolicyData?.data?.results[0]?.textEn,
    lines: 2, // At most how many lines
    endSpaceNumber: 4, // how many trailing space,
    unitSpaceChar: "*", // single trailing space should be the width of this char
    debounceTime: 700, // in miliseconds
  });

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex  sm:items-start  rounded-lg">
                      <div className="mx-auto  flex h-12  flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className=" w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {t("PrivacyPolicy")}
                        </Dialog.Title>
                        <div className="mt-2  ">
                          <div className=" h-[200px] overflow-auto">
                            {lang === "ar"
                              ? PrivacyPolicyData?.data?.results[0]?.text
                              : PrivacyPolicyData?.data?.results[0]?.textEn}
                            {/* <div
                              className="  text-[0.8rem]   l bg-red-500 overflow-y-auto md:text-base  "
                              ref={wrapperContainerRef}
                            >
                              <span ref={textContainerRef}>{clampedText}</span>
                              {longEnoughToClamp ? (
                                <button
                                  ref={addOnsContainerRef}
                                  onClick={toggleClamp}
                                >
                                  {clamped
                                    ? `... ${"\u2193"} ${t("readMore")}`
                                    : `... ${"\u2191"} ${t("readLess")}`}
                                </button>
                              ) : null}
                            </div> */}
                          </div>

                          <div
                            className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                          >
                            <label
                              htmlFor="privacypolicy"
                              className={`text-xl mb-2 md:mb-0`}
                            >
                              {t("accept_condition_policy")}
                            </label>
                            <input
                              id="privacypolicy"
                              type="checkbox"
                              name="privacypolicy"
                              {...register("privacypolicy")}
                              className={` ml-2 outline-none px-4 flex justify-center items-center  rounded-2xl duration-300`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {watch("privacypolicy") === true ? (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={HitApi}
                      >
                        {t("ok")}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <MainSection
        className={`!mt-52 md:!mt-22 min-h-screen  flex items-center flex-col `}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`flex justify-between flex-col-reverse gap-x-10 lg:flex-row w-[95%] mx-auto`}
          >
            <div
              className={`py-2 px-2 bg-[#5776A5] bg-opacity-10 rounded-2xl w-full`}
            >
              <h2 className={`text-2xl font-semibold text-[#5776a5] mb-6`}>
                {t("Signup to Netzoon")}
              </h2>
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="userType" className={`text-xl mb-2 md:mb-0`}>
                  {t("selectTheUserType")}
                </label>
                <select
                  {...register("userType", {
                    required: {
                      value: true,
                      message: t("required"),
                    },
                  })}
                  className={`outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl ml-2 duration-300 `}
                  id="userType"
                  name="userType"
                >
                  {options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {t(`${option.label}`)}
                    </option>
                  ))}
                </select>
              </div>
              {errors.userType && (
                <p className="text-red-500 mb-3 ">{errors.userType.message}</p>
              )}
              {watch("userType") === "factory" ? (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="userType"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("categoriesOfFactories")}
                    </label>
                    <select
                      {...register("title", {
                        required: {
                          value: true,
                          message: t("required"),
                        },
                      })}
                      id="title"
                      name="title"
                      className={`outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl ml-2 duration-300 `}
                    >
                      {Categoriesoffactories?.data?.map((option, index) => (
                        <option key={index} value={option.title}>
                          {option.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.title && (
                    <p className="text-red-500 mb-3 ">{errors.title.message}</p>
                  )}
                </>
              ) : (
                ""
              )}
              {!userId && (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="username"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {watch("userType") === "user"
                        ? t("user_name")
                        : t("business_name")}
                    </label>
                    <input
                      id="username"
                      type="text"
                      name="username"
                      {...register("username", {
                        required: {
                          value: true,
                          message: t("username_required"),
                        },
                      })}
                      className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>
                  {errors.username && (
                    <p className="text-red-500 mb-3 ">
                      {errors.username.message}
                    </p>
                  )}

                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label htmlFor="email" className={`text-xl mb-2 md:mb-0`}>
                      {t("email")}
                    </label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      {...register("email", {
                        required: {
                          value: true,
                          message: t("email_condition"),
                        },
                      })}
                      className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>

                  {errors.email && (
                    <p className="text-red-500 mb-3 ">{errors.email.message}</p>
                  )}
                </>
              )}

              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="contactName" className={`text-xl mb-2 md:mb-0`}>
                  {t("contact_name")}
                </label>
                <input
                  id="contactName"
                  type="text"
                  name="contactName"
                  {...register("contactName", {
                    required: {
                      value: true,
                      message: t("required"),
                    },
                  })}
                  className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                />
              </div>
              {errors.contactName && (
                <p className="text-red-500 mb-3 ">
                  {errors.contactName.message}
                </p>
              )}
              <div>
                <div
                  className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                >
                  <label
                    htmlFor="firstMobile"
                    className={`text-xl mb-2 md:mb-0`}
                  >
                    {t("phone_number")}
                  </label>
                  <input
                    id="firstMobile"
                    type="number"
                    name="firstMobile"
                    {...register("firstMobile", {
                      required: {
                        value: true,
                        message: t("required"),
                      },
                    })}
                    className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                  />
                </div>
                {errors.firstMobile && (
                  <p className="text-red-500 mb-3 ">
                    {errors.firstMobile.message}
                  </p>
                )}

                {!userId && (
                  <>
                    <div
                      className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                    >
                      <label
                        htmlFor="password"
                        className={`text-xl mb-2 md:mb-0`}
                      >
                        {t("password")}
                      </label>
                      <input
                        id="password"
                        type="password"
                        name="password"
                        {...register("password", {
                          required: {
                            value: true,
                            message: t("password_required"),
                          },
                          validate: (val) => {
                            if (
                              watch("rePassword") !== val &&
                              watch("rePassword") !== ""
                            ) {
                              return "Your passwords do no match";
                            }
                          },
                          min: {
                            value: 8,
                            message: "password_condition",
                          },
                        })}
                        className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>

                    {errors.password && (
                      <p className="text-red-500 mb-3 ">
                        {errors.password.message}
                      </p>
                    )}

                    <div
                      className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                    >
                      <label
                        htmlFor="password"
                        className={`text-xl mb-2 md:mb-0`}
                      >
                        {t("password_confirmation")}
                      </label>
                      <input
                        id="password"
                        type="password"
                        {...register("rePassword", {
                          required: {
                            value: true,
                            message: t("password_required"),
                          },
                          validate: (val) => {
                            if (
                              watch("password") !== val &&
                              watch("password") !== ""
                            ) {
                              return "Your passwords do no match";
                            }
                          },
                          min: {
                            value: 8,
                            message: t("password_condition"),
                          },
                        })}
                        className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.rePassword && (
                      <p className="text-red-500 mb-3 ">
                        {errors.rePassword.message}
                      </p>
                    )}
                  </>
                )}
              </div>
              {/* <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="description" className={`text-xl mb-2 md:mb-0`}>
                  {t("desc")}
                </label>
                <textarea
                  id="description"
                  name="description"
                  {...register("description")}
                  className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                />
              </div> */}
              {/* <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="website" className={`text-xl mb-2 md:mb-0`}>
                  {t("website")}
                </label>
                <input
                  id="website"
                  type="text"
                  name="website"
                  {...register("website")}
                  className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                />
              </div>
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="link" className={`text-xl mb-2 md:mb-0`}>
                  {t("link")}
                </label>
                <input
                  id="link"
                  type="text"
                  name="link"
                  {...register("link")}
                  className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                />
              </div>
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="slogn" className={`text-xl mb-2 md:mb-0`}>
                  {t("slogn")}
                </label>
                <input
                  id="slogn"
                  type="text"
                  name="slogn"
                  {...register("slogn")}
                  className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                />
              </div> */}
              {watch("userType") === "delivery_company" ? (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="isThereWarehouse"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("is_there_warehouse")}
                    </label>
                    <input
                      id="isThereWarehouse"
                      type="checkbox"
                      name="isThereWarehouse"
                      {...register("isThereWarehouse")}
                      className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>

                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="isThereFoodsDelivery"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("is_there_food_delivery")}
                    </label>
                    <input
                      id="isThereFoodsDelivery"
                      type="checkbox"
                      name="isThereFoodsDelivery"
                      {...register("isThereFoodsDelivery")}
                      className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="deliveryCarsNum"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("deliveryCarsNum")}
                    </label>
                    <input
                      id="deliveryCarsNum"
                      type="number"
                      name="deliveryCarsNum"
                      {...register("deliveryCarsNum", {
                        required: {
                          value: true,
                          message: t("required"),
                        },
                      })}
                      className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>
                  {errors.deliveryCarsNum && (
                    <p className="text-red-500 mb-3 ">
                      {errors.deliveryCarsNum.message}
                    </p>
                  )}
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="deliveryMotorsNum"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("deliveryMotorsNum")}
                    </label>
                    <input
                      id="deliveryMotorsNum"
                      type="number"
                      name="deliveryMotorsNum"
                      {...register("deliveryMotorsNum", {
                        required: {
                          value: true,
                          message: t("required"),
                        },
                      })}
                      className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>
                  {errors.deliveryMotorsNum && (
                    <p className="text-red-500 mb-3 ">
                      {errors.deliveryMotorsNum.message}
                    </p>
                  )}
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="deliveryType"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("delivery_type")}
                    </label>
                    <select
                      {...register("deliveryType")}
                      id="deliveryType"
                      name="deliveryType"
                      className={`outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl ml-2 duration-300 `}
                    >
                      {deliveryTypes?.map((option, index) => (
                        <option key={index} value={option.value}>
                          {t(`${option.title}`)}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.deliveryType && (
                    <p className="text-red-500 mb-3 ">
                      {errors.deliveryType.message}
                    </p>
                  )}
                </>
              ) : (
                ""
              )}
              {/* {watch("userType") === "local_company" ? (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="deliverable"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("Is there delivery")}
                    </label>
                    <input
                      type="checkbox"
                      id="deliverable"
                      name="deliverable"
                      {...register("deliverable")}
                      className={` ml-2 w-10 h-10 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>
                </>
              ) : (
                ""
              )} */}
              {/* {watch("userType") === "local_company" ||
              watch("userType") === "real_estate" ||
              watch("userType") === "trader" ||
              watch("userType") === "car" ||
              watch("userType") === "sea_companies" ||
              watch("userType") === "factory" ? (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="subcategory"
                      className={`text-xl mb-2 md:mb-0`}
                      s
                    >
                      {t("subcategory")}
                    </label>
                    <input
                      id="subcategory"
                      type="text"
                      {...register("subcategory")}
                      name="subcategory"
                      className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>
                </>
              ) : (
                ""
              )} */}
              {/* <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="address" className={`text-xl mb-2 md:mb-0`}>
                  {t("address_and_other_branches")}
                </label>
                <input
                  id="address"
                  type="text"
                  name="address"
                  {...register("address", {
                    required: {
                      value: true,
                      message: t("required"),
                    },
                  })}
                  className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                />
              </div>
              {errors.address && (
                <p className="text-red-500 mb-3 ">{errors.address.message}</p>
              )} */}
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="cou" className={`text-xl mb-2 md:mb-0`}>
                  {t("country")}
                </label>

                <select
                  onChange={(e) => {
                    setValue("country", e.target.value); // manually update React Hook Form state
                    setSelectedCountry(e.target.value); // update local state
                    fetchCities(e.target.value); // fetch cities
                  }}
                  id="cou"
                  name="cou"
                  className="outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl ml-2 duration-300"
                >
                  {countriesCurrenciesSignUp.map((option, index) => (
                    <option key={index} value={option.code}>
                      {t(`${option.countryName}`)}
                    </option>
                  ))}
                </select>
              </div>
              {errors.country && (
                <p className="text-red-500 mb-3 ">{errors.country.message}</p>
              )}
              {/* {watch("userType") === "local_company" ||
              watch("userType") === "real_estate" ||
              watch("userType") === "trader" ||
              watch("userType") === "car" ||
              watch("userType") === "freezone" ||
              watch("userType") === "sea_companies" ||
              watch("userType") === "factory" ? (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="isFreeZoon"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("affiliated_to_a_free_zone")}
                    </label>
                    <input
                      type="checkbox"
                      id="isFreeZoon"
                      name="isFreeZoon"
                      {...register("isFreeZoon")}
                      className={` ml-2 w-10 h-10 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>
                </>
              ) : (
                ""
              )} */}
              {watch("userType") === "local_company" ||
              watch("userType") === "factory" ? (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="isService"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("Do you offer services rather than products")}
                    </label>
                    <input
                      type="checkbox"
                      id="isService"
                      name="isService"
                      {...register("isService")}
                      className={` ml-2 w-10 h-10 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              {/* {watch("userType") === "local_company" ||
              watch("userType") === "real_estate" ||
              watch("userType") === "trader" ||
              watch("userType") === "car" ||
              watch("userType") === "freezone" ||
              watch("userType") === "factory" ||
              watch("userType") === "sea_companies" ? (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="companyProductsNumber"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("number_of_company_products")}
                    </label>
                    <input
                      id="companyProductsNumber"
                      type="number"
                      name="companyProductsNumber"
                      {...register("companyProductsNumber", {})}
                      className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>
                </>
              ) : (
                ""
              )} */}
              {/* {watch("userType") === "local_company" ||
              watch("userType") === "real_estate" ||
              watch("userType") === "trader" ||
              watch("userType") === "car" ||
              watch("userType") === "freezone" ||
              watch("userType") === "factory" ||
              watch("userType") === "sea_companies" ? (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="sellType"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("sell_method")}
                    </label>
                    <input
                      id="sellType"
                      type="text"
                      name="sellType"
                      {...register("sellType")}
                      className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>
                  {errors.sellType && (
                    <p className="text-red-500 mb-4 ">
                      {errors.sellType.message}
                    </p>
                  )}
                </>
              ) : (
                ""
              )} */}
              {/* {watch("userType") === "local_company" ||
              watch("userType") === "real_estate" ||
              watch("userType") === "trader" ||
              watch("userType") === "car" ||
              watch("userType") === "factory" ||
              watch("userType") === "freezone" ||
              watch("userType") === "sea_companies" ? (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <label
                      htmlFor="toCountry"
                      className={`text-xl mb-2 md:mb-0`}
                    >
                      {t("where_to_sell")}
                    </label>
                    <input
                      id="toCountry"
                      type="text"
                      name="toCountry"
                      {...register("toCountry")}
                      className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                    />
                  </div>
                </>
              ) : (
                ""
              )} */}
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label className={`text-xl mb-2 md:mb-0`}>
                  {t("LocationDetails")}
                </label>
              </div>
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="city" className={`text-xl mb-2 md:mb-0`}>
                  {t("chosethecity")}
                </label>
                <select
                  {...register("city", {
                    required: {
                      value: true,
                      message: t("required"),
                    },
                  })}
                  id="city"
                  name="city"
                  className="outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl ml-2 duration-300"
                  disabled={!cities.length} // Disable the select if there are no cities
                >
                  <option>{t("select_city")}</option>
                  {cities.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              {errors.city && (
                <p className="text-red-500 mb-3 ">{errors.city.message}</p>
              )}
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label
                  htmlFor="addressDetails"
                  className={`text-xl mb-2 md:mb-0`}
                >
                  {t("enterTheaddressDetails")}
                </label>
                <input
                  {...register("addressDetails", {
                    required: {
                      value: true,
                      message: t("required"),
                    },
                  })}
                  type="text"
                  id="addressDetails"
                  name="addressDetails"
                  className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                />
              </div>
              {errors.addressDetails && (
                <p className="text-red-500 mb-3 ">
                  {errors.addressDetails.message}
                </p>
              )}
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label htmlFor="floorNum" className={`text-xl mb-2 md:mb-0`}>
                  {t("floorNum")}
                </label>

                <input
                  {...register("floorNum", {
                    required: {
                      value: true,
                      message: t("required"),
                    },
                  })}
                  type="number"
                  name="floorNum"
                  id="floorNum"
                  className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                />
              </div>
              {errors.floorNum && (
                <p className="text-red-500 mb-3 ">{errors.floorNum.message}</p>
              )}
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                <label
                  htmlFor="locationType"
                  className={`text-xl mb-2 md:mb-0`}
                >
                  {t("locationType")}
                </label>
                <div className="flex flex-row">
                  <input
                    {...register("locationType")}
                    type="radio"
                    value="home"
                    name="locationType"
                    className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                  />
                  <FaHome className={`text-[#5776a5] md:text-base w-8 h-8`} />
                </div>

                <div className="flex flex-row">
                  <input
                    {...register("locationType")}
                    id="locationType"
                    name="locationType"
                    type="radio"
                    value="work"
                    className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                  />
                  <MdOutlineWorkOutline className={`text-[#5776a5] w-8 h-8`} />
                </div>
              </div>
              {errors.locationType && (
                <p className="text-red-500 mb-3 ">
                  {errors.locationType.message}
                </p>
              )}
              {watch("userType") === "local_company" ||
              watch("userType") === "real_estate" ||
              watch("userType") === "trader" ||
              watch("userType") === "car" ||
              watch("userType") === "freezone" ||
              watch("userType") === "sea_companies" ||
              watch("userType") === "news_agency" ||
              watch("userType") === "factory" ? (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <div className="container">
                      <div {...getRootPropsTradeLicense({ style })}>
                        <input {...getInputPropsTradeLicense()} />
                        <p>{t("copy_of_trade_license")}</p>
                      </div>
                      {tradeLicenseFile && (
                        <img
                          crossorigin="anonymous"
                          className="h-1/2 w-1/2 md:h-1/2 md:w-1/2  object-cover"
                          src={URL?.createObjectURL(tradeLicenseFile)}
                          alt={tradeLicenseFile.name}
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                {/* <label htmlFor="profilePhoto" className={`text-xl mb-2 md:mb-0`}>
                {t("profilePhoto")}
              </label>
              <input
                required
                id="profilePhoto"
                type="file"
                name="profilePhoto"
                ref={profilePhoto}
                multiple
                onChange={handleProfilePhotoChange}
              /> */}
                {!userId && (
                  <>
                    <div className="container">
                      <div {...getRootPropsprofilePhoto({ style })}>
                        <input {...getInputPropsprofilePhoto()} />
                        <p>{t("profile_photo")}</p>
                      </div>
                      {profilePhotoFile && (
                        <img
                          crossorigin="anonymous"
                          className="h-1/2 w-1/2 md:h-1/2 md:w-1/2  object-cover"
                          src={URL?.createObjectURL(profilePhotoFile)}
                          alt={profilePhotoFile.name}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
              <div
                className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
              >
                {/* <label htmlFor="coverPhoto" className={`text-xl mb-2 md:mb-0`}>
                {t("coverPhoto")}
              </label>
              <input
                required
                id="coverPhoto"
                type="file"
                name="coverPhoto"
                ref={coverPhoto}
                multiple
                onChange={handleCoverPhotoChange}
              /> */}
                <div className="container">
                  <div {...getRootPropscoverPhoto({ style })}>
                    <input {...getInputPropscoverPhoto()} />
                    <p>{t("cover_photo")}</p>
                  </div>
                  {coverPhotoFile && (
                    <img
                      crossorigin="anonymous"
                      className="h-1/2 w-1/2 md:h-1/2 md:w-1/2  object-cover"
                      src={URL?.createObjectURL(coverPhotoFile)}
                      alt={coverPhotoFile.name}
                    />
                  )}
                </div>
              </div>
              {watch("userType") === "local_company" ||
              watch("userType") === "real_estate" ||
              watch("userType") === "trader" ||
              watch("userType") === "car" ||
              watch("userType") === "factory" ||
              watch("userType") === "freezone" ||
              watch("userType") === "sea_companies" ||
              watch("userType") === "delivery_company" ? (
                <>
                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    {/* <label
                    htmlFor="frontIdPhoto"
                    className={`text-xl mb-2 md:mb-0`}
                  >
                    {t("frontIdPhoto")}
                  </label>
                  <input
                    required
                    id="frontIdPhoto"
                    type="file"
                    name="frontIdPhoto"
                    ref={frontIdPhoto}
                    multiple
                    onChange={handleFrontIdPhotoChange}
                  /> */}
                    <div className="container">
                      <div {...getRootPropsFrontIdPhoto({ style })}>
                        <input {...getInputPropsFrontIdPhoto()} />
                        <p>{t("front_id_photo")}</p>
                      </div>
                      {frontIdPhotoFile && (
                        <img
                          crossorigin="anonymous"
                          className="h-1/2 w-1/2 md:h-1/2 md:w-1/2  object-cover"
                          src={URL?.createObjectURL(frontIdPhotoFile)}
                          alt={frontIdPhotoFile.name}
                        />
                      )}
                    </div>
                  </div>

                  <div
                    className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                  >
                    <div className="container">
                      <div {...getRootPropsBackIdPhoto({ style })}>
                        <input {...getInputPropsBackIdPhoto()} />
                        <p>{t("back_id_photo")}</p>
                      </div>
                      {backIdPhotoFile && (
                        <img
                          crossorigin="anonymous"
                          className="h-1/2 w-1/2 md:h-1/2 md:w-1/2  object-cover"
                          src={URL?.createObjectURL(backIdPhotoFile)}
                          alt={backIdPhotoFile.name}
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {watch("userType") === "delivery_company" ? (
                <div
                  className={`flex items-center justify-between flex-col md:flex-row mb-4 pb-2 border-b-[1px] border-[#5776a5]`}
                >
                  <div className="container">
                    <div {...getRootPropsdeliveryPermitPhoto({ style })}>
                      <input {...getInputPropsdeliveryPermitPhoto()} />
                      <p>{t("deliveryPermitPhoto")}</p>
                    </div>
                    {deliveryPermitPhotoFile && (
                      <img
                        crossorigin="anonymous"
                        className="h-1/2 w-1/2 md:h-1/2 md:w-1/2  object-cover"
                        src={URL?.createObjectURL(deliveryPermitPhotoFile)}
                        alt={deliveryPermitPhotoFile.name}
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={`flex flex-col   `}>
              <img
                crossorigin="anonymous"
                src={logo}
                alt={logo.toString().slice(0, 3)}
                className={`w-[200px] md:w-[400px] ${styles.logo} opacity-20`}
              />
              <p
                className={`text-2xl text-[#5776a5] font-semibold text-center mt-8`}
              >
                {t("Stay Connected, Stay Satisfied with Netzoon")}
              </p>
              <p
                className={`mt-8 text-xl font-medium text-[#5776a5] text-center`}
              >
                {t("Already you have an account on Netzoon!")} !
                <Link to={"/login"} className={`underline`}>
                  {t("Sign in from here")}
                </Link>
              </p>
            </div>
          </div>
          <div className={`mt-4 mb-8 flex w-[90%] mx-auto`}>
            <button
              type="submit"
              // disabled={!isDirty || !isValid}
              className={`py-1 px-2  bg-[#5776a5] border-2 border-[#5776a5] text-white text-xl hover:text-[#5776a5] hover:bg-transparent duration-300 rounded-md`}
            >
              {t("submit")}
            </button>
          </div>
          {/* <DevTool control={control} /> set up the dev tool */}
        </form>
      </MainSection>
    </>
  );
};

export default SignUp;
