import apiclient from "./../config/axios";

class Shipping {
  //Aramex Apis
  async CalculateRate(data) {
    return await apiclient.post(`/aramex/calculateRate`, data);
  }
  async CreatePickup(data) {
    return await apiclient.post(`/aramex/createPickUp`, data);
  }
  async CreateShipments(data) {
    return await apiclient.post(`/aramex/createShipment`, data);
  }
}

export const shipping = new Shipping();
