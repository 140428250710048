import API_ROUTES from "../../constants/apiRoute";
import { createFormData } from "../../utils/createFormData";
import privetInstance from "../privetInstance";
import publicInstance from "./publicInstance";
const getProductDetails = async (id) => {
  const res = await publicInstance.get(API_ROUTES.PRODUCT.GET_DETAILS(id));
  return res.data;
};
const editProduct = async (payload) => {
  const { _id, ...rest } = payload;
  const data = createFormData(rest);
  const res = await privetInstance.put(API_ROUTES.PRODUCT.EDIT(_id), data);
  return res.data;
};
export { getProductDetails, editProduct };
