import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { deletePieceOfNews, editPieceOfNews, getPieceOfNews } from ".";
const useGetPieceOfNewsQuery = (id) =>
  useQuery({
    queryKey: ["get-piece-of-news", id],
    queryFn: () => getPieceOfNews(id),
    enabled: !!id,
    staleTime: 0,
  });
const useDeletePieceOfNewsMutation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["delete-pice-of-news"],
    mutationFn: (id) => deletePieceOfNews(id),
    onSuccess: (data) => {
      navigate(-1);
    },
  });
};
const useEditPieceOfNewsMutation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["edit-piece-of-news"],
    mutationFn: (payload) => editPieceOfNews(payload),
    onSuccess: () => {
      navigate(-1);
    },
  });
};
export {
  useGetPieceOfNewsQuery,
  useDeletePieceOfNewsMutation,
  useEditPieceOfNewsMutation,
};
