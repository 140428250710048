import React from "react";
import { useTranslation } from "react-i18next";
import { useRouteLoaderData } from "react-router-dom";
import { useSelector } from "react-redux";
import MainSection from "../../../components/UI/MainSection";
import DealCard from "../../../components/UI/DealCard";
import { getCurrencySymbol } from "../../../funcs/Currency";
const UserDeals = () => {
  const { userDeals } = useRouteLoaderData("user");
  const { t } = useTranslation();
  const currencySymbol = useSelector((state) =>
    getCurrencySymbol(state.currency.selectedCurrency)
  );
  const currency = useSelector((state) => state.currency.selectedCurrency);
  console.log(userDeals);
  return (
    <MainSection className={"w-full md:w-[80%] mx-auto"}>
      <ul
        className={`grid grid-cols-2 place-items-center md:grid-cols-3 w-full mx-auto [&>*]:mb-4  [&>*]:mr-2`}
      >
        {!!userDeals.length ? (
          userDeals.map((userDeal) => (
            <DealCard
              className={`2xl:!w-[22rem]    rounded-lg `}
              key={userDeal._id}
              imgSrc={userDeal.imgUrl}
              imgAlt={userDeal.name}
              first={t(userDeal.name)}
              second={userDeal.companyName}
              validUntil={userDeal.endDate}
              third={
                <>
                  {t("curr_price")}:{" "}
                  <span className=" text-green-900 font-bold">
                    {userDeal.currentPrice.toLocaleString()} {t(currencySymbol)}
                  </span>
                </>
              }
              fourth={
                <>
                  {t("prev_price")} :{" "}
                  <del className=" text-red-500">
                    {userDeal.prevPrice} {t(currencySymbol)}
                  </del>
                </>
              }
              path={`/deals/${currency}/dealsTypes/any/${currency}/${userDeal._id}`}
            />
          ))
        ) : (
          <p className={`w-full flex justify-center items-center text-lg`}>
            {t("no_deals")}
          </p>
        )}
      </ul>
    </MainSection>
  );
};

export default UserDeals;
