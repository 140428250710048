import React from "react";
import { useLoaderData } from "react-router-dom";
import ProductDetailsPageCard from "../UI/ProductDetailsPageCard/ProductDetailsPageCard";

const ProductDetailsComp = () => {
  const data = useLoaderData();
  return (
    <ProductDetailsPageCard
    data={data}
    />
  );
};

export default ProductDetailsComp;
