import React, { useState } from "react";
import RatingSystem from "../RatingSystem";
import ShareLink from "../ShareLink";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import MainSection from "../MainSection";
import { getCurrencySymbol } from "../../../funcs/Currency";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaWhatsapp } from "react-icons/fa";
import Toast from "../../../utils/Toast";

import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { MdEdit } from "react-icons/md";
import { selectCart, addToCart, clearCart } from "../../../store/cartSlice";
import DeleteServiceDialog from "../../dialogs/delete";
import { useDeleteServiceMutation } from "../../../api/services/queries";
import useOpenEle from "../../../hooks/useOpenEle";
import { selectUser } from "../../../store/userSlice";

const ProductDetailsPageCard = ({
  data,
  // id,
  // title,
  // description,
  // imgSrc,
  // imgAlt,
  // Price,
  // totalRatings,
  // ratingValue,
  // imgs,
  // quantity,
  // owner,
  // ownerType,
  // condition,
  // guarantee,
  // category,
  isService,
  ishidden,
}) => {
  const [heart, setHeart] = useState(false);
  const [
    openDeleteServiceModal,
    handleOpenDeleteServiceModal,
    handleCloseDeleteServiceModal,
  ] = useOpenEle();
  const { ownerId } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const { user } = useSelector(selectUser);
  const { mutate: deleteService } = useDeleteServiceMutation();
  const handleDeleteService = () => {
    deleteService(data._id);
  };
  // Get the existing cart items from local storage or initialize an empty array
  // const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

  const handleHeartState = () => {
    setHeart(!heart);
  };
  console.log(ownerId, "owner");
  const addToCartHandler = () => {
    const user = localStorage.getItem("user");
    if (user) {
      if (data.owner._id === JSON.parse(user).result._id) {
        Toast({ Data: t("add_self_product"), type: "error" });
      } else {
        if (data.quantity <= 0) {
          window.alert(
            t("empty_stock", {
              product: data.name,
            })
          );
        } else {
          if (cart.length === 0) {
            dispatch(addToCart(data));

            Toast({
              Data: t("add_to_cart_successfully", {
                product: data.name,
              }),
              type: "success",
            });
          } else {
            if (cart[0].owner._id !== data.owner._id) {
              const iswWantToClearCart = window.confirm(
                "You Can't Add Item from Another Owner,would you like to clear cart"
              );
              if (iswWantToClearCart) {
                dispatch(clearCart({}));
                dispatch(addToCart(data));
                Toast({
                  Data: t("add_to_cart_successfully", {
                    product: data.name,
                  }),
                  type: "success",
                });
              }
            } else {
              dispatch(addToCart(data));

              Toast({
                Data: t("add_to_cart_successfully", {
                  product: data.name,
                }),
                type: "success",
              });
            }
          }
        }
      }
    } else {
      const isLogin = window.confirm(t("go_to_login"));
      if (isLogin) {
        navigate("/login");
      }
    }
  };
  const currencySymbol = useSelector((state) =>
    getCurrencySymbol(state.currency.selectedCurrency)
  );
  const handleEditService = () => {
    navigate(`/service/${data._id}/edit`);
  };
  return (
    <MainSection className={`!mt-52 md:!mt-24 w-[90%] md:w-[70%] mx-auto`}>
      <div className={`flex flex-col`}>
        <div
          className={`flex  justify-center items-center flex-col lg:flex-row`}
        >
          <div
            className={` mx-auto p-1 w-[220px] h-[220px] sm:w-[400px] md:w-[500px] lg:w-[600px] sm:h-[400px] md:h-[500px] lg:h-[600px] bg-[#5776a5] bg-opacity-20 rounded-lg self-start`}
          >
            <img
              crossorigin="anonymous"
              src={data.imageUrl}
              alt={data.name}
              className={`w-full h-full rounded-sm`}
            />
          </div>
          <div
            className={`flex flex-col p-2 w-full text-center md:text-left lg:w-[50%] h-1/2`}
          >
            <div className={`flex flex-col`}>
              <p className={` text-lg md:text-3xl font-bold mb-4 text-center`}>
                {data.name ?? data?.title}
              </p>
              <p
                className={`text-base md:text-xl font-medium mb-4 h-[50%] overflow-hidden`}
              >
                {data.description}
              </p>
            </div>
            <div>
              <h2 className={`text-2xl mb-4 text-[#5776a5]`}>
                {data.price && data.price.toLocaleString()}{" "}
                {data.price && t(currencySymbol)}
              </h2>
            </div>
            <div>
              <div className={`mb-4 flex flex-col items-center justify-center`}>
                <RatingSystem value={data.averageRating} />
                <span className={`ml-2 text-gray-600`}>
                  {data.totalRatings}
                  {t("Reviews")}
                </span>

                {/* {!isService && (
                  <div className={`block md:hidden`}>
                    <Link
                      to="suggestPrice"
                      className={`block px-4 py-2 mt-2 bg-[#5776a5] rounded-2xl text-sm md:text-base text-white font-medium border-2 duration-300 hover:text-[#5776a5] hover:bg-transparent hover:border-[#5776a5]`}
                    >
                      {t("suggestAPrice")}
                    </Link>
                  </div>
                )} */}
              </div>
              <div className={`flex justify-between items-center `}>
                {ownerId && ownerId === data.owner._id && (
                  <Link to={`/product/${data._id}/edit`}>
                    <MdEdit className="text-2xl fill-primary" />
                  </Link>
                )}
                {user._id === data.owner._id && (
                  <>
                    <IconButton
                      color="error"
                      onClick={handleOpenDeleteServiceModal}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton color="primary" onClick={handleEditService}>
                      <EditIcon />
                    </IconButton>
                  </>
                )}

                <ShareLink />

                <div>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${data.whatsAppNumber}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaWhatsapp className=" text-3xl text-green-500" />
                  </a>
                </div>

                {/* <FaCopy
                  className="hover:cursor-pointer text-3xl"
                  onClick={async () =>
                    await navigator.clipboard.writeText(data.whatsAppNumber)
                  }
                  title={t("copy number")}
                /> */}

                {!heart ? (
                  <AiOutlineHeart
                    className={`text-3xl text-red-500 cursor-pointer`}
                    onClick={handleHeartState}
                  />
                ) : (
                  <AiFillHeart
                    className={`text-3xl text-red-500 cursor-pointer`}
                    onClick={handleHeartState}
                  />
                )}
                {!isService && (
                  <button
                    onClick={addToCartHandler}
                    className={`bg-[#5776a5] ${
                      ishidden && "hidden"
                    } text-lg text-white px-1 rounded-lg border-2 border-[#5776a5] hover:text-[#5776a5]  hover:bg-transparent duration-300`}
                  >
                    {t("add_to_cart")}
                  </button>
                )}
                {/* {!isService && (
                  <div className={`hidden md:block`}>
                    <Link
                      to="suggestPrice"
                      className={`block px-2 py-1 bg-[#5776a5] rounded-2xl text-sm md:text-base text-white font-medium border-2 duration-300 hover:text-[#5776a5] hover:bg-transparent hover:border-[#5776a5]`}
                    >
                      {t("suggestAPrice")}
                    </Link>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>

        {data?.serviceImageList ? (
          <div className={`mt-8`}>
            <div className=" Gallery ">
              <LightGallery
                enableSwipe
                height="200px"
                width="200px"
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
              >
                {data?.serviceImageList?.map((ele, i) => (
                  <a className="gallery-item block " href={ele}>
                    <img
                      className="h-[100px] w-[100px] md:h-60 md:w-60 lg:h-65 lg:w-65"
                      alt="img1"
                      src={ele}
                    />
                  </a>
                ))}
              </LightGallery>
            </div>
          </div>
        ) : data?.images ? (
          <div className={`mt-8`}>
            <div className=" Gallery ">
              <LightGallery
                enableSwipe
                height="200px"
                width="200px"
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
              >
                {data?.images?.map((ele, i) => (
                  <a className="gallery-item block " href={ele}>
                    <img
                      className="h-[100px] w-[100px] md:h-60 md:w-60 lg:h-65 lg:w-65"
                      alt="img1"
                      src={ele}
                    />
                  </a>
                ))}
              </LightGallery>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* <div className={`mt-8`}>
          {(data?.serviceImageList?.length > 0 || data?.images?.length > 0) && (
            <ul
              className={`grid grid-cols-2 gap-3 lg:grid-cols-3 xl:grid-cols-4 p-2 bg-[#5776a5] bg-opacity-20 rounded-xl`}
            >
              {isService
                ? data?.serviceImageList?.map((ele, i) => (
                    <li
                      key={i}
                      className={`bg-white rounded-lg p-1  h-30 w-30 md:h-60 md:w-60 lg:h-65 lg:w-65 flex flex-col justify-between`}
                    >
                      <div
                        className={` overflow-hidden w-25 h-25 md:h-50 md:w-50 lg:h-55 lg:w-55 h-[80%] w-full flex items-center justify-center`}
                      >
                        <img
                          crossorigin="anonymous"
                          src={ele}
                          alt={i}
                          className={` h-full w-full rounded-xl object-cover`}
                        />
                      </div>
                      <div className={`py-1`}>
                        <ShareLink /> */}
        {/* Replace ShareLink with your actual component */}
        {/* <ShareLink /> */}
        {/* </div>
                    </li>
                  ))
                : data?.images?.map((ele, i) => (
                    <li
                      key={i}
                      className={`h-40 md:w-36 bg-white rounded-lg  `}
                    >
                      <div
                        className={` w-full h-full overflow-hidden flex items-center justify-center`}
                      >
                        <img
                          crossorigin="anonymous"
                          src={ele}
                          alt={i}
                          className={` h-full  w-full   rounded-xl object-contain`}
                        />
                      </div>
                      <div className={`py-2`}> */}
        <ShareLink />
        {/* Replace ShareLink with your actual component */}
        {/* <ShareLink /> */}
        {/* </div>
                    </li>
                  ))}
            </ul>
          )}
        </div> */}
        {!isService && (
          <div className={`mb-8`}>
            <ul className={`p-2 bg-[#5776a5] bg-opacity-20 rounded-xl mt-8`}>
              <li
                className={`p-2 border-b border-gray-700 text-lg font-medium`}
              >
                {t("category")} :{" "}
                <span className={`text-base text-gray-700`}>
                  {data?.category?.name}
                </span>
              </li>
              <li
                className={`p-2 border-b border-gray-700 text-lg font-medium`}
              >
                {t("owner")} :{" "}
                <span className={`text-base text-gray-700`}>
                  {data.owner?.username}
                </span>
              </li>

              <li
                className={`p-2 border-b border-gray-700 text-lg font-medium`}
              >
                {t("quantity")} :{" "}
                <span className={`text-base text-gray-700`}>
                  {data.quantity}
                </span>
              </li>
              <li
                className={`p-2 border-b border-gray-700 text-lg font-medium`}
              >
                {t("weight")} :{" "}
                <span className={`text-base text-gray-700`}>{data.weight}</span>
              </li>
              <li
                className={`p-2 border-b border-gray-700 text-lg font-medium`}
              >
                {t("condition")} :{" "}
                <span className={`text-base text-gray-700`}>
                  {data.condition}
                </span>
              </li>
              <li className={`p-2 text-lg font-medium`}>
                {t("guarantee")} :{" "}
                <span className={`text-base text-gray-700`}>
                  {data.guarantee ? data.guarantee : "Not Applied"}
                </span>
              </li>
            </ul>
          </div>
        )}
      </div>
      <DeleteServiceDialog
        open={openDeleteServiceModal}
        handleDelete={handleDeleteService}
        name={data.title}
        onClose={handleCloseDeleteServiceModal}
      />
    </MainSection>
  );
};

export default ProductDetailsPageCard;
