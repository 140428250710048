import React, { useState } from "react";
import { Input, InputLabel, Box, Button } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useTranslation } from "react-i18next";
const ImageInput = ({ setValue, name, oldImgSrc }) => {
  const [imgSrc, setImgSrc] = useState("");
  const { t } = useTranslation();
  const handleUploadFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      const imgFile = event.target.files[0];
      setValue(name, imgFile);
      setImgSrc(URL.createObjectURL(imgFile));
    }
  };
  return (
    <Box>
      <InputLabel>
        <Button
          variant="contained"
          startIcon={<AddAPhotoIcon />}
          component={"span"}
        >
          {t("upload_img")}
        </Button>
        <Input
          type="file"
          accept={"image/*"}
          sx={{ display: "none" }}
          onChange={handleUploadFile}
        />
      </InputLabel>
      <Box
        crossorigin="anonymous"
        component={"img"}
        sx={{ width: "full", border: 1, mt: 1, aspectRatio: "16/9" }}
        src={!!imgSrc ? imgSrc : oldImgSrc}
      />
    </Box>
  );
};

export default ImageInput;
