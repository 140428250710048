import React from "react";

const Visa = () => {
  return (
    <svg
      width="41"
      height="26"
      viewBox="0 0 41 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7895_31573)">
        <path
          d="M15.7922 6.22286L10.7022 18.7771H7.38051L4.84742 8.77332C4.70404 8.15428 4.56066 7.93143 4.10662 7.65904C3.34191 7.23809 2.09926 6.8419 1 6.59428L1.07169 6.22286H6.42463C7.11764 6.22286 7.71507 6.69334 7.88235 7.51047L9.22063 14.7657L12.4945 6.22286H15.7922ZM28.8401 14.6914C28.864 11.3733 24.4192 11.1753 24.443 9.71428C24.443 9.26856 24.8732 8.77332 25.7812 8.67428C26.2353 8.62475 27.4779 8.57523 28.8879 9.24381L29.4375 6.54477C28.6728 6.27238 27.693 6 26.4981 6C23.3915 6 21.193 7.70856 21.1691 10.16C21.1452 11.9676 22.7463 12.9828 23.9173 13.5771C25.1361 14.1961 25.5661 14.5924 25.5423 15.1371C25.5423 15.979 24.5625 16.3504 23.6544 16.3752C22.0772 16.3999 21.1452 15.9295 20.4283 15.5828L19.8548 18.3314C20.5956 18.6781 21.9577 18.9752 23.3677 19C26.6654 18.9752 28.8401 17.2913 28.8401 14.6914ZM37.0846 18.7771H40L37.4431 6.22286H34.7665C34.1691 6.22286 33.6434 6.59428 33.4283 7.13905L28.6967 18.7771H31.9945L32.6397 16.8952H36.6783L37.0846 18.7771ZM33.5478 14.3447L35.1967 9.63999L36.1526 14.3447H33.5478ZM20.3088 6.22286L17.704 18.7771H14.5497L17.1544 6.22286H20.3088Z"
          fill="#1334CB"
        />
      </g>
      <defs>
        <clipPath id="clip0_7895_31573">
          <rect width="41" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Visa;
