
import { toast } from "react-toastify";

export default function Toast({ Data, type }) {

  toast(Data, {
    type,
    position:
      localStorage?.getItem("i18nextLng") === "ar" ? "top-right" : "top-left",
  });
}
