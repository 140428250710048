import React from "react";

const AppleIcon = () => {
  return (
    <svg
      width="168"
      height="52"
      viewBox="0 0 84 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M82.5786 22.5943C82.5786 23.9229 81.5277 25 80.2313 25H3.3534C2.72981 25.0017 2.13119 24.7489 1.68967 24.2976C1.24814 23.8463 0.999998 23.2334 1 22.5943V3.40565C1.00338 2.07603 2.05604 0.999995 3.3534 1H80.2497C81.5388 1.01035 82.5786 2.08437 82.5786 3.40565V22.5943Z"
        fill="black"
        stroke="#979797"
      />
      <path
        d="M17.7181 12.8867C17.7355 11.6372 18.3954 10.4834 19.4673 9.82852C18.7866 8.86334 17.6874 8.27334 16.5013 8.2365C15.2338 8.10435 14.0487 8.97902 13.4086 8.97902C12.7685 8.97902 11.7799 8.24908 10.7278 8.27425C9.34148 8.31966 8.08082 9.08407 7.40695 10.2879C5.96832 12.7546 7.03937 16.3854 8.42095 18.3802C9.11175 19.3555 9.91662 20.4504 10.956 20.4127C11.9953 20.3749 12.3692 19.7834 13.6114 19.7834C14.8536 19.7834 15.2021 20.4127 16.2795 20.4127C17.3569 20.4127 18.0794 19.431 18.7448 18.4431C19.2395 17.7476 19.6202 16.9788 19.8729 16.1652C18.5531 15.6055 17.7027 14.3116 17.7181 12.8867Z"
        fill="white"
      />
      <path
        d="M15.6901 6.92763C16.2936 6.2097 16.5915 5.28643 16.5203 4.35396C15.5997 4.44905 14.749 4.88489 14.1374 5.57472C13.534 6.25637 13.2284 7.14839 13.2882 8.05401C14.2204 8.06381 15.1056 7.64868 15.6901 6.92763Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1791 20.2617H32.6241L29.8926 11.8925H28.2132L25.5007 20.2617H26.9013L27.6555 17.8013H30.3933L31.1791 20.2617ZM29.449 14.6109L30.1588 16.8007H27.8899L28.6441 14.6046C28.8258 13.9082 28.9589 13.3817 29.0434 13.0252C29.2335 13.8432 29.3687 14.3718 29.449 14.6109Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.7652 19.6072C39.3518 18.934 39.6518 18.0608 39.6017 17.172C39.635 16.3647 39.3715 15.573 38.8603 14.9444C38.3699 14.3687 37.6342 14.0572 36.8763 14.1044C36.1184 14.1515 35.4276 14.5516 35.0134 15.1835L34.9373 14.2271H33.7458C33.7796 14.9067 33.7965 15.5695 33.7965 16.2155V22.7221H35.1781V19.5443C35.548 20.1254 36.2118 20.455 36.9019 20.4001C37.6091 20.4149 38.2883 20.1259 38.7652 19.6072ZM37.8272 15.7687C38.1067 16.2014 38.246 16.7087 38.2265 17.2223C38.2524 17.7659 38.1037 18.3035 37.8019 18.7577C37.5315 19.1421 37.0822 19.3629 36.6104 19.3429C36.2071 19.3566 35.8189 19.1904 35.552 18.8899C35.2793 18.5832 35.1343 18.185 35.1464 17.7761V16.7693C35.1512 16.6309 35.1725 16.4936 35.2098 16.3602C35.2836 16.0213 35.4714 15.7173 35.7422 15.4982C35.996 15.2865 36.3169 15.1706 36.6484 15.1709C37.1182 15.1582 37.5622 15.3834 37.8272 15.7687Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.7618 19.6072C46.3484 18.934 46.6484 18.0608 46.5984 17.172C46.6311 16.3648 46.3676 15.5733 45.8569 14.9444C45.3665 14.3687 44.6308 14.0572 43.8729 14.1044C43.1151 14.1515 42.4243 14.5516 42.01 15.1835L41.934 14.2271H40.7425C40.7763 14.9067 40.7932 15.5695 40.7932 16.2155V22.7221H42.1748V19.5443C42.5446 20.1254 43.2085 20.455 43.8986 20.4001C44.6059 20.4158 45.2854 20.1266 45.7618 19.6072ZM44.8239 15.7687C45.1033 16.2014 45.2427 16.7087 45.2231 17.2223C45.2474 17.756 45.1034 18.284 44.8112 18.7326C44.5409 19.117 44.0915 19.3377 43.6197 19.3178C43.2076 19.3493 42.8035 19.1917 42.5233 18.8899C42.2512 18.5829 42.1062 18.1849 42.1177 17.7761V16.7693C42.1229 16.631 42.1442 16.4937 42.1811 16.3602C42.2545 16.0211 42.4423 15.7169 42.7135 15.4982C42.9673 15.2865 43.2883 15.1706 43.6197 15.1709C44.0984 15.1494 44.554 15.3756 44.8239 15.7687Z"
        fill="white"
      />
      <path
        d="M54.3936 17.9145C54.4152 18.5747 54.1414 19.2106 53.6457 19.6513C53.0009 20.1734 52.1822 20.4361 51.3516 20.3875C50.5876 20.4233 49.8291 20.2421 49.1651 19.8652L49.4756 18.7451C50.0636 19.1001 50.7395 19.2854 51.4276 19.28C51.8523 19.3066 52.2733 19.1866 52.6191 18.9402C52.8978 18.7197 53.0552 18.3814 53.0437 18.0278C53.0512 17.707 52.9251 17.3974 52.6951 17.172C52.354 16.885 51.9571 16.6708 51.529 16.5427C50.0587 15.9806 49.3235 15.173 49.3235 14.1201C49.3009 13.4538 49.5941 12.8156 50.1157 12.3959C50.7045 11.9437 51.4389 11.72 52.1818 11.7666C52.8384 11.7454 53.4905 11.8814 54.083 12.1631L53.7598 13.258C53.2626 12.9961 52.7065 12.864 52.1437 12.8741C51.7417 12.8491 51.3445 12.9724 51.0283 13.2202C50.8115 13.4117 50.6889 13.6872 50.6924 13.9754C50.6869 14.2927 50.8296 14.5948 51.079 14.7934C51.4527 15.0665 51.8677 15.2789 52.3085 15.4226C52.9151 15.6349 53.4639 15.9839 53.9119 16.4421C54.2531 16.8554 54.425 17.381 54.3936 17.9145Z"
        fill="white"
      />
      <path
        d="M58.8869 15.2339H57.3975V18.1662C57.3975 18.9129 57.6595 19.2863 58.1834 19.2863C58.386 19.2906 58.5883 19.2695 58.7855 19.2234L58.8298 20.2365C58.4907 20.3474 58.1347 20.3985 57.7778 20.3875C57.3043 20.4178 56.8411 20.2408 56.5103 19.903C56.1644 19.4327 56.0043 18.8528 56.0603 18.2732V15.2339H55.1731V14.227H56.0603V13.1195L57.3849 12.7231V14.227H58.8742L58.8869 15.2339Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.8062 19.4814C65.3529 18.8472 65.6382 18.0313 65.6047 17.1972C65.6423 16.3896 65.3654 15.5987 64.8315 14.9885C64.2782 14.3952 63.4904 14.0731 62.6767 14.1075C61.8451 14.0691 61.0385 14.3959 60.4713 15.001C59.9118 15.634 59.619 16.4564 59.6537 17.2978C59.6246 18.1112 59.9077 18.9052 60.4459 19.5191C60.9889 20.112 61.769 20.4348 62.5753 20.4001C63.4191 20.4361 64.2358 20.0998 64.8062 19.4814ZM63.8429 15.8065C64.0945 16.2424 64.2195 16.739 64.2041 17.2412C64.2101 17.7512 64.0717 18.2527 63.8048 18.6885C63.565 19.1365 63.0958 19.4165 62.5849 19.4165C62.0739 19.4165 61.6047 19.1365 61.3649 18.6885C61.1152 18.2564 60.9902 17.7644 61.0036 17.2664C60.9862 16.756 61.1112 16.2507 61.3649 15.8065C61.6091 15.3404 62.1039 15.0579 62.6324 15.0828C63.1418 15.0754 63.6111 15.356 63.8429 15.8065Z"
        fill="white"
      />
      <path
        d="M69.9966 15.4038C69.8565 15.3784 69.7144 15.3658 69.572 15.366C69.1362 15.3439 68.7196 15.546 68.4693 15.9009C68.2329 16.2542 68.1153 16.6727 68.1334 17.0965V20.2428H66.7835V16.1085C66.7835 15.4122 66.7666 14.7829 66.7328 14.2208H67.9116L67.9623 15.3786H68.0003C68.1287 15.0015 68.3642 14.6693 68.6784 14.4221C68.9605 14.2137 69.3028 14.1012 69.6544 14.1012C69.7662 14.0926 69.8785 14.0926 69.9903 14.1012L69.9966 15.4038Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.9919 17.5873C76.03 17.3798 76.047 17.169 76.0426 16.958C76.0596 16.2931 75.8716 15.6389 75.5039 15.0829C75.0555 14.4382 74.3019 14.0712 73.514 14.1138C72.6892 14.0818 71.8985 14.4416 71.3845 15.0829C70.8692 15.727 70.6019 16.5323 70.6304 17.3545C70.5894 18.17 70.8793 18.9678 71.4352 19.5695C72.0279 20.1411 72.8347 20.4401 73.6597 20.3938C74.3585 20.411 75.0532 20.2846 75.7004 20.0225L75.4913 19.0912C74.9668 19.2867 74.41 19.3827 73.8498 19.3744C73.3719 19.395 72.9022 19.2455 72.5253 18.9528C72.1343 18.6072 71.9183 18.1068 71.9359 17.5873H75.9919ZM74.5153 15.58C74.7032 15.8907 74.7937 16.25 74.7751 16.612H71.9613C71.9918 16.2332 72.1302 15.8709 72.3605 15.5674C72.603 15.2154 73.0086 15.0094 73.4379 15.0199C73.8738 14.9941 74.2884 15.2096 74.5153 15.58Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0434 8.41269C29.5015 7.97787 29.7349 7.35925 29.6771 6.73257C29.7205 6.17093 29.509 5.61941 29.1004 5.22864C28.6274 4.88205 28.0453 4.71467 27.459 4.75669C27.0749 4.75549 26.6913 4.78495 26.3119 4.84479V8.87835C26.6338 8.91419 26.9576 8.93099 27.2815 8.92869C27.9118 8.96916 28.5361 8.78632 29.0434 8.41269ZM28.5934 5.62507C28.8678 5.92713 29.0073 6.32668 28.98 6.73257H29.0053C29.0465 7.19028 28.8977 7.64483 28.5934 7.99109C28.2618 8.27628 27.8252 8.41088 27.3893 8.36235C27.2394 8.37192 27.0891 8.37192 26.9393 8.36235V5.28527C27.1149 5.25386 27.2932 5.23911 27.4717 5.24122C27.8826 5.21238 28.2876 5.35098 28.5934 5.62507Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.0297 8.4819C33.2977 8.17079 33.438 7.77093 33.4226 7.36182C33.438 6.96332 33.2997 6.574 33.036 6.2732C32.7641 5.98187 32.3774 5.82323 31.9777 5.83901C31.5688 5.8208 31.1726 5.98186 30.8939 6.27949C30.6204 6.5903 30.4775 6.99349 30.4947 7.40587C30.4807 7.80716 30.6212 8.19867 30.8876 8.50078C31.1541 8.79228 31.5371 8.95133 31.9333 8.93497C32.3482 8.952 32.7495 8.78617 33.0297 8.4819ZM32.5544 6.73255C32.6649 6.92455 32.7259 7.14073 32.7318 7.36181V7.40586C32.7468 7.66524 32.6851 7.92331 32.5544 8.14838C32.4457 8.37531 32.2115 8.51632 31.9586 8.50706C31.6961 8.5229 31.4485 8.38518 31.3249 8.15467C31.2015 7.94045 31.1401 7.69657 31.1474 7.4499C31.1388 7.1991 31.2002 6.95082 31.3249 6.73255C31.4473 6.50087 31.6958 6.36269 31.9586 6.38016C32.2101 6.36977 32.4439 6.50807 32.5544 6.73255Z"
        fill="white"
      />
      <path
        d="M38.3976 5.92711L37.466 8.89092H36.8322L36.4456 7.6324C36.349 7.31787 36.2686 6.99862 36.2048 6.67593C36.1472 6.99997 36.0667 7.31958 35.964 7.6324L35.552 8.89092H34.9183L34.069 5.92711H34.7471L35.0894 7.36182C35.1697 7.69323 35.2373 8.00996 35.2922 8.312C35.3429 8.0645 35.4253 7.74987 35.5393 7.36811L35.964 5.95228H36.5026L36.9083 7.33665C37.0054 7.67645 37.0857 8.00157 37.1491 8.312C37.1991 7.98353 37.2668 7.65795 37.3519 7.33665L37.7131 5.95228H38.3469L38.3976 5.92711Z"
        fill="white"
      />
      <path
        d="M41.8452 8.89092H41.2115V7.19192C41.2115 6.66753 41.0108 6.40534 40.6094 6.40534C40.4252 6.40375 40.2494 6.48185 40.1278 6.61929C40.0062 6.76139 39.9409 6.94257 39.944 7.12899V8.89092H39.3102V6.7766C39.3102 6.51651 39.3102 6.23334 39.3102 5.9271H39.8172V6.39276C39.8974 6.24532 40.0155 6.12158 40.1594 6.03408C40.3398 5.92187 40.5487 5.86294 40.7615 5.86418C41.0214 5.85592 41.2733 5.9537 41.4586 6.13476C41.7049 6.4007 41.8288 6.75623 41.8009 7.1164L41.8452 8.89092Z"
        fill="white"
      />
      <path
        d="M43.6768 8.89092H42.986V4.5679H43.6197L43.6768 8.89092Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.1941 8.4819C47.4621 8.17079 47.6024 7.77093 47.587 7.36182C47.5995 6.97357 47.4662 6.59458 47.2131 6.29837C46.9412 6.00704 46.5545 5.8484 46.1547 5.86418C45.7499 5.83949 45.3542 5.99111 45.071 6.27949C44.7952 6.58934 44.65 6.99263 44.6654 7.40587C44.6497 7.80632 44.7879 8.19772 45.052 8.50078C45.318 8.79302 45.7014 8.95221 46.0977 8.93497C46.5126 8.952 46.9139 8.78617 47.1941 8.4819ZM46.7188 6.73256C46.8293 6.92457 46.8903 7.14074 46.8963 7.36182V7.40587C46.9151 7.65173 46.8646 7.89795 46.7505 8.11693C46.6418 8.34387 46.4076 8.48487 46.1548 8.47561C45.8922 8.49145 45.6446 8.35374 45.521 8.12323C45.4032 7.91822 45.342 7.68595 45.3436 7.44992C45.3349 7.19912 45.3963 6.95083 45.521 6.73256C45.6324 6.50591 45.8694 6.36722 46.1231 6.38018C46.3745 6.36979 46.6083 6.50808 46.7188 6.73256Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.2045 8.89093H50.8002C50.7685 8.65107 50.7558 8.40911 50.7622 8.16728V7.07236C50.7622 6.25433 50.3713 5.84531 49.5897 5.84531C49.2298 5.83302 48.8737 5.92249 48.563 6.1033L48.6898 6.55637C48.9163 6.41116 49.1806 6.33463 49.4503 6.33613C49.8728 6.33613 50.084 6.5312 50.084 6.92134V6.94651C49.6432 6.92218 49.2043 7.02023 48.8165 7.22968C48.5215 7.40153 48.346 7.72097 48.3602 8.0603C48.3474 8.29323 48.4322 8.52105 48.5947 8.68956C48.7735 8.8726 49.0226 8.97105 49.2792 8.96014C49.6214 8.97828 49.9499 8.82464 50.1538 8.55113L50.2045 8.89093ZM50.1094 7.41216V7.86523C50.1116 8.05044 50.0215 8.22476 49.8686 8.33088C49.7522 8.41979 49.6098 8.46842 49.463 8.46932C49.3446 8.47405 49.2289 8.43351 49.1397 8.35605C49.0493 8.26415 49.0032 8.13805 49.013 8.00996C49.013 7.61143 49.3785 7.41216 50.1094 7.41216Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.988 8.89092H54.5774C54.5605 8.6476 54.5562 8.38332 54.5647 8.09805V4.5679H53.9309V6.26061C53.7488 5.97942 53.423 5.82373 53.0881 5.85789C52.7327 5.85236 52.3934 6.00466 52.1628 6.2732C51.8941 6.60084 51.7565 7.01528 51.7762 7.43733C51.7577 7.83284 51.8837 8.22168 52.1311 8.53224C52.3484 8.80442 52.6812 8.96033 53.031 8.95385C53.4306 8.98116 53.8078 8.76789 53.988 8.41268V8.89092ZM53.8866 7.13529V7.63241V7.6387C53.8954 7.83152 53.8347 8.02115 53.7155 8.17357C53.5905 8.33509 53.3945 8.42653 53.1895 8.41898C52.9571 8.42507 52.7376 8.3137 52.6064 8.12323C52.4619 7.91243 52.3907 7.66054 52.4036 7.40587C52.3939 7.13993 52.4693 6.87776 52.6191 6.65705C52.7503 6.46658 52.9698 6.35521 53.2021 6.3613C53.3657 6.35692 53.5247 6.41554 53.6458 6.52491C53.7673 6.63013 53.8517 6.77128 53.8866 6.92764C53.8941 6.99666 53.8941 7.06627 53.8866 7.13529Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.8629 8.4819C60.1309 8.17079 60.2712 7.77092 60.2558 7.36181C60.27 6.97439 60.1391 6.59549 59.8882 6.29836C59.6168 6.00632 59.2298 5.84754 58.8299 5.86417C58.4228 5.83762 58.0245 5.98938 57.7398 6.27949C57.464 6.58934 57.3188 6.99263 57.3342 7.40586C57.3185 7.80631 57.4567 8.19772 57.7208 8.50077C57.9873 8.79228 58.3703 8.95132 58.7665 8.93496C59.1816 8.95287 59.5832 8.7869 59.8629 8.4819ZM59.3876 6.73256C59.4981 6.92456 59.5591 7.14074 59.565 7.36182V7.40587C59.5766 7.65617 59.5174 7.90469 59.3939 8.12322C59.2762 8.34321 59.0459 8.48073 58.795 8.48073C58.5441 8.48073 58.3138 8.34321 58.1961 8.12322C58.0783 7.91822 58.0171 7.68595 58.0187 7.44992C58.01 7.19912 58.0714 6.95083 58.1961 6.73256C58.3125 6.51353 58.5424 6.37751 58.7918 6.38017C59.0433 6.36979 59.2771 6.50808 59.3876 6.73256Z"
        fill="white"
      />
      <path
        d="M63.8238 8.89093H63.1901V7.19193C63.1901 6.66754 62.9788 6.40535 62.5563 6.40535C62.371 6.39833 62.1929 6.47742 62.0747 6.6193C61.9526 6.76111 61.8872 6.94248 61.8909 7.129V8.89093H61.2571V6.77661C61.2571 6.51652 61.2571 6.23335 61.2571 5.92711H61.8402V6.39276C61.9209 6.24568 62.0389 6.12205 62.1824 6.03409C62.3719 5.91597 62.5926 5.85681 62.8162 5.86419C63.076 5.85593 63.328 5.95371 63.5133 6.13477C63.7595 6.40071 63.8835 6.75624 63.8555 7.11641L63.8238 8.89093Z"
        fill="white"
      />
      <path
        d="M68.2981 6.41793H67.5693V7.85894C67.5693 8.22391 67.7024 8.40639 67.9559 8.40639C68.0548 8.41897 68.1549 8.41897 68.2538 8.40639V8.9098C68.0884 8.9646 67.9147 8.99016 67.7404 8.98531C67.5038 9.00392 67.2712 8.91616 67.1067 8.74619C66.9365 8.51585 66.8575 8.23142 66.8849 7.94703V6.41793H66.4476V5.92711H66.8849V5.38594L67.5186 5.19087V5.92711H68.2474V6.41793H68.2981Z"
        fill="white"
      />
      <path
        d="M71.8091 8.89092H71.1754V7.2045C71.1754 6.67173 70.9747 6.40534 70.5733 6.40534C70.2805 6.39459 70.0185 6.58453 69.9396 6.8647C69.928 6.94399 69.928 7.02453 69.9396 7.10382V8.89092H69.3058V4.5679H69.9396V6.355C70.1274 6.04193 70.4728 5.85601 70.8395 5.87047C71.0836 5.85777 71.3228 5.94147 71.5049 6.1033C71.747 6.37639 71.868 6.73476 71.8408 7.09753L71.8091 8.89092Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.4406 7.56949C75.4502 7.46903 75.4502 7.3679 75.4406 7.26744C75.4398 6.93791 75.3357 6.61679 75.1427 6.34872C74.9239 6.03088 74.5539 5.84955 74.1667 5.87049C73.7611 5.85446 73.3723 6.03226 73.121 6.34872C72.8693 6.66494 72.739 7.05971 72.7534 7.46251C72.7323 7.86299 72.8739 8.25513 73.1464 8.55113C73.4369 8.83106 73.8323 8.97715 74.2364 8.95386C74.5792 8.96089 74.9198 8.89881 75.2378 8.77137L75.1364 8.31201C74.8791 8.40766 74.6061 8.45462 74.3315 8.45045C74.1024 8.45626 73.8784 8.38286 73.6977 8.2428C73.5045 8.07259 73.3977 7.82581 73.4062 7.56949H75.4406ZM74.6801 6.58784C74.7731 6.74115 74.8173 6.91883 74.8068 7.09754L73.4316 7.09125C73.4482 6.90655 73.5162 6.73009 73.628 6.58155C73.7475 6.41052 73.9444 6.30923 74.154 6.31096C74.3672 6.30072 74.569 6.40692 74.6801 6.58784Z"
        fill="white"
      />
    </svg>
  );
};

export default AppleIcon;
