import React, { useEffect } from "react";
import axios from "../../config/axios";
import { useMutation } from "@tanstack/react-query";
import { order } from "../../services/order";
import { useSelector } from "react-redux";

export default function PaymentProces() {


  return <div>PaymentProces</div>;
}
