import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Grid,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useEditProductMutation,
  useGetProductDetails,
} from "../../../api/product/queries";
import Loading from "../../../components/Loading/Loading";
import ImageInput from "../../../components/UI/ImageInput";
import VideoInput from "../../../components/UI/videoInput";

const EditProduct = () => {
  const { productId } = useParams();
  const { t } = useTranslation();
  const { data: product, isLoading } = useGetProductDetails(productId);
  const { mutate: editProduct } = useEditProductMutation();
  const { control, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      _id: "",
      name: "",
      price: "",
      description: "",
      quantity: "",
      weight: "",
      guarantee: false,
      madeIn: "",
      image: null,
      video: null,
    },
  });
  useEffect(() => {
    if (product) {
      setValue("_id", product._id);
      setValue("name", product.name);
      setValue("price", product.price);
      setValue("description", product.description);
      setValue("quantity", product.quantity);
      setValue("guarantee", product.guarantee);
      setValue("weight", product.weight);
      setValue("madeIn", product.madeIn);
    }
  }, [product, setValue]);

  const onEditProduct = (values) => {
    editProduct(values);
  };
  if (isLoading) return <Loading />;
  return (
    <Box sx={{ my: { xs: 44, md: 23 }, mx: 2 }}>
      <form onSubmit={handleSubmit(onEditProduct)}>
        <Grid container justifyContent={"center"} gap={4}>
          <Grid item xs={12} md={10} lg={8}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  {...field}
                  label={t("name")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={10} lg={8}>
            {" "}
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  {...field}
                  label={t("price")}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={10} lg={8}>
            {" "}
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  {...field}
                  multiline
                  rows={3}
                  label={t("description")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={10} lg={8}>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  {...field}
                  label={t("quantity")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={10} lg={8}>
            <Controller
              name="weight"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  {...field}
                  label={t("weightInKg")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={10} lg={8}>
            {" "}
            <Controller
              name="madeIn"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  {...field}
                  label={t("madeIn")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={10} lg={8}>
            {" "}
            <Controller
              name="guarantee"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      fullWidth
                      {...field}
                      checked={watch("guarantee")}
                      onchange={field.onChange}
                    />
                  }
                  label={t("guarantee")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={10} lg={4}>
            <ImageInput
              setValue={setValue}
              name={"image"}
              oldImgSrc={product?.imageUrl}
            />
          </Grid>
          <Grid item xs={12} md={10} lg={4}>
            <VideoInput setValue={setValue} name={"video"} />
          </Grid>
          <Grid item xs={12} md={10} lg={8}>
            <Button type="submit" sx={{ m: "auto" }} variant="contained">
              {t("edit_product")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditProduct;
