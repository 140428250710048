import React from "react"
import { useLoaderData } from "react-router-dom"
import SectionsHeader from "../../UI/SectionsHeader"
import MainSection from "../../UI/MainSection"
import ServiceCategoryCard from "../../UI/ServiceCategoryCard"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const ServicesCats = () => {
  const data = useLoaderData()
  console.log(data)
  const { t } = useTranslation()
  const currency = useSelector((state) => state.currency.selectedCurrency)

  const serviceItems = data[18].slice(0, 9).map((service) => (
    <div
      key={service._id}
      className="w-1/2 px-1 md:w-1/3 md:flex md:px-2 mb-4 md:mb-0"
    >
      <ServiceCategoryCard
        className="2xl:!w-[22rem] rounded-lg my-1 mx-auto"
        title={t(service.title)}
        path={`/servicescatslist/${currency}/${service._id}`}
      />
    </div>
  ))

  return (
    <MainSection className="py-2">
      <SectionsHeader
        title={"Services"}
        path={`/${currency}/servicescatslist`}
      />
      <div className="flex p-3 flex-wrap bg-[#bcc8db]">{serviceItems}</div>
    </MainSection>
  )
}

export default ServicesCats
