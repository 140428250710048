import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import MainSection from "./MainSection"
import Card from "./Card"

const CategoryServices = () => {
  const [services, setServices] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { categoryId, currency } = useParams()

  useEffect(() => {
    async function fetchServices() {
      const endpoint = `https://back.netzoon.com/categories/services-by-category?category=${categoryId}&country=${currency}`

      try {
        const response = await fetch(endpoint)

        if (!response.ok) {
          throw new Error("Network response was not ok")
        }

        const data = await response.json()
        setServices(data.services) // Set the fetched data
        setLoading(false) // Set loading to false after the data is fetched
      } catch (error) {
        console.error("Fetch error:", error)
        setError(error)
        setLoading(false)
      }
    }

    fetchServices()
  }, [categoryId, currency])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }
  return (
    <MainSection className={`w-full md:w-[80%] flex  mx-auto my-8`}>
      <ul
        className={`grid grid-cols-2 place-items-center md:grid-cols-3 [&>*]:mb-4 [&>*]:mr-4 mx-auto my-8 py-8`}
      >
        {services.length > 0 ? (
          services.map((ele) => (
            <Card
              key={ele._id}
              path={ele._id}
              className={`!w-36 !h-36 md:!w-[15rem] md:!h-[15rem] 2xl:!w-52 2xl:!h-52`}
              imgSrc={ele.imageUrl}
              imgAlt={ele.title || ele.name}
              title={ele.title || ele.name}
            />
          ))
        ) : (
          <p className={`w-full flex justify-center items-center text-lg`}>
            No Items Found
          </p>
        )}
      </ul>
    </MainSection>
  )
}

export default CategoryServices
export const singleServicesLoader = async ({ params }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL

  const prodServId = params.prodId

  const product = await fetch(
    baseUrl + `/categories/local-company/get-service/${prodServId}`
  )

  const data = await product.json()

  return data
}
