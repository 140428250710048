import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconButton, Box, Typography, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useOpenEle from "../../hooks/useOpenEle";
import { selectUser } from "../../store/userSlice";
import DeleteNewsDialog from "../../components/dialogs/delete";
import {
  useDeletePieceOfNewsMutation,
  useGetPieceOfNewsQuery,
} from "../../api/news/queries";
import moment from "moment";
import Loading from "../../components/Loading/Loading";

const PieceOfNews = () => {
  const [
    openDeletedPieceOfNewsModal,
    handleOpenDeletedPieceOfNewsModal,
    handleCloseDeletedPieceOfNewsModal,
  ] = useOpenEle();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: pieceOfNews, isLoading } = useGetPieceOfNewsQuery(id);
  const { mutate: deletePieceOfNews } = useDeletePieceOfNewsMutation();
  const { user } = useSelector(selectUser);

  const handleDeletePieceOfNews = () => {
    deletePieceOfNews(pieceOfNews._id);
  };

  const handleEditPieceOfNews = () => {
    navigate(`/piece-of-news/${pieceOfNews._id}/edit`);
  };
  if (isLoading) return <Loading />;
  return (
    <Box
      sx={{
        my: { xs: 44, md: 23 },
        mx: 2,
        width: { xs: "100%", md: "80%", lg: "70%" },
        m: "auto",
      }}
    >
      <Box
        component={"img"}
        src={pieceOfNews.imgUrl}
        sx={{ width: "100%", aspectRatio: "16/9", objectFit: "cover" }}
        alt={pieceOfNews.title}
      />
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        my={2}
      >
        {user._id === pieceOfNews.creator._id && (
          <Box>
            <IconButton color="primary" onClick={handleEditPieceOfNews}>
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={handleOpenDeletedPieceOfNewsModal}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
        <Typography>{pieceOfNews.title}</Typography>
        <Typography>{moment(pieceOfNews.createAt).format("L")}</Typography>
      </Stack>
      <Box>
        <Typography>{pieceOfNews.description}</Typography>
      </Box>
      <DeleteNewsDialog
        name={pieceOfNews.title}
        open={openDeletedPieceOfNewsModal}
        onClose={handleCloseDeletedPieceOfNewsModal}
        handleDelete={handleDeletePieceOfNews}
      />
    </Box>
  );
};

export default PieceOfNews;
