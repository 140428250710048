import React from "react";
import aramex from "./../../../assets/public/aramex.png";
export default function Aramex() {
  return (
    <img
      crossorigin="anonymous"
      className="w-20 h-14 object-contain"
      alt="aramex "
      src={aramex}
    />
  );
}
