import React from "react";

const AdvertismentsCard = ({ imgSrc, imgAlt }) => {
  return (
    
      <div className={``}>
        <div className={`w-full`}>
          <img
            crossorigin="anonymous"
            src={imgSrc}
            alt={imgAlt}
            className={`  w-full h-full `}
          />
        </div>
      </div>
    
  );
};

export default AdvertismentsCard;
