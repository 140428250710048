export default function FormatDate(item){
    var inputDate = new Date(item);
    var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return inputDate.toLocaleString("en-US", options);
}

