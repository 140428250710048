export const deliveryTypes =  [ 
    {
    value:"inside",
    title:"inside_country"
},
    {
    value:"outside",
    title:"outside_country"
},
    {
    value:"inside_and_outside",
    title:"inside_and_outside_country"
}
]