import { useDropzone } from "react-dropzone";
import Toast from "./Toast";

export const DropZoneHook = ({
  onDropCallback,
  acceptedTypes,
  maxFiles,
}) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: maxFiles ?? 1,
    onDrop: (accepted) => {
      onDropCallback(accepted);
    },
    multiple: maxFiles ? (maxFiles > 1 ? true : false) : false,
    accept: acceptedTypes,
    onDropRejected: (rejected) => {
      rejected.forEach((file) => {
        Toast({
          Data: `${file.file.name} Rejected Because  ${file.errors[0].message}`,
          type: "error",
        });
      });
    },
  });

  return {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  };
};
