export const Cities = [
  "Abadilah",
  "Abu Dhabi",
  "Abu Hayl",
  "Abu Shagara",
  "Adhan",
  "Ajman City",
  "Akamiyah",
  "Al Ain City",
  "Al Ardiyah",
  "Al Azrah",
  "Al Dharbaniyah",
  "Al Dhayd",
  "Al Duss",
  "Al Fahiya",
  "Al Falaj",
  "Al Fulayyah",
  "Al Hamraniyah",
  "Al Hamriyah",
  "Al Hayl",
  "Al Hayr",
  "Al Jazirah",
  "Al Jazirah Al Hamra",
  "Al Khan",
  "Al Khashfah",
  "Al Khateem",
  "Al Lebsa",
  "Al Madam",
  "Al Mafraq",
  "Al Manak",
  "Al Manamah",
  "Al Maqta",
  "Al Marfa",
  "Al Qazimiya",
  "Al Qouz",
  "Al Raafah",
  "Al Rafaah",
  "Al Rams",
  "Al Ruwaiya",
  "Al Ruways",
  "Al Salam Living City",
  "Al ShaM",
  "Al Tawoon",
  "Al Wasit",
  "Al Zahra",
  "Awanat",
  "Badiyah",
  "Barakah",
  "Beda Zayed",
  "Bu Hasa",
  "Bur Dubai",
  "Bustan",
  "Buteen",
  "Buteena",
  "Dasman",
  "Deira",
  "Dhaid",
  "Diba",
  "Diba Al Baya",
  "Diba Al Hesn",
  "Dubai",
  "Dubai Internet City",
  "Falaj Al Mulla",
  "Fujairah",
  "Furfar",
  "Ganthoot",
  "Gayathi",
  "Ghafia",
  "Gheweifat",
  "Ghubaiba",
  "Ghurfah",
  "Habshan",
  "Hamariya ",
  "Hamim",
  "Hassan Dibba",
  "Hatta",
  "Horl Al Anz",
  "Jabel Dhana",
  "Jarf",
  "Jazzat",
  "Jebal Ali",
  "Jumeirah",
  "Kalba",
  "Karama",
  "Khannur",
  "Kharayan",
  "Kharran",
  "Khatt",
  "Khawr Fakkan",
  "Khawr Kalba",
  "Khawr Khuwayr",
  "Kidfa",
  "Layyah",
  "Liwa Region",
  "Madinat Zayed",
  "Mafraq",
  "Maisaloon",
  "Majjarah",
  "Mansoora",
  "Masafi",
  "Masfut",
  "Mina Sufooh ",
  "Mirdif",
  "Mushrif",
  "Mussafah",
  "Muwafja",
  "Nabbah",
  "Nakheel",
  "Nakheelat",
  "Nasariya",
  "Qasimiya",
  "Qidfa",
  "Qusais",
  "Ramaqiya",
  "Ramlah",
  "Ramtha",
  "Ras Al Khaimah city",
  "Rashideya",
  "Rashidya",
  "Reem Community",
  "Rima",
  "Rolla",
  "Rughaylat",
  "Saabah",
  "Saif",
  "Samnan",
  "Saqamqam",
  "Satwa",
  "Shahba",
  "Sham",
  "Shandaghah",
  "Sharjah",
  "Sherqan",
  "Siji",
  "Sila",
  "Sir Baniyas",
  "Suwayhan",
  "Sweihan",
  "Tarif",
  "Tarif Kalba",
  "Um Al Nar",
  "Umm Al Ashtan",
  "Umm Al Quwain",
  "Umm Kanoor",
  "Wadi Shi",
  "Warsan",
  "Wasit",
  "Yarmook",
];
