import React, { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const MultiItemCarousel = ({
  items,
  count0,
  count1,
  count2,
  count3,
  count4,
  count5,
  count6,
  autoPlayInterval,
  isAutoPlay,
  isCarouselAutoPlayMobile, // New prop for controlling mobile autoplay
}) => {
  const Lang = useSelector((state) => state.lang);

  const [isMobile, setIsMobile] = useState(true);
  const AliceCarouselRef = useRef();
  useEffect(() => {
    // Check the screen width and update the isMobile state accordingly
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    // Add a resize event listener
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };
  return (
    <>
      <AliceCarousel
        items={items}
        autoPlay={true} // Enable autoplay if isAutoPlay is true and it's not a mobile screen or isCarouselAutoPlayMobile is true
        autoPlayInterval={autoPlayInterval ? autoPlayInterval : 1000}
        infinite
        autoPlayDirection={Lang.direction}
        responsive={{
          0: { items: count0 || 3 },
          600: { items: count1 || 3 },
          900: { items: count2 || 3 },
          1024: { items: count3 || 3 },
          1100: { items: count4 || 3 },
          1200: { items: count5 || 5 },
          1400: { items: count6 || 6 },
        }}
      />

      {/* <Swiper
        breakpoints={{
          0: { items: count0 || 3, spaceBetween: 0 },
          600: {
            slidesPerView: count1 || 3,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: count2 || 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: count3 || 3,
            spaceBetween: 20,
          },
          1100: {
            slidesPerView: count4 || 3,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: count5 || 5,
            spaceBetween: 20,
          },
          1400: {
            slidesPerView: count6 || 6,
            spaceBetween: 20,
          },
        }}
        className="mySwiper"
        spaceBetween={50}
        slidesPerView={3}
        modules={[Autoplay, Pagination, Navigation]}
        pagination={pagination}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        dir={Lang.direction}
        // direction={Lang.direction}
      >
        {items?.map((item, index) => (
          <SwiperSlide key={index}>{item}</SwiperSlide>
        ))}
      </Swiper> */}
    </>
  );
};

export default MultiItemCarousel;
