// ServicesList.js
import React from "react"
import { useLoaderData } from "react-router-dom"
import MainSection from "../../components/UI/MainSection"
import ServiceCategoryCard from "../../components/UI/ServiceCategoryCard"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const ServicesCatsList = () => {
  const data = useLoaderData()
  const { t } = useTranslation()

  const currency = useSelector((state) => state.currency.selectedCurrency)
  const serviceItems = data[18].map((service) => (
    <div
      key={service._id}
      className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-4"
    >
      <ServiceCategoryCard
        className="2xl:!w-[22rem] rounded-lg"
        title={t(service.title)}
        path={`/servicescatslist/${currency}/${service._id}`}
      />
    </div>
  ))

  return (
    <MainSection className="py-2">
      <div className="flex my-10 p-5 flex-wrap">{serviceItems}</div>
    </MainSection>
  )
}

export default ServicesCatsList
