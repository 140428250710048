import React from "react";
import { Link, useLoaderData, useParams } from "react-router-dom";
import MainSection from "../../components/UI/MainSection";
import ShareLink from "../../components/UI/ShareLink";
import { CiLocationOn } from "react-icons/ci";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "../../funcs/Currency";
import { useTranslation } from "react-i18next";
import { ReplaceLine } from "../../utils/replaceLine";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
const AdsDetails = () => {
  const data = useLoaderData();
  const params = useParams();

  const currencySymbol = useSelector((state) =>
    getCurrencySymbol(state.currency.selectedCurrency)
  );
  // const callHandler = () => {
  //   setIsCall(results.contactNumber);
  // };
  const [isCall, setIsCall] = useState("call");
  const callHandler = () => {
    setIsCall(data.owner.firstMobile);
  };
  const { t } = useTranslation();
  const adBuyHandler = () => {
    window.localStorage.setItem(
      "adPrice",
      JSON.stringify(data.advertisingPrice)
    );
    window.location.replace(`/advertisements/${params.adId}/paymentgateway`);
  };
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  return (
    <MainSection
      className={`!mt-52 md:!mt-28 flex flex-col mx-auto w-[90%] md:w-[70%]`}
    >
      {data.advertisingType === "company" ? (
        <>
          {/* <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelButtonRef}
              onClose={setOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4  sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="mt-3  sm:ml-4 sm:mt-0 ">
                          <Dialog.Title
                            as="h3"
                            className="text-base  font-semibold leading-6 text-gray-900"
                          >
                            <label htmlFor="suggestedId">
                              {t("add_suggestion_price")}
                            </label>
                          </Dialog.Title>
                          <div className="mt-2">
                            <input
                              type="text"
                              id="suggestedId"
                              className=" w-full border-b-2 block border-[#5776a5] border-solid "
                            />
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-[#5776a5] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#335d9d] sm:ml-3 sm:w-auto"
                          onClick={() => setOpen(false)}
                        >
                          {t("send")}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        >
                          {t("cancel")}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root> */}
          <Link to={`/catagories/${data.owner.userType}/${data.owner._id}`}>
            <div className={`flex items-center mb-4 px-2`}>
              <img
                crossorigin="anonymous"
                src={data.owner.profilePhoto}
                alt={data.owner.username}
                className={`rounded-full w-12 md:w-16 mr-4`}
              />
              <h2 className={`text-lg md:text-2xl font-bold text-[#5776a5]`}>
                {data.owner.username}
              </h2>
            </div>
          </Link>
          <div
            className={` my-2   bg-opacity-20 rounded-lg flex items-center flex-col md:flex-row `}
          >
            <Link
              to={`/catagories/${data.owner.userType}/${data.owner._id}`}
              className={` md:mr-4 w-auto md:w-72`}
            >
              <img
                crossorigin="anonymous"
                src={data.advertisingImage}
                alt={data.advertisingTitle}
                className={`rounded-lg  w-full h-full`}
              />
            </Link>
            <div className={`flex flex-col mb-4 w-full mt-2 md:mt-0`}>
              <div
                className={`flex justify-between items-center mb-2 text-xl text-gray-600`}
              >
                <p className={`flex items-center`}>
                  <CiLocationOn className={`mr-2`} />{" "}
                  <span>{data.advertisingLocation}</span>
                </p>

                <p>
                  {t("num_of_viewers")} : <span>{data.adsViews}</span>
                </p>
              </div>
              <div
                className={`flex flex-row md:flex-row justify-between items-center mb-2 mt-2`}
              >
                <p className={`text-[#67354F] text-3xl font-bold`}>
                  {data.advertisingPrice.toLocaleString()} {t(currencySymbol)}
                </p>
                <div
                  className={`flex items-center justify-between mt-2 md:mt-0
                        `}
                >
                  <ShareLink />
                </div>
              </div>
              <Link to={`/catagories/${data.owner.userType}/${data.owner._id}`}>
                <div className={` text-base md:text-3xl font-semibold mb-4`}>
                  <h2>{data.advertisingTitle}</h2>
                </div>
                <div
                  className={`mb-2 p-2  bg-opacity-20 rounded-lg mt-2 text-gray-700`}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: ReplaceLine(data?.advertisingDescription),
                    }}
                  />
                </div>
              </Link>
              <div
                className={`flex flex-col md:flex-row justify-between items-center mb-2 mt-2`}
              >
                <div
                  className={`flex   flex-col gap-y-2  md:flex-row md:gap-y-0 items-center justify-between mt-2 md:mt-0
                              `}
                >
                  <button
                    onClick={callHandler}
                    className={`p-1 bg-[#5776a5] text-white w-44 rounded-2xl  hover:text-[#5776a5] hover:bg-transparent duration-300 border border-[#5776a5]`}
                  >
                    {t(isCall)}
                  </button>
                  <Link
                    to={"/chats"}
                    className={`p-1 text-center bg-[#5776a5] text-white w-44 rounded-2xl  hover:text-[#5776a5] hover:bg-transparent duration-300 border border-[#5776a5]`}
                  >
                    {t("chat")}
                  </Link>
                  {/* <button
                    onClick={() => setOpen((prev) => !prev)}
                    className={`p-1 bg-[#5776a5] text-white w-44 rounded-2xl  hover:text-[#5776a5] hover:bg-transparent duration-300 border border-[#5776a5]`}
                  >
                    {t("suggestAPrice")}
                  </button> */}
                </div>
              </div>
            </div>
          </div>

          {data?.advertisingImageList?.length ? (
            <div className={`mt-4 mb-4`}>
              <h3 className={`text-3xl mb-2`}>{t("images")} : </h3>
              <ul className={`bg-[#5776a5] bg-opacity-20 p-2 rounded-lg flex`}>
                {data?.advertisingImageList?.map((ele, i) => {
                  return (
                    <li>
                      <img
                        crossorigin="anonymous"
                        src={ele}
                        alt={i}
                        className={`w-32 md:w-40 rounded-md mr-2`}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <div
            className={`p-2 bg-[#5776a5] bg-opacity-20 rounded-lg flex items-center flex-col md:flex-row`}
          >
            <Link to={`/catagories/${data.owner.userType}/${data.owner._id}`}>
              <div className={`p-2 bg-[#5776a5] bg-opacity-20 rounded-lg mr-4`}>
                <img
                  crossorigin="anonymous"
                  src={data.advertisingImage}
                  alt={data.advertisingTitle}
                  className={`rounded-lg w-80`}
                />
              </div>
            </Link>
            <div className={``}>
              <Link to={`/catagories/${data.owner.userType}/${data.owner._id}`}>
                <h2 className={`text-2xl font-bold text-[#5776a5] mb-8`}>
                  {data.advertisingTitle}
                </h2>
              </Link>
              <div className={`pr-4 flex justify-between items-center mb-4`}>
                <span className={`text-xl font-medium`}>
                  {data.advertisingPrice.toLocaleString()} {t(currencySymbol)}
                </span>
                <span>
                  <ShareLink />
                </span>
              </div>
              <p>
                {t("num_of_viewers")} :
                <span className={`ml-2 text-[#5776a5]`}>{data.adsViews}</span>
              </p>
              <div
                className={`mt-4 flex items-center justify-center flex-wrap`}
              >
                <button
                  onClick={callHandler}
                  className={`p-1 bg-[#5776a5] text-white w-36 rounded-2xl mr-2 hover:text-[#5776a5] hover:bg-transparent duration-300 border border-[#5776a5]`}
                >
                  {t(isCall)}
                </button>
                <Link
                  to="/chats"
                  className={`flex items-center justify-center p-1 bg-[#5776a5] text-white w-36 rounded-2xl mr-2 hover:text-[#5776a5] hover:bg-transparent duration-300 border border-[#5776a5]`}
                >
                  {t("chat")}
                </Link>
                {data.purchasable && (
                  <button
                    onClick={adBuyHandler}
                    className={`p-1 bg-[#5776a5] text-white w-36 rounded-2xl hover:text-[#5776a5] hover:bg-transparent duration-300 border border-[#5776a5] mt-2 `}
                  >
                    {t("buy")}
                  </button>
                )}
                {/* <button
                  className={`p-1 bg-[#5776a5] text-white w-36 rounded-2xl hover:text-[#5776a5] hover:bg-transparent duration-300 border border-[#5776a5] mt-2 `}
                >
                  <Link to="priceSuggAds">{t("price_suggestion")}</Link>
                </button> */}
              </div>
            </div>
          </div>
          <div className={`mt-8`}>
            <p className={`text-2xl font-bold mb-2`}>{t("details")} :</p>
            <ul>
              <li
                className={`text-lg font-medium border-b border-[#5776a5] p-1`}
              >
                {t("owner")} : <span>{data.owner.username}</span>
              </li>
              <li
                className={`text-lg font-medium border-b border-[#5776a5] p-1`}
              >
                {t("category")} : <span>{data.owner.userType}</span>
              </li>
              <li
                className={`text-lg font-medium border-b border-[#5776a5] p-1`}
              >
                {t("year")} : <span>{data.advertisingYear}</span>
              </li>
              <li className={`text-lg font-medium p-1`}>
                {t("address")} : <span>{data.advertisingLocation}</span>
              </li>
            </ul>
          </div>
          <div
            className={`flex flex-col mt-2 p-2 bg-[#5776a5] bg-opacity-20 rounded-xl mb-4`}
          >
            {t("description")} : <span>{data.advertisingDescription}</span>
          </div>
          {data?.advertisingImageList ? (
            <div>
              <p className={`text-2xl font-bold mb-2`}>{t("images")}</p>
              <ul
                className={`p-2 bg-[#5776a5] bg-opacity-20 rounded-xl mb-4 flex`}
              >
                {data.advertisingImageList.map((ele, i) => (
                  <li
                    className="h-20 rounded-md overflow-hidden mr-2 flex-wrap"
                    key={i}
                  >
                    <img
                      crossorigin="anonymous"
                      src={ele}
                      alt={i}
                      className={`w-36 h-20 object-cover`}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </MainSection>
  );
};

export default AdsDetails;
export const adsDetailsLoader = async ({ params }) => {
  const adId = params.adId;
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const response = await fetch(baseUrl + `/advertisements/${adId}`);
  const data = await response.json();
  return data;
};
