import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function CheckoutAdvertismentDetails() {
  const { t } = useTranslation();
  const {
    state: {  TotalPrice },
  } = useLocation();
  return (
    <div className=" gap-2 p-2  container mx-auto flex justify-center align-middle flex-col items-center">
    
      <div className=" w-[80%] md:w-[50%]  flex-1 flex justify-around bg-[#5776a5]  rounded-lg ">
        <label className="md:text-4xl text-2xl text-[#f1f7ff] text-center ">
          {t("total")}
        </label>{" "}
        <label className="md:text-4xl text-2xl text-white text-center ">
          {TotalPrice.toFixed(2)}
        </label>
      </div>
    </div>
  );
}
