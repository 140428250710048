import React from "react";
import { Link } from "react-router-dom";
import styles from "./Ad.module.css";
import { useTranslation } from "react-i18next";
const AdCard = ({
  path,
  imgSrc,
  imgAlt,
  first,
  second,
  third,
  fourth,
  className,
  classNameHight,
}) => {
  const { t } = useTranslation();
  console.log(imgSrc);
  return (
    <div className="flex flex-col mx-2 rounded-xl shadow-sm  items-center  justify-center gap-4">
      <img
        className="aspect-video object-cover rounded-t-xl"
        crossorigin="anonymous"
        src={imgSrc}
        alt={first}
      />
      <p className="text-2xl text-primary whitespace-nowrap text-center px-2  text-ellipsis overflow-hidden w-full ">
        {first}
      </p>
      <p className="min-h-[70px]">
        {second.length > 100 ? `${second.slice(0, 80)}...` : second}
      </p>
      <Link
        to={path}
        className="py-2 w-full bg-primary text-center text-white rounded-b-xl"
      >
        {t("ads_description")}
      </Link>
    </div>
    // <>
    //   <Link
    //     to={path}
    //     className={` px-2 inline-block w-full  h-full md:w-auto md:h-auto `}
    //   >
    //     <div
    //       className={`relative h-60 ${styles.ad} w-full md:w-80 md:h-80  rounded-xl  overflow-hidden ${className} `}
    //     >
    //       <img
    //         crossorigin="anonymous"
    //         src={imgSrc}
    //         alt={imgAlt}
    //         className={`w-full h-full`}
    //       />
    //     </div>

    //     <div
    //       className={`p-2 flex items-center justify-center flex-col bg-[#5776a5] bg-opacity-50 text-2xl text-white text-center ${classNameHight}`}
    //     >
    //       <p
    //         className={`text-2xl line-clamp-1 overflow-ellipsis  text-[#404553] mb-2`}
    //       >
    //         {first}
    //       </p>
    //       <p
    //         className={`text-xl  line-clamp-1 overflow-ellipsis text-white mb-1`}
    //       >
    //         {second}
    //       </p>
    //       {/* <p className={`text-lg  line-clamp-1 overflow-ellipsis text-white mb-1`}>{third}</p>
    //         <p className={`text-lg line-clamp-1 overflow-ellipsis  text-white`}>{fourth}</p> */}
    //     </div>
    //   </Link>
    // </>
  );
};

export default AdCard;
