import React from "react";
import styles from "./../../../src/pages/Root/Root.module.css";
// import logo from "../../assets/netzoon-logo.png";
import logo from "../../assets/netzoon-logo.png";
export default function Loading() {
  return (
    <div className={`h-screen flex  items-center justify-center`}>
      <img
        crossorigin="anonymous"
        src={logo}
        alt={logo.toString().slice(0, 3)}
        className={`${styles.loading} w-56 opacity-20`}
      />
      <p className={`text-4xl opacity-20 text-[#2072B7] ${styles.loading}`}>
        ......
      </p>
    </div>
  );
}
