import { useQuery } from "@tanstack/react-query";
import React from "react";
import axios from "../../../config/axios";
import { order } from "../../../services/order";

import { MdRemoveShoppingCart } from "react-icons/md";
import { Link } from "react-router-dom";
import OrdersToME from "../../../components/orders/OrdersToME.js";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "../../../funcs/Currency.js";
import MainSection from "./../../../components/UI/MainSection.js";
import Loading from "../../../components/Loading/Loading.jsx";

export default function ManegeOrders() {
  const { data, loading, error, isError } = useQuery({
    queryKey: ["manageorders"],
    queryFn: () =>
      order.OrderdToMe({
        clientId: JSON.parse(window.localStorage.getItem("user")).result._id,
      }),
  });
  const currencySymbol = useSelector((state) =>
    getCurrencySymbol(state.currency.selectedCurrency)
  );

  return (
    <MainSection
      className={`!mt-52 md:!mt-28 mx-auto w-[90%] md:w-[60%] min-h-screen`}
    >
      {data?.length <= 0 && !isError ? (
        <>
          <div
            className={`md:w-[90%] w-[60%] mx-auto flex flex-col justify-center items-center`}
          >
            <MdRemoveShoppingCart
              className={`text-[10rem] text-[#5776a5] text-opacity-40 mb-8`}
            />
            <h3
              className={`font-bold text-2xl text-[#5776a5] text-opacity-40 mb-4`}
            >
              You Do Not Have Any Orders
            </h3>
            <Link to="/" className={`text-[#5776a5] underline`}>
              Start Shopping!
            </Link>
          </div>
        </>
      ) : (
        <>
          <h2
            className={`text-2xl font-semibold text-center mb-4 text-[#5776a5]`}
          >
            Your Orders
          </h2>
          <ul className={`flex flex-col items-center w-full`}>
            {data?.data?.reverse().map((ele, i) => (
              <OrdersToME
                currencySymbol={currencySymbol}
                order={data.data.length - i}
                ele={ele}
                key={i}
              />
            ))}
          </ul>
        </>
      )}

      {loading && <Loading />}
    </MainSection>
  );
}
