import { createSlice } from "@reduxjs/toolkit";
const cart = localStorage.getItem("cart");

const initialState = cart ? JSON.parse(cart) : [];

const carSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const productId = action.payload._id;
      const productIndex = state.findIndex(
        (product) => product._id === productId
      );
      if (productIndex !== -1) {
        state[productIndex].productCountInCart += 1;
      } else {
        state.push({ ...action.payload, productCountInCart: 1 });
      }
    },
    clearCart: (state, action) => {
      return [];
    },
    removeProductFromCart: (state, action) => {
      const productId = action.payload.id;
      return state.filter((product) => product._id !== productId);
    },
    incrementProductCount: (state, action) => {
      const productId = action.payload.id;
      const productIndex = state.findIndex(
        (product) => product._id === productId
      );
      state[productIndex].productCountInCart += 1;
    },
    decrementProductCount: (state, action) => {
      const productId = action.payload.id;
      const productIndex = state.findIndex(
        (product) => product._id === productId
      );
      if (state[productIndex].productCountInCart > 0)
        state[productIndex].productCountInCart -= 1;
    },
  },
});

export default carSlice.reducer;

export const {
  addToCart,
  clearCart,
  removeProductFromCart,
  incrementProductCount,
  decrementProductCount,
} = carSlice.actions;

export const selectCart = (state) => state.cart;
