import API_ROUTES from "../../constants/apiRoute";
import { createFormData } from "../../utils/createFormData";
import privetInstance from "../privetInstance";

const getService = async (id) => {
  const res = await privetInstance.get(API_ROUTES.SERVICES.GET(id));
  return res.data;
};
const editService = async ({ id, ...data }) => {
  const formData = createFormData(data);
  const res = await privetInstance.put(API_ROUTES.SERVICES.EDIT(id), formData);
  return res.data;
};
const deleteService = async (id) => {
  const res = await privetInstance.delete(API_ROUTES.SERVICES.DELETE(id));
  return res.data;
};
export { editService, deleteService, getService };
