import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const MuiTheme = ({ children }) => {
  const { i18n } = useTranslation();
  const theme = createTheme({
    direction: i18n.language === "ar" ? "rtl" : "ltr",
    palette: {
      primary: { main: "#3a3657" },
    },
    components: {
      MuiMenu: {
        defaultProps: {
          disableScrollLock: true,
        },
      },

      MuiDialog: {
        defaultProps: {
          disableScrollLock: true,
        },
      },
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiTheme;
