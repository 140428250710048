import React from "react";

const CardLi = ({
  data,
  title,
  bio = false,
  whatsapp = false,
  email = false,
  link = false,
}) => {
  return (
    <>
      {data && (
        <>
          <li
            className={` border-b-[1px] border-gray-600 p-4 flex items-center justify-between`}
          >
            <span className={`font-bold block text-base md:text-lg w-[50%]  `}>
              {title}
            </span>
            {bio ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: data,
                }}
                className={` w-[50%] text-sm md:base`}
              />
            ) : whatsapp ? (
              <a
            
                href={`https://wa.me/${data}`}
                target="_blank"
                rel="noreferrer"
                className="w-[50%] text-sm md:base"
              >
                {data}{" "}
              </a>
            ) : link ? (
              <a
            
                href={data}
                target="_blank"
                rel="noreferrer"
                className="w-[50%] text-sm md:base"
              >
                {data}{" "}
              </a>
            ) : email ? (
              <a
      
                href={`mailto:${data}`}
                target="_blank"
                rel="noreferrer"
                className="w-[50%] text-sm md:base"
              >
                {data}
              </a>
            ) : (
              <span className={`w-[50%] text-sm md:base`}>{data}</span>
            )}
          </li>
        </>
      )}
    </>
  );
};

export default CardLi;
