import React from "react";
import { Link } from "react-router-dom";
import styles from "./Ad.module.css";
import { useTranslation } from "react-i18next";
import moment from "moment";
const DealCard = ({
  path,
  imgSrc,
  imgAlt,
  first,
  second,
  third,
  fourth,
  className,
  classNameHight,
  validUntil,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Link
        to={path}
        className={` px-2 inline-block w-full  h-full md:w-auto md:h-auto `}
      >
        <div
          className={`w-full md:w-80 rounded-xl  overflow-hidden ${className}`}
        >
          <div className={`relative h-60 ${styles.ad} `}>
            <img
              crossorigin="anonymous"
              src={imgSrc}
              alt={imgAlt}
              className={`w-full h-full`}
            />
          </div>
          <div
            className={`p-2 flex   flex-col bg-[#5776a5] bg-opacity-50 text-2xl text-white  ${classNameHight}`}
          >
            <p
              className={`text-2xl line-clamp-1 overflow-ellipsis  text-[#404553] mb-2`}
            >
              {first}
            </p>
            <p
              className={`text-xl  line-clamp-1 overflow-ellipsis text-white mb-1`}
            >
              <span>{t("vendor_name")}: </span>
              <span>{second}</span>
            </p>
            <p
              className={`text-lg  line-clamp-1 overflow-ellipsis text-white mb-1`}
            >
              {third}
            </p>
            <p className={`text-lg line-clamp-1 overflow-ellipsis  text-white`}>
              {fourth}
            </p>
            <p className={`text-lg line-clamp-1 overflow-ellipsis  text-white`}>
              <span>{t("valid_until")}: </span>
              <span>{moment(validUntil).format("l")}</span>
            </p>
          </div>
        </div>
      </Link>
    </>
  );
};
export default DealCard;
