import apiclient from "./../config/axios";

class PrivacyPolicy {


  async GetPrivacyPolicy() {
    return await apiclient.get(`/legalAdvices`);
  }



}

export const privacyPolicy = new PrivacyPolicy();
