import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { RiSecurePaymentFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { order } from "../../services/order";

import { toast } from "react-toastify";
import { shipping } from "../../services/shipping";
import Loading from "../Loading/Loading";
import CheckoutOrderDetails from "../CheckoutOrderDetails/CheckoutOrderDetails";
import { advertisments } from "../../services/advertisments";
import CheckoutAdvertismentDetails from "../CheckoutAdvertismentDetails/CheckoutAdvertismentDetails";

const CheckOutFormComp = ({ url }) => {
  const {
    state: { TotalPrice },
  } = useLocation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const returnURL = window.location.origin + url;

  const { mutate: CreateAdvertisment, isPending: isPendingCreateAdvertisment } =
    useMutation({
      onSuccess(data) {
        toast("Create Advertisment Success");
        localStorage.removeItem("advertisementData");
        navigate("/");
      },
      onError(error) {},
      mutationKey: ["CreateAdvertisment"],
      mutationFn: async (data) => await advertisments.SaveAdvertisment(data),
    });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnURL,
      },
      redirect: "if_required",
    });

    if (error) {
      toast("We Got Error", { type: "error" });
    } else {
      toast("Success Payment", { position: "top-right", type: "success" });
      toast("We Will Re-direct you to Home Page", {
        position: "top-right",
        type: "info",
      });

      CreateAdvertisment(JSON.parse(localStorage.getItem("advertisementData")));
    }
  };

  return (
    <>
      {isPendingCreateAdvertisment && <Loading />}
      {!isPendingCreateAdvertisment && (
        <>
          <h2 className={`text-[#5776a5] text-center text-2xl`}>
            Your Advertisment is one step away from you
          </h2>
          <CheckoutAdvertismentDetails />
          <form
            onSubmit={handleSubmit}
            className={`flex flex-col items-center mt-4`}
          >
            <PaymentElement />
            <button
              disabled={!stripe}
              className={` px-2 py-1 bg-green-500 text-white border-2 hover:border-green-500 hover:bg-transparent hover:!text-green-500 duration-300 flex  items-center justify-between mt-4 rounded-md disabled:bg-white`}
            >
              <span className={` flex items-center`}>
                Pay{" "}
                <span className={`mx-4 font-bold text-xl`}>
                  {TotalPrice.toFixed(2)}
                </span>{" "}
                Securely
              </span>

              <span>
                <RiSecurePaymentFill className={`text-3xl`} />
              </span>
            </button>
          </form>
        </>
      )}
    </>
  );
};

export default CheckOutFormComp;
