import API_ROUTES from "../../constants/apiRoute";
import publicInstance from "../publicInstance";

const getCities = async (params) => {
  const res = await publicInstance.post(API_ROUTES.WORLD.GET_CITIES, null, {
    params,
  });
  return res.data;
};
export { getCities };
