import apiclient from "./../config/axios";

class Order {
  async SaveOrder({ data, userId }) {
 
    return await apiclient.post(`/order/save/${userId}`, data);
  }

  async OrderdToMe({ clientId }) {
    return await apiclient.get(`/order/client-orders/${clientId}`);
  }

  async OrderdDatail( {queryKey} ) {

    return await apiclient.get(`/order/${queryKey[1]}`);
  }



}

export const order = new Order();
