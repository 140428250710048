// store.js
import { configureStore } from "@reduxjs/toolkit";
import currencyReducer from "./currencySlice";
import userReducer from "./userSlice";
import LangSlice from "./LangSlice";
import CartReducer from "./cartSlice";

const store = configureStore({
  reducer: {
    currency: currencyReducer,
    user: userReducer,
    lang: LangSlice,
    cart: CartReducer,
  },
});

export default store;
