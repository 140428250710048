import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { deleteService, editService, getService } from ".";
const useGetServiceQuery = (id) =>
  useQuery({
    queryKey: ["get-service", id],
    queryFn: () => getService(id),
    enabled: !!id,
    staleTime: 0,
  });
const useDeleteServiceMutation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["delete-service"],
    mutationFn: (id) => deleteService(id),
    onSuccess: (data) => {
      navigate(-1);
    },
  });
};
const useEditServiceMutation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["edit-service"],
    mutationFn: (payload) => editService(payload),
    onSuccess: () => {
      navigate(-1);
    },
  });
};
export { useGetServiceQuery, useDeleteServiceMutation, useEditServiceMutation };
