import React from "react"
import ProductDetailsComp from "../../components/ProductDetails/ProductDetailsComp"

const ProductDetails = () => {
  return <ProductDetailsComp />
}

export default ProductDetails

export const productDetailsLoader = async ({ params }) => {
  const productId = params.productId

  const baseUrl = process.env.REACT_APP_BASE_URL

  const response = await fetch(baseUrl + `/departments/getproduct/${productId}`)
  const data = await response.json()
  console.log(data)
  return data
}
