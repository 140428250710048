import apiclient from "./../config/axios";

class Category {
  


  async getCategoryByDeparment( {queryKey} ) {

    return await apiclient.get(`departments/categories?department=${queryKey[1]}`);
  }

}

export const category = new Category();
