import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import MuiTheme from "./libs/mui/theme";

function initializeStripe() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <MuiTheme>
          <App />
        </MuiTheme>
        <ToastContainer />
      </Provider>
    </QueryClientProvider>
  );
}

initializeStripe();
