class Products {
  async CreateProduct(data) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/departments/addProduct`,
        {
          method: "POST",
          body: data,
        }
      );
      if (res.ok) {
        return res;
      } else {
        const errorText = await res.json();
     
        throw new Error(` ${errorText.message}`);
      }
    } catch (error) {
      throw new Error(` ${error.message}`);
    }
  }
}

export const product = new Products();
