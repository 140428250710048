import React, { useEffect } from "react";
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom";
import MainSection from "../../components/UI/MainSection";
import { useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import cars from "../../constants/cars";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { advertisments } from "../../services/advertisments";
import { category } from "../../services/categories";
import { countriesCurrenciesSignUp } from "../../constants/countriesSignUp";
import Toast from "../../utils/Toast";
import { product } from "../../services/products";
import { useForm } from "react-hook-form";
import { DropZoneHook } from "../../utils/DropzoneHook";
import { useMemo } from "react";
import { AiFillDelete } from "react-icons/ai";

const Addon = () => {
  const navigate = useNavigate();

  const {
    getRootProps: getRootPropsproductPhoto,
    getInputProps: getInputPropsproductPhoto,
    isFocused: isFocusedprofilePhoto,
    isDragAccept: isDragAcceptprofilePhoto,
    isDragReject: isDragRejectprofilePhoto,
  } = DropZoneHook({
    onDropCallback: (accepted) => {
      setProductPhoto(accepted[0]);
    },
    acceptedTypes: {
      "image/png": [".png", ".jpg"],
    },
    maxFiles: 1,
  });
  const {
    getRootProps: getRootPropsproductVideo,
    getInputProps: getInputPropsproductVideo,
    isFocused: isFocusedproductVideo,
    isDragAccept: isDragAcceptproductVideo,
    isDragReject: isDragRejectproductVideo,
  } = DropZoneHook({
    onDropCallback: (accepted) => {
      console.log(accepted);
      setProductVideo(accepted[0]);
    },
    acceptedTypes: {
      "video/mp4": [".mp4", ".MP4"],
    },
    maxFiles: 1,
  });

  const {
    getRootProps: getRootPropsproductPhotos,
    getInputProps: getInputPropsproductPhotos,
    isFocused: isFocusedprofilePhotos,
    isDragAccept: isDragAcceptprofilePhotos,
    isDragReject: isDragRejectprofilePhotos,
  } = DropZoneHook({
    onDropCallback: (accepted) => {
      if (productPhotos?.length + accepted?.length <= 6)
        setProductPhotos((prev) => [...prev, ...accepted]);
      else {
        Toast({
          type: "error",
          Data: "You Can't Upload more than 6 images for one product",
        });
      }
    },
    acceptedTypes: {
      "image/png": [".png", ".jpg"],
    },
    maxFiles: 6,
  });

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#5776a5",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#5776a5",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocusedprofilePhoto ? focusedStyle : {}),
      ...(isDragAcceptprofilePhoto ? acceptStyle : {}),
      ...(isDragRejectprofilePhoto ? rejectStyle : {}),
    }),
    [
      isFocusedprofilePhoto,
      isDragAcceptprofilePhoto,

      isDragRejectprofilePhoto,

      acceptStyle,
      baseStyle,
      focusedStyle,
      rejectStyle,
    ]
  );
  const currency = useSelector((state) => state.currency.selectedCurrency);
  const data = useLoaderData();
  const [selectedOptionCar, setSelectedOptionCar] = useState("");
  const [adStartDateState, setAdStartDate] = useState();
  const [adEndDateState, setAdEndDate] = useState();
  const handleEndDateState = () => {};
  const handleStartDateState = () => {};
  const [totalFees, setTotalFees] = useState(0);
  const handleOptionCarChange = (event) => {
    setSelectedOptionCar(event.target.value);
  };
  const [addFees, setAddFees] = useState(0);

  const { t } = useTranslation();
  const optionsDep = [
    { value: "", label: "Select An Option" },
    { value: "الكترونيات", label: t("electronics") },
    { value: "أجهزة المنزل والمكتب", label: t("homeAndOfficeDevices") },
    { value: "موضة رجالية", label: t("men'sFashion") },
    { value: "موضة نسائية", label: t("women'sFashion") },
    { value: "منتجات غذائية", label: t("foodProducts") },
    { value: "عطور", label: t("perfumes") },
    { value: "ساعات", label: t("watches") },
    { value: "حيوانات", label: t("animals") },
    { value: "آلات موسيقية", label: t("musicalInstruments") },
    { value: "أجهزة رياضية", label: t("sportMachines") },
    { value: "الزراعة", label: t("agriculture") },
  ];

  const [selectedOption, setSelectedOption] = useState(""); // State to track the selected option

  const options = [
    {
      label: "New and used civil and private aircraft",
      value: "الطائرات المدنية والخاصة الجديدة و المستخدمة",
    },
    {
      label: "Cars, New and Used",
      value: "السيارات، الجديدة و المستخدمة",
    },
    {
      label: "Individual Tenders",
      value: "المناقصات الفردية",
    },
    {
      label: "Active Shops",
      value: "محلات نشطة",
    },
    {
      label: "Civil, private and used boats and vessels",
      value: "القوارب والسفن المدنية والخاصة والمستخدمة",
    },
    {
      label: "Real Estate",
      value: "العقارات",
    },
    {
      label: "Building Material",
      value: "مواد بناء",
    },
    {
      label: "Scrap",
      value: "سكراب",
    },
    {
      label: "Infrastructure Material",
      value: "مواد بناء تحتية",
    },
  ];

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [selectedCategory, setSelectedCategory] = useState(""); // State to track the selected category
  const [selectedCountry, setSelectedCountry] = useState(""); // State to track the selected category

  const categories = [
    { label: "Company", value: "company" },
    { label: "Car", value: "car" },
    { label: "Planes", value: "planes" },
    { label: "Real Estate", value: "real_estate" },
    { label: "Product", value: "product" },
    { label: "Service", value: "service" },
  ];

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  const [productPhoto, setProductPhoto] = useState(null);
  const [productVideo, setProductVideo] = useState(null);
  const [productPhotos, setProductPhotos] = useState([]);

  const params = useParams();
  const isUser = window.localStorage.getItem("user") ? true : false;
  const isService = JSON.parse(window.localStorage.getItem("user")).result
    .isService;

  const userType = JSON.parse(window.localStorage.getItem("user")).result
    .userType;
  const userId = JSON.parse(window.localStorage.getItem("user")).result._id;

  const adStartDate = useRef();
  const adEndDate = useRef();

  const dealStartDate = useRef();
  const dealEndDate = useRef();

  const onSubmit = async (data) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const formData = new FormData();

    if (params.addon === "services") {
      if (!productPhoto) {
        Toast({ Data: "service photo is required", type: "error" });
        return;
      }
      formData.append("owner", userId);
      formData.append("title", data.title);
      formData.append("description", data.description);
      // eslint-disable-next-line no-lone-blocks
      if (data.price !== "") {
        formData.append("price", data.price);
      }
      // formData.append("price", price.current.value);
      formData.append("whatsAppNumber", data.number);
      formData.append("image", productPhoto);
      formData.append("video", productVideo);

      let files;
      if (productPhotos !== null) {
        files = [...productPhotos];
        files.forEach((ele) => formData.append("serviceImageList", ele));
      }
      try {
        const response = await fetch(
          baseUrl + "/categories/local-company/add-service",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // Handle success

          const data = await response.json();

          window.alert("The service has been added successfully");
        } else {
          const data = await response.json();
          // Handle error

          console.error("Error sending data");
          window.alert("The service not added");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    if (params.addon === "advertisement") {
      if (!productPhoto) {
        Toast({ Data: t("advertisement photo is required"), type: "error" });
        return;
      }
      formData.append("owner", userId);
      formData.append("purchasable", data.purchasable);
      formData.append("advertisingType", data.advertisingType);
      formData.append("advertisingTitle", data.advertisingTitle);
      formData.append(
        "advertisingStartDate",
        adStartDate.current.value.toString()
      );
      formData.append("advertisingEndDate", adEndDate.current.value.toString());
      formData.append("advertisingDescription", data.advertisingDescription);
      // eslint-disable-next-line no-lone-blocks

      formData.append("advertisingPrice", data.advertisingPrice);

      // formData.append("price", price.current.value);
      formData.append("contactNumber", data.contactNumber);
      formData.append("advertisingYear", data.advertisingYear);
      formData.append("advertisingLocation", data.advertisingLocation);
      formData.append("image", productPhoto);
      formData.append("guarantee", data.guarantee);
      formData.append("video", productVideo);

      let files;
      if (productPhotos !== null) {
        files = [...productPhotos];
        files.forEach((ele) => formData.append("advertisingImageList", ele));
      }

      localStorage.setItem("advertisementData", JSON.stringify(formData));

      navigate("/add/advertisement/paymentgateway", {
        state: {
          TotalPrice: totalFees,
        },
      });
    }
    if (params.addon === "deal") {
      if (!productPhoto) {
        Toast({ Data: t("deal photo is required"), type: "error" });
        return;
      }
      formData.append("owner", userId);
      formData.append("description", data.description);
      formData.append("name", data.name);
      formData.append("dealImage", productPhoto);
      formData.append("companyName", data.companyName);
      formData.append("prevPrice", data.prevPrice);
      formData.append("currentPrice", data.currentPrice);
      formData.append("startDate", dealEndDate.current.value.toString());
      formData.append("endDate", dealEndDate.current.value.toString());
      formData.append("location", data.location);
      formData.append("category", data.category);
      formData.append("country", currency.toString());

      try {
        const response = await fetch(baseUrl + "/deals/addDeal", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          // Handle success

          const data = await response.json();

          window.alert("Deal has been added successfully");
        } else {
          const data = await response.json();
          // Handle error

          console.error("Error sending data");
          window.alert("The Deal not added");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    console.log(data);
    if (params.addon === "products") {
      if (!productPhoto) {
        Toast({ Data: t("product photo is required"), type: "error" });
        return;
      }
      formData.append("owner", userId);
      formData.append("categoryName", data.categoryName);
      formData.append("name", data.name);
      formData.append("image", productPhoto);
      formData.append("departmentName", data.departmentName);
      formData.append("description", data.description);
      formData.append("price", data.price);
      formData.append("guarantee", data.guarantee);
      formData.append("address", data.address);
      formData.append("condition", data.condition);
      formData.append("discountPercentage", data.discountPercentage);
      formData.append("quantity", data.quantity);
      formData.append("color", data.color);
      formData.append("weight", data.weight);
      formData.append("madeIn", data.madeIn);
      let files;
      if (productPhotos !== null) {
        files = [...productPhotos];
        files.forEach((ele) => formData.append("productimages", ele));
      }
      formData.append("video", productVideo);
      formData.append("country", data.country);
      CreateProduct(formData);
    }
  };

  const handleFees = () => {
    const days =
      +adEndDate.current.value.slice(8, 10) -
      +adStartDate.current.value.slice(8, 10);
    setTotalFees(days * 5);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    watch,
  } = useForm({
    mode: "all",
    defaultValues: {
      userType: "user",
      owner: "",
      name: "",
      categoryName: "",
      departmentName: "",
      description: "",
      price: "",
      country: "",
      guarantee: false,
      address: "",
      condition: "new",
      discountPercentage: "",
      quantity: "",
      color: "",
      weight: "",
      purchasable: false,
      whatsAppNumber: "",
      companyName: "",
      bio: "",
      advertisingPrice: "",
      contactNumber: "",
      advertisingYear: "",
      advertisingLocation: "",
      advertisingDescription: "",
      title: "",
      advertisingTitle: "",
      location: "",
      category: "",
      currentPrice: "",
      prevPrice: "",
    },
  });
  useEffect(() => {
    if (params.addon === "advertisement") {
      window.localStorage.setItem("adFees", JSON.stringify(totalFees));
    }
  }, [params.addon, adStartDate, adEndDate, totalFees]);

  const { data: CategoriesByDeparment } = useQuery({
    queryKey: ["categoriesofproduct", watch("departmentName")],
    queryFn: category.getCategoryByDeparment,
  });

  const { mutate: CreateProduct } = useMutation({
    mutationFn: async (data) => await product.CreateProduct(data),
    onSuccess(data) {
      console.log(data);
      Toast({
        Data: t("product_created"),
        type: "success",
      });
    },
    onError(error) {
      Toast({
        Data: t(error.message),
        type: "error",
      });
    },
  });
  return (
    <div>
      {isUser && (
        <>
          {isService && (
            <>
              {params.addon === "services" && (
                <MainSection className={`!mt-44 md:!mt-24`}>
                  <p
                    className={`text-2xl font-semibold text-[#5776a5] text-center mb-8`}
                  >
                    Add {params.addon}
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="serviceName"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Service Name
                      </label>
                      <input
                        id="title"
                        type="text"
                        name="title"
                        {...register("title", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%]  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    {errors.title && (
                      <p className="text-red-500 ">{errors.title.message}</p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="description"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        {...register("description", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%]  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    {errors.description && (
                      <p className="text-red-500 ">
                        {errors.description.message}
                      </p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="price"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Price
                      </label>
                      <input
                        id="price"
                        type="number"
                        name="price"
                        {...register("price", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="number"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Number
                      </label>
                      <input
                        id="number"
                        type="text"
                        name="number"
                        {...register("whatsAppNumber", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label htmlFor="bio" className={`text-lg text-[#5776a5]`}>
                        Bio
                      </label>
                      <input
                        id="bio"
                        type="text"
                        name="bio"
                        {...register("bio", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductPhoto({ style })}>
                          <input {...getInputPropsproductPhoto()} />
                          <p>{t("ServicePhoto")}</p>
                        </div>
                        {productPhoto && (
                          <img
                            crossorigin="anonymous"
                            className="h-1/2 w-1/2 md:h-1/2 md:w-1/2  object-cover"
                            src={URL?.createObjectURL(productPhoto)}
                            alt={productPhoto.name}
                          />
                        )}
                      </div>
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductVideo({ style })}>
                          <input {...getInputPropsproductVideo()} />
                          <p>{t("ServiceVideo")}</p>
                        </div>
                        {productPhotos && (
                          <video
                            maxwidth="800"
                            width="100%"
                            height="450"
                            playsInline
                            autoPlay
                            muted
                            loop
                          >
                            <source src={productVideo} type="video/webm" />
                          </video>
                        )}
                      </div>
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductPhotos({ style })}>
                          <input {...getInputPropsproductPhotos()} />
                          <p>{t("ServicePhoto(s)")}</p>
                        </div>
                        {productPhotos &&
                          productPhotos?.map((item, index) => {
                            return (
                              <img
                                crossorigin="anonymous"
                                key={index}
                                className="h-1/2 w-1/2 md:h-1/2 md:w-1/2  object-cover"
                                src={URL?.createObjectURL(item)}
                                alt={item.name}
                              />
                            );
                          })}
                      </div>
                    </div>

                    <div className={`flex items-center mb-4`}>
                      <button
                        type="submit"
                        className={`w-24 flex justify-center items-center border-2 bg-[#5776a5] border-[#5776a5] rounded-xl text-white hover:bg-transparent hover:text-[#5776a5] duration-300`}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </MainSection>
              )}
              {/*  */}
              {params.addon === "advertisement" && (
                <MainSection className={`!mt-44 md:!mt-24`}>
                  <p
                    className={`text-2xl font-semibold text-[#5776a5] text-center mb-8`}
                  >
                    Add {params.addon}
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <label className={`text-lg text-[#5776a5]`}>
                        Select the Department
                      </label>
                      <select
                        required
                        {...register("advertisingType", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%] mb-4  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      >
                        <option value="">Department</option>
                        {categories.map((category, index) => (
                          <option key={index} value={category.value}>
                            {category.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="adName"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Ad Name
                      </label>
                      <input
                        id="adName"
                        type="text"
                        name="adName"
                        {...register("advertisingTitle", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%]  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="adDescription"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        {...register("advertisingDescription", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%]  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="adStartDate"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Start Date
                      </label>
                      <input
                        id="adStartDate"
                        type="date"
                        name="adStartDate"
                        ref={adStartDate}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="adEndDate"
                        className={`text-lg text-[#5776a5]`}
                      >
                        End Date
                      </label>
                      <input
                        id="adEndDate"
                        type="date"
                        name="adEndDate"
                        ref={adEndDate}
                        onChange={handleFees}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div>
                      Your Total Fees Amount :{" "}
                      <span className={`text-red-600`}>{totalFees}</span>
                    </div>

                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="address"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Address
                      </label>
                      <input
                        id="address"
                        type="text"
                        name="address"
                        {...register("advertisingLocation", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="price"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Price
                      </label>
                      <input
                        id="price"
                        type="number"
                        name="price"
                        {...register("advertisingPrice", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="number"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Number
                      </label>
                      <input
                        id="number"
                        type="text"
                        name="number"
                        {...register("contactNumber", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="year"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Year
                      </label>
                      <input
                        id="year"
                        type="number"
                        name="year"
                        {...register("advertisingYear", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div>
                      <label>Is Purchasable</label>
                      <input {...register("purchasable")} type="checkbox" />
                    </div>
                    <div>
                      <label>Is there a guarantee</label>
                      <input type="checkbox" {...register("guarantee")} />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductPhoto({ style })}>
                          <input {...getInputPropsproductPhoto()} />
                          <p>{t("ServicePhoto")}</p>
                        </div>
                        {productPhoto && (
                          <div className="relative w-[200px]  h-[200px] mx-auto">
                            <img
                              crossorigin="anonymous"
                              className="w-[200px]  h-[200px]   object-cover"
                              src={URL?.createObjectURL(productPhoto)}
                              alt={productPhoto.name}
                            />
                            <AiFillDelete
                              onClick={() => {
                                setProductPhoto(null);
                              }}
                              className=" top-0 left-0  hover:cursor-pointer absolute text-red-500 text-3xl "
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductVideo({ style })}>
                          <input {...getInputPropsproductVideo()} />
                          <p>{t("ServiceVideo")}</p>
                        </div>
                        {productPhotos && (
                          <video
                            maxwidth="800"
                            width="100%"
                            height="450"
                            playsInline
                            autoPlay
                            muted
                            loop
                          >
                            <source src={productVideo} type="video/webm" />
                          </video>
                        )}
                      </div>
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductPhotos({ style })}>
                          <input {...getInputPropsproductPhotos()} />
                          <p>{t("Service Photo(s)")}</p>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2  place-items-center">
                          {productPhotos &&
                            productPhotos?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="col-span-1 row-span-1 w-[200px]  h-[200px]  relative"
                                >
                                  <img
                                    crossorigin="anonymous"
                                    key={index}
                                    className="w-[200px]    h-[200px]   object-cover"
                                    src={URL?.createObjectURL(item)}
                                    alt={item.name}
                                  />
                                  <AiFillDelete
                                    onClick={() => {
                                      const updatedPhotos = [...productPhotos];
                                      updatedPhotos.splice(index, 1);

                                      setProductPhotos(updatedPhotos);
                                    }}
                                    className=" top-0 left-0  hover:cursor-pointer absolute text-red-500 text-3xl "
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    <div className={`flex items-center mb-4`}>
                      <button
                        type="submit"
                        className={`w-24 flex justify-center items-center border-2 bg-[#5776a5] border-[#5776a5] rounded-xl text-white hover:bg-transparent hover:text-[#5776a5] duration-300`}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </MainSection>
              )}
              {/*  */}
              {params.addon === "deal" && (
                <MainSection className={`!mt-44 md:!mt-24`}>
                  <p
                    className={`text-2xl font-semibold text-[#5776a5] text-center mb-8`}
                  >
                    Add {params.addon}
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <label className={`text-lg text-[#5776a5]`}>
                        Select the Category
                      </label>
                      <select
                        className={`w-[90%] md:w-[40%] mb-4  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                        value={selectedOption}
                        onChange={handleOptionChange}
                      >
                        <option value="">Select an option</option>
                        {options.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        Deal Title
                      </label>
                      <input
                        type="text"
                        {...register("name", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%]  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    {errors.name && (
                      <p className="text-red-500 ">{errors.name.message}</p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        Deal Company
                      </label>
                      <textarea
                        required
                        {...register("companyName", {})}
                        className={`w-[90%] md:w-[40%]  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        Previous Price
                      </label>
                      <input
                        type="number"
                        {...register("prevPrice")}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        Current Price
                      </label>
                      <input
                        type="number"
                        {...register("currentPrice", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    {errors.currentPrice && (
                      <p className="text-red-500 ">
                        {errors.currentPrice.message}
                      </p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        Start Date
                      </label>
                      <input
                        type="date"
                        ref={dealStartDate}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        End Date
                      </label>
                      <input
                        type="date"
                        ref={dealEndDate}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        Location
                      </label>
                      <input
                        type="text"
                        {...register("location")}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>

                    <div className={`flex  flex-col mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductPhoto({ style })}>
                          <input {...getInputPropsproductPhoto()} />
                          <p>{t("ServicePhoto")}</p>
                        </div>
                        {productPhoto && (
                          <div className="relative w-[200px]  h-[200px] mx-auto">
                            <img
                              crossorigin="anonymous"
                              className="w-[200px]  h-[200px]   object-cover"
                              src={URL?.createObjectURL(productPhoto)}
                              alt={productPhoto.name}
                            />
                            <AiFillDelete
                              onClick={() => {
                                setProductPhoto(null);
                              }}
                              className=" top-0 left-0  hover:cursor-pointer absolute text-red-500 text-3xl "
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`flex items-center mb-4`}>
                      <button
                        type="submit"
                        className={`w-24 flex justify-center items-center border-2 bg-[#5776a5] border-[#5776a5] rounded-xl text-white hover:bg-transparent hover:text-[#5776a5] duration-300`}
                      >
                        {t("submit")}
                      </button>
                    </div>
                  </form>
                </MainSection>
              )}
            </>
          )}

          {(isService === undefined || isService === false) && (
            <>
              {params.addon === "products" && (
                <MainSection className={`!mt-44 md:!mt-24`}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <div className={`flex  flex-col mb-4`}>
                        <label htmlFor="departmentName" className={`text-xl`}>
                          {t("department")}
                        </label>
                        <select
                          required
                          id="departmentName"
                          name="departmentName"
                          {...register("departmentName", {
                            required: {
                              value: true,
                              message: t("required"),
                            },
                          })}
                          className={`w-[90%] md:w-[40%] outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl ml-2 duration-300`}
                        >
                          {optionsDep.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.departmentName && (
                        <p className="text-red-500 ">
                          {errors.departmentName.message}
                        </p>
                      )}
                      <div className={`flex  flex-col mb-4`}>
                        <label htmlFor="categoryName" className={`text-xl`}>
                          {t("category")}
                        </label>
                        <select
                          id="categoryName"
                          required
                          name="categoryName"
                          {...register("categoryName", {
                            required: {
                              value: true,
                              message: t("required"),
                            },
                          })}
                          className={`w-[90%] md:w-[40%] outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl ml-2 duration-300`}
                        >
                          {CategoriesByDeparment?.data?.results?.map(
                            (option, index) => (
                              <option key={index} value={option.name}>
                                {option.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      {errors.categoryName && (
                        <p className="text-red-500 ">
                          {errors.categoryName.message}
                        </p>
                      )}
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-xl`}>{t("productName")}</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        {...register("name", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%] outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.name && (
                      <p className="text-red-500 ">{errors.name.message}</p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-xl`}>{t("country")}</label>

                      <select
                        id="country"
                        name="country"
                        {...register("country", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%] outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl ml-2 duration-300`}
                      >
                        {countriesCurrenciesSignUp?.map((option, index) => (
                          <option key={index} value={option.code}>
                            {option.countryName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.country && (
                      <p className="text-red-500 ">{errors.country.message}</p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-xl`}>{t("description")}</label>
                      <input
                        type="text"
                        {...register("description", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%] outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.description && (
                      <p className="text-red-500 ">
                        {errors.description.message}
                      </p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-xl`}>{t("price")}</label>
                      <input
                        {...register("price", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        type="number"
                        className={` w-[90%] md:w-[40%] outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.price && (
                      <p className="text-red-500 ">{errors.price.message}</p>
                    )}

                    <div className={`flex  flex-col mb-4`}>
                      <label htmlFor="quantity" className={`text-xl`}>
                        {t("quantity")}
                      </label>
                      <input
                        name="quantity"
                        id="quantity"
                        {...register("quantity", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        type="number"
                        className={` w-[90%] md:w-[40%] outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.quantity && (
                      <p className="text-red-500 ">{errors.quantity.message}</p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label htmlFor="weight" className={`text-xl`}>
                        {t("weight")}
                      </label>
                      <input
                        {...register("weight", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        type="number"
                        id="weight"
                        name="weight"
                        className={` w-[90%] md:w-[40%] outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.weight && (
                      <p className="text-red-500 ">{errors.weight.message}</p>
                    )}

                    <div className={`flex  flex-col mb-4`}>
                      <label htmlFor="color" className={`text-xl`}>
                        {t("color")}
                      </label>
                      <input
                        {...register("color", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        type="text"
                        className={` w-[90%] md:w-[40%] outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.color && (
                      <p className="text-red-500 ">{errors.color.message}</p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label htmlFor="discountPercentage" className={`text-xl`}>
                        {t("discount")}
                      </label>
                      <input
                        id="discountPercentage"
                        {...register("discountPercentage", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        type="number"
                        name="discountPercentage"
                        className={`w-[90%] md:w-[40%] outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.discountPercentage && (
                      <p className="text-red-500 ">
                        {errors.discountPercentage.message}
                      </p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <p className={`text-xl`}>{t("condition")}</p>
                      <label className={`text-xl`}>{t("new")}</label>
                      <input
                        {...register("condition", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        name="con"
                        value="new"
                        type="radio"
                        className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                      <label className={`text-xl`}>{t("used")}</label>
                      <input
                        {...register("condition", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        name="con"
                        value="used"
                        type="radio"
                        className={` ml-2 outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.condition && (
                      <p className="text-red-500 ">
                        {errors.condition.message}
                      </p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-xl`}>{t("address")}</label>
                      <input
                        {...register("address", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        type="text"
                        className={`w-[90%] md:w-[40%] outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.address && (
                      <p className="text-red-500 ">{errors.address.message}</p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-xl`}>{t("madeIn")}</label>
                      <input
                        type="text"
                        {...register("madeIn", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%] outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.madeIn && (
                      <p className="text-red-500 ">{errors.madeIn.message}</p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-xl`}>
                        {t("isThereGuarantee")}
                      </label>
                      <input
                        type="checkbox"
                        {...register("guarantee", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%] outline-none px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl duration-300`}
                      />
                    </div>
                    {errors.guarantee && (
                      <p className="text-red-500 ">
                        {errors.guarantee.message}
                      </p>
                    )}
                    <div className={`flex items-center mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductPhoto({ style })}>
                          <input
                            name="productPhoto"
                            {...getInputPropsproductPhoto()}
                          />
                          <p>{t("productPhoto")}</p>
                        </div>

                        {productPhoto && (
                          <div className="relative w-[200px]  h-[200px] mx-auto">
                            <img
                              crossorigin="anonymous"
                              className="w-[200px]  h-[200px]   object-cover"
                              src={URL?.createObjectURL(productPhoto)}
                              alt={productPhoto.name}
                            />
                            <AiFillDelete
                              onClick={() => {
                                setProductPhoto(null);
                              }}
                              className=" top-0 left-0  hover:cursor-pointer absolute text-red-500 text-3xl "
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`flex items-center mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductPhotos({ style })}>
                          <input {...getInputPropsproductPhotos()} />
                          <p>{t("productPhoto(s)")}</p>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2  place-items-center">
                          {productPhotos &&
                            productPhotos?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="col-span-1 row-span-1 w-[200px]  h-[200px]  relative"
                                >
                                  <img
                                    crossorigin="anonymous"
                                    className="w-[200px]    h-[200px]   object-cover"
                                    src={URL?.createObjectURL(item)}
                                    alt={item.name}
                                  />
                                  <AiFillDelete
                                    onClick={() => {
                                      const updatedPhotos = [...productPhotos];
                                      updatedPhotos.splice(index, 1);

                                      setProductPhotos(updatedPhotos);
                                    }}
                                    className=" top-0 left-0  hover:cursor-pointer absolute text-red-500 text-3xl "
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className={`flex items-center mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductVideo({ style })}>
                          <input {...getInputPropsproductVideo()} />
                          <p>{t("productVideo")}</p>
                        </div>
                        {productVideo && (
                          <div className="relative h-[300px] md:h-[400px]">
                            <video
                              className="w-full h-full  rounded-3xl"
                              playsInline
                              autoPlay
                              controls
                              muted
                              loop
                            >
                              <source src={URL.createObjectURL(productVideo)} />
                            </video>
                            <AiFillDelete
                              onClick={() => {
                                setProductVideo(null);
                              }}
                              className=" top-0 left-0  hover:cursor-pointer absolute text-red-500 text-3xl "
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`flex items-center mb-4`}>
                      <button
                        type="submit"
                        className={`w-24 flex justify-center items-center border-2 bg-[#5776a5] border-[#5776a5] rounded-xl text-white hover:bg-transparent hover:text-[#5776a5] duration-300`}
                      >
                        {t("submit")}
                      </button>
                    </div>
                  </form>
                </MainSection>
              )}
              {/*  */}
              {params.addon === "advertisement" && (
                <MainSection className={`!mt-44 md:!mt-24`}>
                  <p
                    className={`text-2xl font-semibold text-[#5776a5] text-center mb-8`}
                  >
                    Add {params.addon}
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <label className={`text-lg text-[#5776a5]`}>
                        {t("select_the_department")}
                      </label>
                      <select
                        className={`w-[90%] md:w-[40%] mb-4  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                        {...register("advertisingType", {
                          required: {
                            message: t("required"),
                            value: true,
                          },
                        })}
                      >
                        {categories.map((category, index) => (
                          <option key={index} value={category.value}>
                            {category.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.advertisingType && (
                      <p className="text-red-500 ">
                        {errors.advertisingType.message}
                      </p>
                    )}

                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="adName"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Ad Name
                      </label>
                      <input
                        id="adName"
                        type="text"
                        name="adName"
                        {...register("advertisingTitle", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%]  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    {errors.advertisingTitle && (
                      <p className="text-red-500 ">
                        {errors.advertisingTitle.message}
                      </p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="adDescription"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        {...register("advertisingDescription", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%]  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    {errors.advertisingDescription && (
                      <p className="text-red-500 ">
                        {errors.advertisingDescription.message}
                      </p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="adStartDate"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Start Date
                      </label>
                      <input
                        id="adStartDate"
                        type="date"
                        name="adStartDate"
                        ref={adStartDate}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="adEndDate"
                        className={`text-lg text-[#5776a5]`}
                      >
                        End Date
                      </label>
                      <input
                        id="adEndDate"
                        type="date"
                        name="adEndDate"
                        ref={adEndDate}
                        onChange={handleFees}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div>
                      Your Total Fees Amount :{" "}
                      <span className={`text-red-600`}>{totalFees}</span>
                    </div>

                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="address"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Address
                      </label>
                      <input
                        id="address"
                        type="text"
                        name="address"
                        {...register("advertisingLocation", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="price"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Price
                      </label>
                      <input
                        id="price"
                        type="number"
                        name="price"
                        {...register("advertisingPrice", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="number"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Number
                      </label>
                      <input
                        id="number"
                        type="text"
                        name="number"
                        {...register("contactNumber", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label
                        htmlFor="year"
                        className={`text-lg text-[#5776a5]`}
                      >
                        Year
                      </label>
                      <input
                        id="year"
                        type="number"
                        name="year"
                        advertisingYear
                        {...register("advertisingYear", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div>
                      <label>Is Purchasable</label>
                      <input type="checkbox" {...register("purchasable", {})} />
                    </div>
                    <div>
                      <label>Is there a guarantee</label>
                      <input type="checkbox" {...register("guarantee", {})} />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductPhoto({ style })}>
                          <input {...getInputPropsproductPhoto()} />
                          <p>{t("advertisementPhoto")}</p>
                        </div>
                        {productPhoto && (
                          <div className="relative w-[200px]  h-[200px] mx-auto">
                            <img
                              crossorigin="anonymous"
                              className="w-[200px]  h-[200px]   object-cover"
                              src={URL?.createObjectURL(productPhoto)}
                              alt={productPhoto.name}
                            />{" "}
                            <AiFillDelete
                              onClick={() => {
                                setProductPhoto(null);
                              }}
                              className=" top-0 left-0  hover:cursor-pointer absolute text-red-500 text-3xl "
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductVideo({ style })}>
                          <input {...getInputPropsproductVideo()} />
                          <p>{t("advertisementVideo")}</p>
                        </div>
                        {productVideo && (
                          <div className="relative h-[300px] md:h-[400px]">
                            <video
                              className="w-full h-full  rounded-3xl"
                              playsInline
                              autoPlay
                              controls
                              muted
                              loop
                            >
                              <source
                                src={URL?.createObjectURL(productVideo)}
                              />
                            </video>
                            <AiFillDelete
                              onClick={() => {
                                setProductVideo(null);
                              }}
                              className=" top-0 left-0  hover:cursor-pointer absolute text-red-500 text-3xl "
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductPhotos({ style })}>
                          <input {...getInputPropsproductPhotos()} />
                          <p>{t("Service Photo(s)")}</p>
                        </div>
                        {productPhotos &&
                          productPhotos?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="col-span-1 row-span-1 w-[200px]  h-[200px]  relative"
                              >
                                <img
                                  crossorigin="anonymous"
                                  className="w-[200px]    h-[200px]   object-cover"
                                  src={URL?.createObjectURL(item)}
                                  alt={item.name}
                                />
                                <AiFillDelete
                                  onClick={() => {
                                    const updatedPhotos = [...productPhotos];
                                    updatedPhotos.splice(index, 1);

                                    setProductPhotos(updatedPhotos);
                                  }}
                                  className=" top-0 left-0  hover:cursor-pointer absolute text-red-500 text-3xl "
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className={`flex items-center mb-4`}>
                      <button
                        type="submit"
                        className={`w-24 flex justify-center items-center border-2 bg-[#5776a5] border-[#5776a5] rounded-xl text-white hover:bg-transparent hover:text-[#5776a5] duration-300`}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </MainSection>
              )}
              {/*  */}
              {params.addon === "deal" && (
                <MainSection className={`!mt-44 md:!mt-24`}>
                  <p
                    className={`text-2xl font-semibold text-[#5776a5] text-center mb-8`}
                  >
                    {t("add_deal")}
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <label className={`text-lg text-[#5776a5]`}>
                        {t("select_the_category")}
                      </label>
                      <select
                        className={`w-[90%] md:w-[40%] mb-4  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                        {...register("category")}
                      >
                        {options.map((option, index) => (
                          <option key={index} value={option.value}>
                            {t(option.label)}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        {t("deal_title")}
                      </label>
                      <input
                        type="text"
                        {...register("name", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%]  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    {errors.name && (
                      <p className="text-red-500 ">{errors.name.message}</p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        {t("deal_descriptions")}
                      </label>
                      <input
                        type="text"
                        {...register("description", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%]  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    {errors.name && (
                      <p className="text-red-500 ">{errors.name.message}</p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        {t("company_name")}
                      </label>
                      <textarea
                        companyName
                        {...register("companyName", {
                          required: {
                            value: true,
                            message: t("required"),
                          },
                        })}
                        className={`w-[90%] md:w-[40%]  outline-none  mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    {errors.companyName && (
                      <p className="text-red-500 ">
                        {errors.companyName.message}
                      </p>
                    )}
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        {t("prev_price")}
                      </label>
                      <input
                        type="number"
                        {...register("prevPrice", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        {t("curr_price")}
                      </label>
                      <input
                        type="number"
                        {...register("currentPrice", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        {t("start_date")}
                      </label>
                      <input
                        type="date"
                        ref={dealStartDate}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        {t("end_date")}
                      </label>
                      <input
                        type="date"
                        ref={dealEndDate}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>
                    <div className={`flex  flex-col mb-4`}>
                      <label className={`text-lg text-[#5776a5]`}>
                        {t("Location")}
                      </label>
                      <input
                        type="text"
                        {...register("advertisingLocation", {})}
                        className={`w-[90%] md:w-[40%]  outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl  duration-300`}
                      />
                    </div>

                    <div className={`flex  flex-col mb-4`}>
                      <div className="container">
                        <div {...getRootPropsproductPhoto({ style })}>
                          <input {...getInputPropsproductPhoto()} />
                          <p>{t("dealPhoto")}</p>
                        </div>
                        {productPhoto && (
                          <div className="relative w-[200px]  h-[200px] mx-auto">
                            <img
                              crossorigin="anonymous"
                              className="w-[200px]  h-[200px]   object-cover"
                              src={URL?.createObjectURL(productPhoto)}
                              alt={productPhoto.name}
                            />{" "}
                            <AiFillDelete
                              onClick={() => {
                                setProductPhoto(null);
                              }}
                              className=" top-0 left-0  hover:cursor-pointer absolute text-red-500 text-3xl "
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`flex items-center mb-4`}>
                      <button
                        type="submit"
                        className={`w-24 flex justify-center items-center border-2 bg-[#5776a5] border-[#5776a5] rounded-xl text-white hover:bg-transparent hover:text-[#5776a5] duration-300`}
                      >
                        {t("submit")}
                      </button>
                    </div>
                  </form>
                </MainSection>
              )}
              {params.addon === "cars" && (
                <MainSection
                  className={`!mt-44 md:!mt-24 h-[calc(100vh-100px)] flex flex-col justify-center items-center`}
                >
                  <h2
                    className={`text-2xl font-semibold text-[#5776a5] text-center mt-8 mb-4`}
                  >
                    Please choose Car Brand
                  </h2>
                  <form>
                    <div className={`flex items-center mb-4`}>
                      <label htmlFor="userType" className={`text-xl`}>
                        Car Brand
                      </label>
                      <select
                        id="userType"
                        name="userType"
                        value={selectedOptionCar}
                        onChange={handleOptionCarChange}
                        className={`outline-none mr-4 px-4 flex justify-center items-center border-2 border-[#5776a5] rounded-2xl ml-2 duration-300`}
                      >
                        <option value={""}>Select a car</option>
                        {data.map((ele, index) => (
                          <option key={index} value={ele.name}>
                            {ele.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </form>
                  <Link
                    to={`${selectedOptionCar}`}
                    className={`px-4 text-lg py-1 bg-[#5776a5] text-white rounded-2xl`}
                  >
                    Next{" "}
                  </Link>
                </MainSection>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Addon;
export const addonLoader = async ({ params }) => {
  if (params.addon === "cars") {
    return cars;
  } else {
    return null;
  }
};
