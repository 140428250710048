import publicInstance from "../api/publicInstance";
import apiclient from "./../config/axios";
class User {
  async CreateUser(data) {
    const res = await publicInstance.post("/user/register", data);
    return res.data;
    // try {
    //   const res = await fetch(
    //     `${process.env.REACT_APP_BASE_URL}/user/register`,
    //     {
    //       method: "POST",
    //       body: data,
    //     }
    //   );
    //   if (res.ok) {
    //     return res;
    //   } else {
    //     const errorText = await res.json();

    //     throw new Error(` ${errorText.message}`);
    //   }
    // } catch (error) {
    //   throw new Error(` ${error.message}`);
    // }
  }
  async updateUser({ data, userId }) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user/editUser/${userId}`,
        {
          method: "PUT",
          body: data,
        }
      );
      if (res.ok) {
        return res;
      } else {
        const errorText = await res.json();

        throw new Error(` ${errorText.message}`);
      }
    } catch (error) {
      throw new Error(` ${error.message}`);
    }
  }

  async getCategoriesOfFactories() {
    return await apiclient.get("/categories/factories");
  }
}

export const user = new User();
