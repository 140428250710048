import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function CheckoutOrderDetails() {
  const { t } = useTranslation();
  const {
    state: { productsPrice, serviceFees, TotalDelivery, TotalPrice },
  } = useLocation();
  return (
    <div className=" gap-2 p-2  container mx-auto flex justify-center align-middle flex-col items-center">
      <div className=" w-[80%] md:w-[50%]  flex flex-col  border-spacing-1 my-2 border-[#5776a5] rounded-md py-2 border-dashed border-2	">
        <div className="flex-1 flex justify-around items-center align-middle">
          <label className="md:text-3xl text-xl  text-[#5776a5] text-center ">
            {t("totalProducts")}
          </label>
          <label className="md:text-3xl text-xl  text-[#5776a5] text-center  ">
            {productsPrice}
          </label>
        </div>
        <div className="flex-1 flex justify-around items-center align-middle">
          <label className="md:text-3xl text-xl  text-[#5776a5] text-center ">
            {t("serviceFee")}
          </label>
          <label className="md:text-3xl text-xl  text-[#5776a5] text-center ">
            {serviceFees}
          </label>{" "}
        </div>
        <div className="flex-1 flex justify-around items-center align-middle">
          <label className="md:text-3xl text-xl  text-[#5776a5] text-center ">
            {t("deliveryfee")}
          </label>
          <label className="md:text-3xl text-xl  text-[#5776a5] text-center ">
            {TotalDelivery}
          </label>
        </div>
      </div>
      <div className=" w-[80%] md:w-[50%]  flex-1 flex justify-around bg-[#5776a5]  rounded-lg ">
        <label className="md:text-4xl text-2xl text-[#f1f7ff] text-center ">
          {t("totalOrder")}
        </label>{" "}
        <label className="md:text-4xl text-2xl text-white text-center ">
          {TotalPrice.toFixed(2)}
        </label>
      </div>
    </div>
  );
}
