import { useQuery } from "@tanstack/react-query";
import { order } from "../../../services/order";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "../../../funcs/Currency";
import MainSection from "../../../components/UI/MainSection";
import { CiLocationOn } from "react-icons/ci";
import { MdMobileFriendly } from "react-icons/md";
import { OrderDetailsToMe } from "../../../components/orders/OrderDetails/OrderDetailsToMe";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
export default function OrderDetailsToMePage() {
  const { orderId } = useParams();

  const { data, loading, error, isError } = useQuery({
    queryKey: ["manageorders", orderId],
    queryFn: (orderId) => order.OrderdDatail(orderId),
  });

  return (
    <MainSection className={`!mt-52 md:!mt-24 p-8`}>
      {data?.data && <OrderDetailsToMe OrderDetail={data?.data} />}
      {loading && <Loading />}
      
    </MainSection>
  );
}
