import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Grid, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useEditPieceOfNewsMutation,
  useGetPieceOfNewsQuery,
} from "../../api/news/queries";
import Loading from "../../components/Loading/Loading";
import ImageInput from "../../components/UI/ImageInput";

const EditPieceOfNewsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data: PieceOfNews, isLoading, isError } = useGetPieceOfNewsQuery(id);
  const { mutate: editPieceOfNews } = useEditPieceOfNewsMutation();
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("required")),
    description: Yup.string().required(t("required")),
  });
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: "",
      title: "",
      description: "",
      image: null,
    },
  });
  console.log(errors);
  useEffect(() => {
    if (PieceOfNews) {
      setValue("id", PieceOfNews._id);
      setValue("title", PieceOfNews.title ?? "");
      setValue("description", PieceOfNews.description);
    }
  }, [PieceOfNews, setValue]);

  const onEditPieceOfNews = (values) => {
    editPieceOfNews(values);
  };
  if (isLoading) return <Loading />;
  return (
    <Box sx={{ my: { xs: 44, md: 23 }, mx: 2 }}>
      <form onSubmit={handleSubmit(onEditPieceOfNews)}>
        <Grid container justifyContent={"center"} gap={4}>
          <Grid item xs={12} md={10} lg={4}>
            <ImageInput
              setValue={setValue}
              name={"image"}
              oldImgSrc={PieceOfNews?.imgUrl}
            />
          </Grid>
          <Grid item xs={12} md={10} lg={8}>
            <Controller
              name="title"
              control={control}
              render={({ field, formState: { errors } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  {...field}
                  error={errors.title}
                  helperText={errors.title && errors.title.message}
                  label={t("title")}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={10} lg={8}>
            {" "}
            <Controller
              name="description"
              control={control}
              render={({ field, formState: { errors } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  {...field}
                  multiline
                  rows={3}
                  error={errors.description}
                  helperText={errors.description && errors.description.message}
                  label={t("description")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={10} lg={8}>
            <Button type="submit" sx={{ m: "auto" }} variant="contained">
              {t("edit_piece_of_news")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditPieceOfNewsPage;
