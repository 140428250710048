import React from "react";

const CardLi = ({
  data,
  title,
  bio = false,
  whatsapp = false,
  link = false,
  email = false,
}) => {
  return (
    <>
      {data && (
        <li
          className={`border-b-[1px] border-gray-600 p-4 flex items-center justify-between`}
        >
          <span className={` font-medium md:font-bold text-base md:text-lg w-[50%]`}>{title}</span>{" "}
          {bio ? (
            <span
              dangerouslySetInnerHTML={{
                __html: data,
              }}
              className={`w-[50%] text-sm md:base`}
            />
          ) : whatsapp ? (
            <a
            className={`w-[50%] text-sm md:base`}
              href={`https://wa.me/${data}`}
              target="_blank"
              rel="noreferrer"
            >
              {data}{" "}
            </a>
          ) : link ? (
            <a className={`w-[50%] text-sm md:base`} href={data} target="_blank" rel="noreferrer">
              {data}{" "}
            </a>
          ) : email ? (
            <a
            className={`w-[50%] text-sm md:base`}
              href={`mailto:${data}`}
              target="_blank"
              rel="noreferrer"
            >
              {data}
            </a>
          ) : (
            <span className={`w-[50%] text-sm md:base`}>{data}</span>
          )}
        </li>
      )}
    </>
  );
};

export default CardLi;
