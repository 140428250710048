const PRODUCT = {
  EDIT: (id) => `/departments/editProduct/${id}`,
  GET_DETAILS: (id) => `/departments/getproduct/${id}`,
};
const AUTH = {
  OAUTH: "/user/oauth",
};
const USER = {
  EDIT: (id) => `/user/net-editUser/${id}`,
};
const WORLD = {
  GET_CITIES: "/aramex/fetchCities",
};
const SERVICES = {
  GET: (id) => `/categories/local-company/get-service/${id}`,
  EDIT: (id) => `categories/local-company/edit-service/${id}`,
  DELETE: (id) => `categories/local-company/service/${id}`,
};
const PIECE_OF_NEWS = {
  GET: (id) => `news/${id}`,
  EDIT: (id) => `news/${id}`,
  DELETE: (id) => `news/${id}`,
};
const SEND_BIRD = {
  CRATE_USER: `https://api-${process.env.REACT_APP_SEND_BIRD}.sendbird.com/v3/users`,
};
const API_ROUTES = {
  PRODUCT,
  AUTH,
  USER,
  WORLD,
  SERVICES,
  PIECE_OF_NEWS,
  SEND_BIRD,
};
export default API_ROUTES;
