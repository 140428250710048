import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { oauth } from ".";
const useOAuthMutation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["oauth"],
    mutationFn: (data) => oauth(data),
    onSuccess: (data) => {
      if (data.message === "User created") {
        navigate(`/sign-up/${data.result._id}`);
      } else {
        localStorage.setItem("user", JSON.stringify(data));
        navigate("/profile");
      }
    },
    onError: (error) => {},
  });
};
export { useOAuthMutation };
