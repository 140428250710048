import React, { useState } from "react";
import { TETabs, TETabsItem } from "tw-elements-react";
import MainSection from "../../components/UI/MainSection";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
// import { Tab, Tabs } from "react-tabs-scrollable";
// import "react-tabs-scrollable/dist/rts.css";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { useEffect } from "react";

const Search = () => {
  const currency = useSelector((state) => state.currency.selectedCurrency);
  const Lang = useSelector((state) => state.lang);
  const param = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!param?.searchId) {
      navigate("local_company");
    }
  }, []);
  const options = [
    {
      value: "local_company",
      label: "local_companies",
      path: "local_company",
    },
    {
      value: "products",
      label: "Products",
      path: `products/${currency}`,
    },
    {
      value: "advertisement",
      label: "Advertisement",
      path: "advertisements",
    },
    { value: "car", label: "Cars", path: "car" },
    {
      value: "AirCraft",
      label: "aircraft",
      path: `civilAirCraft/${currency}`,
    },
    { value: "real_estate", label: "Real Estate", path: "real_estate" },

    // {
    //   value:"freezone",
    //   label: "Free Zone Companies",
    //   path: `/search/freezone/${currency}`,
    // },
    // {
    //   value:"factory",
    //   label: "Factories",
    //   path: "/search/factory",
    // },
    // {
    //   value:"",
    //   label: "government_institutions",
    //   path: "/search/governmental",
    // },

    // {
    //   value:"delivery_company",
    //   label: "Delivery",
    //   path: `/search/delivery_company`,
    // },
  ];

  const { t } = useTranslation();
  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);

    return (
      <div
        className="flex flex-col justify-center align-middle mx-2"
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
      >
        {Lang.lang === "en" ? (
          <FaArrowAltCircleLeft className="text-[#5776a5]" />
        ) : (
          <FaArrowAltCircleRight className="text-[#5776a5]" />
        )}
      </div>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext);

    return (
      <div
        className="flex flex-col justify-center align-middle mx-2"
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}
      >
        {Lang.lang === "ar" ? (
          <FaArrowAltCircleLeft className="text-[#5776a5]" />
        ) : (
          <FaArrowAltCircleRight className="text-[#5776a5]" />
        )}
      </div>
    );
  }

  return (
    <MainSection className={`!mt-48 md:!mt-28`}>
      {/* <div className="mb-3">
        <TETabs >
          {options.map((item, index) => {
            return (
              <TETabsItem>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#5776a5]  border-b-[#5776a5] border-solid border-b-2 "
                      : ""
                  }
                  to={item.path}
                  key={index}
                >
                  {t(item.label)}
                </NavLink>
              </TETabsItem>
            );
          })}
        </TETabs>
      </div> */}
      {/* <Tabs
        onTabClick={(val) => console.log(val)}
        activeTab={-1}
       
        showTabsScroll={true}
      >
        {options.map((item, index) => (
          <Tab key={index}>
            {" "}
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-[#5776a5]  border-b-[#5776a5] border-solid border-b-2 "
                  : ""
              }
              to={item.path}
              key={index}
            >
              {t(item.label)}
            </NavLink>
          </Tab>
        ))}
      </Tabs> */}
      <div className=" hidden md:flex justify-center">
        {options.map(({ path, label }, index) => (
          <NavLink
            className={({ isActive }) =>
              `${
                isActive
                  ? "text-[white] bg-[#5776a5]  border-b-[#5776a5] border-solid border-b-2 "
                  : ""
              }  break-keep  text-[#5776a5] px-2  w-fit  rounded-lg overflow-hidden`
            }
            to={path}
            key={index}
          >
            {t(label)}
          </NavLink>
        ))}
      </div>
      <div className="md:hidden">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {options.map(({ path, label }, index) => (
            <NavLink
              className={({ isActive }) =>
                `${
                  isActive
                    ? "text-[white] bg-[#5776a5]  border-b-[#5776a5] border-solid border-b-2 "
                    : ""
                }  break-keep  text-[#5776a5] px-2  w-fit  rounded-lg overflow-hidden`
              }
              to={path}
              key={index}
            >
              {t(label)}
            </NavLink>
          ))}
        </ScrollMenu>
      </div>
      <Outlet />
    </MainSection>
  );
};

export default Search;
