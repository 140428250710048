import axios from "axios";
import API_ROUTES from "../../constants/apiRoute";
const createSendBirdUser = async (payload) => {
  const res = axios.post(API_ROUTES.SEND_BIRD.CRATE_USER, payload, {
    headers: {
      "Api-Token": process.env.REACT_APP_SEND_BIRD_API_TOKEN,
    },
  });
  return res.data;
};
export { createSendBirdUser };
