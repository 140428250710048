import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import MainSection from "../../../components/UI/MainSection";
import CardLi from "./Reusable/CardLi";
import { useTranslation } from "react-i18next";
import { ReplaceLine } from "../../../utils/replaceLine";

const About = () => {
  const data = useRouteLoaderData("user");
  const { t } = useTranslation();

  return (
    <MainSection className={`w-full md:w-[700px] mx-auto`}>
      <ul>
        <CardLi title={`${t("company_name")}:`} data={data.data.username} />
        <CardLi title={`${t("description")}:`} data={data.data.description} />
        <CardLi whatsapp={true} title={`${t("mobile")}:`} data={data.data.firstMobile} />
        <CardLi email={true} title={`${t("email")}:`} data={data.data.email} />
        <CardLi
          bio={true}
          title={`${t("Bio")}:`}
          data={ReplaceLine(data.data.bio)}
        />
        <CardLi title={`${t("address")}:`} data={data.data.address} />
        <CardLi
          link={true}
          title={`${t("website")}:`}
          data={data.data.website}
        />
        <CardLi link={true} title={`${t("link")}:`} data={data.data.link} />
      </ul>
    </MainSection>
  );
};

export default About;
