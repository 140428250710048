import { useState } from "react";

const useOpenEle = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return [open, handleOpen, handleClose, setOpen];
};

export default useOpenEle;
