import React from "react";
import MainSection from "../../components/UI/MainSection";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DealTypes = () => {
  const currency = useSelector((state) => state.currency.selectedCurrency);
  const dealsTypes = [
    {
      title: "الطائرات المدنية والخاصة الجديدة و المستخدمة",
      path: `aircraft/${currency}`,
    },
    {
      title: "السيارات، الجديدة و المستخدمة",
      path: `cars/${currency}`,
    },
    {
      title: "المناقصات الفردية",
      path: `tenders/${currency}`,
    },
    {
      title: "محلات نشطة",
      path: `shops/${currency}`,
    },
    {
      title: "القوارب والسفن المدنية والخاصة والمستخدمة",
      path: `boats and vessels/${currency}`,
    },
    {
      title: "عقارات",
      path: `Real Estate/${currency}`,
    },

    { title: "مواد بناء", path: `building material/${currency}` },

    { title: "سكراب", path: `scrap/${currency}` },
    {
      title: "مواد بناء تحتية",
      path: `infrastructure material/${currency}`,
    },
  ];
  const { t } = useTranslation();
  return (
    <MainSection
      className={`!mt-52 md:!mt-24 w-[90%] lg:w-[900px] mx-auto p-2 bg-gray-300 mb-4`}
    >
      <h2 className={`text-center text-3xl py-1 mb-2`}>{t("deals_categories")}</h2>
      <ul>
        {dealsTypes.map((ele) => (
          <li className={`mb-4`}>
            <Link
              to={ele.path}
              className={`p-2 bg-[#5776a5] block text-white text-2xl rounded-md`}
            >
              {t(`${ele.title}`)}
            </Link>
          </li>
        ))}
      </ul>
    </MainSection>
  );
};

export default DealTypes;
