import apiclient from "./../config/axios";

class Advertisement {
  async SaveAdvertisment(data) {

    return await fetch(
      process.env.REACT_APP_BASE_URL + "/categories/local-company/add-service",
      {
        method: "POST",
        body: data,
      }
    );
  }
}

export const advertisments = new Advertisement();
