import React from "react";
import { Link } from "react-router-dom";

const FooterUlsStaticsHorizntal = ({ title, items }) => {
  return (
    <div className={`flex   flex-col mb-4 mr-4 `}>
      <h3
        className={`text-xl font-semibold text-black overflow-hidden  whitespace-nowrap`}
      >
        {title}
      </h3>
      <div className="flex flex-row flex-wrap">
        {items.map((ele, i) => (
          <Link className={`text-white w-1/2  sm:w-1/3 whitespace-nowrap `} to={ele.path}>
            {ele.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FooterUlsStaticsHorizntal;
