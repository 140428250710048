import { useParams, useRouteLoaderData } from "react-router-dom";
import MainSection from "../../../../components/UI/MainSection";
import CardLi from "./Reusable/CardLi";
import { useTranslation } from "react-i18next";

const AboutFac = () => {
  const {factory} = useRouteLoaderData("facId");
  const {t} = useTranslation();
     const {facId}= useParams();

     const data = factory?.filter(item=>item._id===facId)[0];
  return (
    <MainSection className={`w-full md:w-[700px] mx-auto`}>
      <ul>
        <CardLi title={`${t("company_name")}:`} data={data.username} />
        <CardLi title={`${t('description')} :`} data={data.description} />
        <CardLi whatsapp={true} title={`${t('mobile')} :`} data={data.firstMobile} />
        <CardLi email={true} title={`${t('email')} :`} data={data.email} />
        <CardLi bio={true} title={`${t('Bio')} :`} data={data.bio} />
        <CardLi title={`${t('address')} :`} data={data.address} />
        <CardLi link={true} title={`${t('website')} :`} data={data.website} />
        <CardLi link={true} title={`${t('link')} :`} data={data.link} />
      </ul>
      {/* <ul>
        <CardLi title={`${t("company_name")}:`} data={data.username} />
        <CardLi title={`${t('description')} :`} data={data.description} />
        <CardLi whatsapp={true} title={`${t('mobile')} :`} data={data.firstMobile} />
        <CardLi email={true} title={`${t('email')} :`} data={data.email} />
        <CardLi bio={true} title={`${t('Bio')} :`} data={data.bio} />
        <CardLi title={`${t('address')} :`} data={data.address} />
        <CardLi link={true} title={`${t('website')} :`} data={data.website} />
        <CardLi link={true} title={`${t('link')} :`} data={data.link} />
      </ul> */}
    </MainSection>
  );
};

export default AboutFac;
// export const userAdsFacLoader = async ({ params }) => {
//   const userFacId = params.facId;
//   const response = await fetch(
//     `https://net-zoon.onrender.com/advertisements/${userFacId}`
//   );
//   return response;
// };
